@charset "UTF-8";
@font-face {
  font-family: 'Nunito';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Nunito-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Bold.woff2") format("woff2"), url("../fonts/Nunito-Bold.woff") format("woff"), url("../fonts/Nunito-Bold.ttf") format("truetype"), url("../fonts/Nunito-Bold.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/*
@font-face {
    font-family: 'Bebas Neue';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/bebasneuebold.eot');
    src: local('Bebas Neue Bold'), local('Bebas-Neue-Bold'),
    url('../fonts/bebasneuebold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/bebasneuebold.woff2') format('woff2'),
    url('../fonts/bebasneuebold.woff') format('woff'),
    url('../fonts/bebasneuebold.ttf') format('truetype'),
    url('../fonts/bebasneuebold.svg#Montserrat') format('svg');
}
*/
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/OpenSans-Light.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype"), url("../fonts/OpenSans-Light.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/OpenSans-Reqular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Reqular"), local("OpenSans-Reqular"), url("../fonts/OpenSans-Reqular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Reqular.woff2") format("woff2"), url("../fonts/OpenSans-Reqular.woff") format("woff"), url("../fonts/OpenSans-Reqular.ttf") format("truetype"), url("../fonts/OpenSans-Reqular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/OpenSans-SemiBold.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/OpenSans-SemiBold.woff") format("woff"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype"), url("../fonts/OpenSans-SemiBold.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.ttf") format("truetype"), url("../fonts/OpenSans-Bold.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

body {
  font-family: 'Open Sans', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  ul li {
    display: inline-block; }

.content_page li {
  display: block; }

a {
  outline: none; }
  a:focus {
    outline: none; }
  a:hover, a:focus {
    text-decoration: none !important; }

table {
  margin: 6px 0; }

td, th {
  padding: 6px; }

header {
  height: auto;
  z-index: 12; }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.pagination > .active > a {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer; }
  .pagination > .active > a:hover {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer; }

.pagination > li > a {
  position: static;
  float: none;
  padding: 0;
  margin-left: 0;
  line-height: normal;
  text-decoration: none;
  background-color: transparent;
  border: none; }
  .pagination > li > a:hover {
    background-color: #b50000; }

.pagination > li > span {
  position: static;
  float: none;
  padding: 0;
  margin-left: 0;
  line-height: normal;
  text-decoration: none;
  background-color: transparent;
  border: none; }

nav a:hover {
  opacity: 1; }

.titleLink:hover {
  color: #b50000;
  text-decoration: underline; }

.topCarousel .owl-controls {
  width: 85vw;
  margin-left: -42vw; }

.topCarousel .topCarouselImage {
  height: 564px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .topCarousel .topCarouselImage .container_12 {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translateX(-50%); }

.popup_close_x {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  z-index: 2;
  transform: rotate(0);
  transition: all ease 0.4s; }
  .popup_close_x.second:before, .popup_close_x.second:after {
    background-color: #850000; }
  .popup_close_x:hover {
    transform: rotate(90deg); }
  .popup_close_x:before, .popup_close_x:after {
    content: '';
    height: 22px;
    width: 2px;
    border-radius: 5px;
    transform: rotate(45deg);
    position: absolute;
    background-color: #000;
    left: 8px;
    top: -2px; }
  .popup_close_x:before {
    transform: rotate(-45deg); }

.popup {
  z-index: 1000;
  height: auto; }
  .popup_wrapp {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: 20;
    transform: translate3d(-50%, -50%, 0);
    overflow: scroll; }
  .popup textarea {
    color: inherit; }

.height-block {
  display: block;
  height: 200px; }

.overflowHidden {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.titleLine {
  display: inline-block;
  width: 100%;
  background: url(/assets/templates/img/divider.png);
  background-repeat: repeat no-repeat;
  background-position: 40% 40%;
  margin-left: 20px; }
  .titleLine.short {
    margin-right: 80px; }

.breadcrumb > li:not(:last-child) {
  margin-right: 25px;
  position: relative; }
  .breadcrumb > li:not(:last-child):after {
    content: '/';
    position: absolute;
    top: 0;
    right: -21px; }

.form-control:focus {
  border-color: #ccc;
  box-shadow: none; }

.form-control.error {
  outline: 1px solid red; }

input[name="nospam"] {
  display: none; }

/******************** HEADER **********************/
/* header top */
.headerTop {
  background-color: #f5f5f5;
  height: 50px; }

.headerTopWrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between; }
  .headerTopWrapper .formGroup {
    position: relative; }
  .headerTopWrapper button {
    cursor: pointer;
    padding: 0 9px;
    height: 30px;
    width: 30px;
    text-align: center;
    background: #5f5f5f;
    color: white;
    text-transform: capitalize;
    display: inline-block;
    border: none;
    outline: none; }
    .headerTopWrapper button svg {
      width: 100%;
      height: 100%;
      fill: white; }
  .headerTopWrapper .formGroup .headerSearchButton {
    position: absolute;
    top: 50%;
    left: 6px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 1000px; }
  .headerTopWrapper input {
    outline: none;
    border: 1px solid #d0d0d2;
    border-radius: 1000px;
    font-size: 14px;
    padding: 10px;
    padding-left: 56px;
    width: 375px;
    max-width: 310px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .headerTopWrapper input::-webkit-input-placeholder {
      color: #a5a8a7; }

.headerTopWrapper .headerCallback {
  display: inline-block;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  text-transform: inherit;
  border-radius: 25px;
  background-color: #f5c10c;
  color: #ffffff;
  text-decoration: underline; }

.topMenu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto; }

nav a {
  line-height: normal;
  text-transform: none;
  color: #75848e; }
  nav a:hover {
    text-decoration: none;
    color: #4e5a64; }

#main-nav li {
  margin-right: 30px;
  text-align: center; }

.headerScaleSvg, .headerStarSvg {
  width: 17px;
  height: 17px;
  margin-right: 30px;
  cursor: pointer;
  -webkit-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease; }

.headerScaleSvg:hover, .headerStarSvg:hover {
  fill: #00bd9b; }

.headerTopWrapper button svg.headerLoginSvg {
  width: 17px;
  height: 17px;
  vertical-align: sub; }

.headerTopWrapper button svg.headerLogoutSvg {
  width: 15px;
  height: 15px;
  transform: translateY(1px); }

.headerTopWrapper .headerLogin {
  width: 210px;
  height: 40px;
  line-height: 40px;
  border-radius: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .headerTopWrapper .headerLogin .logoutCont .logoutName {
    margin: 0 5px;
    color: #fff;
    text-decoration: underline; }
  .headerTopWrapper .headerLogin .logoutCont .logoutLink {
    color: #fff;
    text-decoration: underline; }
  .headerTopWrapper .headerLogin span {
    position: relative; }

.headerLogin .loginUnderlined {
  text-decoration: underline;
  margin-left: 10px; }

/* headerMiddle */
.headerMiddle {
  height: 115px;
  display: flex;
  align-items: center; }

.headerMiddleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.headerSocial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 23px; }

.socialIcons a {
  text-decoration: none;
  margin-right: 20px; }
  .socialIcons a:hover .socialIcon {
    fill: #00bd9b; }
    .socialIcons a:hover .socialIcon.headerSocialSkype {
      fill: #00bd9b; }

.socialIcons .socialIcon {
  width: 15px;
  height: 15px;
  fill: #4e585f;
  -webkit-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease; }

.socialIcon.headerSocialSkype {
  fill: #4e585f; }

.headerLocation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 23px;
  color: #404040; }

@media screen and (max-width: 1024px) {
  .headerLocation.work_time {
    display: none; } }

.headerPhone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.headerIconWrapper, .contactIconWrapper {
  width: 25px;
  height: 25px;
  border: 1px solid #e7eae9;
  border-radius: 6px;
  margin-right: 16px;
  text-align: center; }

.locationIcon {
  width: 60%;
  height: 100%;
  fill: #5f5f5f; }

.scheduleIcon {
  width: 50%;
  height: 100%;
  fill: #5f5f5f; }

.phoneIcon {
  width: 57%;
  height: 100%;
  fill: #5f5f5f; }

.phoneText {
  text-align: right; }

header .phone {
  font-size: 17px;
  font-weight: bold;
  color: #303b44;
  text-decoration: none;
  display: block; }

.getOrder {
  color: #b50000;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block; }

/* header bottom */
.headerBottom {
  height: 50px;
  background-color: #b50000; }
  .headerBottom .container_12 {
    height: 100%; }

.headerBottomWrapper {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .headerBottomWrapper #mmenu {
    height: 100%; }

nav ul {
  overflow: visible;
  align-items: center; }

ul.topCatalogMenu {
  height: 100%; }
  ul.topCatalogMenu li {
    height: 100%;
    position: relative; }
    ul.topCatalogMenu li > ul li a.disabled {
      color: #d7d7d7;
      pointer-events: none;
      cursor: default;
      text-decoration: none; }
    ul.topCatalogMenu li:hover:after {
      -webkit-transform: rotateZ(45deg);
      -ms-transform: rotate(45deg);
      transform: rotateZ(45deg);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      visibility: visible;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    ul.topCatalogMenu li.active:after {
      -webkit-transform: rotateZ(45deg);
      -ms-transform: rotate(45deg);
      transform: rotateZ(45deg);
      visibility: visible; }
    ul.topCatalogMenu li.active > a {
      color: #f5c10c; }
    ul.topCatalogMenu li a {
      color: white;
      text-decoration: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      ul.topCatalogMenu li a:hover {
        color: #f5c10c; }
    ul.topCatalogMenu li > ul {
      position: absolute;
      display: none;
      left: 0;
      top: 100%;
      background-color: white;
      z-index: 1000;
      border-radius: 0 0 4px 4px;
      width: 214px;
      border: 1px solid #e5ebea; }
      ul.topCatalogMenu li > ul > li.menuNest {
        position: relative; }
        ul.topCatalogMenu li > ul > li.menuNest:after {
          content: "";
          width: 7px;
          height: 7px;
          border-bottom: 1px solid #b50000;
          border-right: 1px solid #b50000;
          margin-top: -2.5px;
          position: absolute;
          top: 50%;
          right: 10px;
          color: #b50000;
          transform: rotate(-45deg); }
      ul.topCatalogMenu li > ul > li:hover > ul {
        display: block; }
      ul.topCatalogMenu li > ul > li > ul {
        display: none;
        position: absolute;
        top: 5px;
        left: 100%; }
    ul.topCatalogMenu li:hover > ul {
      display: block; }
    ul.topCatalogMenu li > ul > li {
      margin-right: 0;
      display: block;
      width: 100%;
      margin: 0; }
    ul.topCatalogMenu li > ul li a {
      color: #373d40;
      line-height: unset;
      padding: 9px 10px;
      border-bottom: 1px solid #e5ebea; }
    ul.topCatalogMenu li > ul li:hover a {
      border-bottom: 1px solid #e5ebea !important; }
  ul.topCatalogMenu > li {
    max-width: 144px;
    text-align: center; }
    ul.topCatalogMenu > li:after {
      display: none;
      content: "";
      width: 5px;
      height: 5px;
      border-bottom: 1px solid #f5c10c;
      border-right: 1px solid #f5c10c;
      margin-top: -2.5px;
      position: absolute;
      top: 50%;
      right: -16px;
      color: #f5c10c;
      font-weight: 900;
      visibility: hidden; }

.topDiscounts {
  margin-left: 30px;
  margin-right: 30px; }
  @media screen and (max-width: 1120px) {
    .topDiscounts {
      margin-left: 12px;
      margin-right: 12px; } }
  .topDiscounts a {
    color: #f5c10c;
    font-weight: bold; }
    .topDiscounts a:hover {
      color: #f5c10c; }

.headerCart {
  width: auto;
  padding: 0 30px 0 10px;
  height: 100%;
  background-color: #c02626;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  flex-shrink: 0; }

#msMiniCart {
  width: 100%; }

.headerMiniCartWrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.headerCart .empty, .headerCart .not_empty {
  height: 100%;
  width: 100%; }

.headerCart .empty .incartAmount {
  font-size: 12px;
  line-height: 12px;
  color: #ffffff; }

.headerCartWrapper {
  width: 50px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative; }

.cartIcon {
  width: 37%;
  height: 100%;
  fill: #fff; }

.headerIncart {
  width: 18px;
  height: 18px;
  background-color: #f5c10c;
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 9px;
  color: #c02626;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px; }

.headerIncartWrapper {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.incartText {
  font-size: 12px;
  color: #ffffff; }

.incartAmount, .incartPrice {
  color: #fff;
  font-size: 14px; }

.incartPriceColor {
  color: #f5c10c; }

/* mobile header */
.mobileHeader, .headerSearchMin {
  display: none; }

.mobileHeader.searched .formGroup {
  display: block; }

.mobileHeader.searched .logo {
  opacity: 0; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #97a5ae !important; }

input[name="second_name"],
input[name="second_email"] {
  display: none; }

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0; }

/************************* BREADCRUMBS **************************/
.breadcrumb {
  background-color: #fff;
  margin-bottom: 10px;
  padding-left: 0; }
  .breadcrumb > li + li:before {
    display: none; }
  .breadcrumb > li > a {
    color: #2a3b45; }
    .breadcrumb > li > a:hover {
      color: #b50000; }

/************************ spine **************************/
.spine, .owl-carousel .owl-controls.disabled {
  display: none; }

/****************************** CAROUSEL ****************************/
.topCarouselWrapper {
  position: relative; }

.topCarousel {
  margin-top: 0; }
  .topCarousel .owl-controls .owl-dot {
    width: 12px;
    height: 12px;
    border-radius: 100px;
    display: inline-block;
    border: 1px solid #b50000; }

.advantagesCarousel .owl-controls .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  display: inline-block; }

.advantagesCarousel .owl-dots {
  text-align: center; }
  .advantagesCarousel .owl-dots .owl-dot {
    background-color: #d4d5d7;
    margin: 0 5px; }
    .advantagesCarousel .owl-dots .owl-dot.active {
      background-color: #b50000; }

.topCarouselTitle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;
  width: 50%;
  border-radius: 4px;
  padding-right: 100px; }
  .topCarouselTitle .titleBig {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: #2a3a44;
    margin-bottom: 37px; }
  .topCarouselTitle .titleItem {
    margin-bottom: 18px;
    position: relative;
    padding-left: 40px; }
    .topCarouselTitle .titleItem .titleItemDec {
      position: absolute;
      top: -3px;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid #e7eae9; }
      .topCarouselTitle .titleItem .titleItemDec:before {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #00bd9b;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }

/************************* TOP FORM *****************************/
.topCarouselForm {
  position: absolute;
  top: 113px;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateX(123%);
  -ms-transform: translateX(123%);
  transform: translateX(123%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 280px;
  border: 10px solid #ffffff;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  text-align: center; }
  .topCarouselForm .topCarouselFormTitle {
    color: #b50000;
    font-weight: bold;
    margin-bottom: 20px; }
    .topCarouselForm .topCarouselFormTitle .titleBold {
      font-size: 20px; }
    .topCarouselForm .topCarouselFormTitle .titleNormal {
      font-size: 14px;
      font-weight: 600; }
  .topCarouselForm .inputholder {
    margin-bottom: 20px;
    position: relative; }
    .topCarouselForm .inputholder input {
      width: 100%;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #b7c6d0;
      outline: none;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-right: 17px;
      background-color: transparent; }
      .topCarouselForm .inputholder input.wrong {
        border-bottom: 1px solid red;
        background-color: #ffdfdf; }

.topCarouselFormSvg {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #b7c5cf; }

.topCarouselForm .inputholder:nth-child(1):before {
  left: auto;
  right: 11px;
  top: 11px;
  background-image: none; }

.topCarouselForm .inputholder:nth-child(2):before {
  left: auto;
  right: 11px;
  top: 9px;
  background-image: none; }

.topCarouselForm .carouselFormButton {
  padding: 10px 20px;
  text-transform: uppercase;
  width: 100%;
  background-color: #b50000;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 20px; }

.topCarouselForm .topCarouselFormSubtitle {
  color: #b50000;
  font-size: 12px;
  text-align: left;
  font-weight: 600; }

/************************* TITLE **************************/
.title {
  font-family: 'Nunito', sans-serif;
  margin-right: 19px;
  -ms-flex-negative: 0;
  flex-shrink: 0; }
  .title:before, .title:after {
    content: "";
    display: none; }

.titleLink {
  line-height: 47px;
  color: #b50000;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-decoration: underline; }

.titleLink_mobile {
  line-height: 47px;
  color: #b50000;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-decoration: underline;
  display: block;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center; }

.titleLink:after {
  display: block; }

.titleLink:hover, .titleLink:focus .titleLink_mobile:hover {
  color: #b50000; }

.titleLink_mobile:focus {
  color: #b50000; }

/***************************** OTHER CAROUSELS *********************/
.catalogCarousel .owl-prev, .catalogCarousel .owl-next {
  color: #b50000; }

.catalogCarousel .owl-prev:hover, .catalogCarousel .owl-next:hover {
  background-color: #b50000; }

.discountCarousel .owl-prev:hover, .discountCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.hitsCarousel .owl-prev:hover, .hitsCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.newsCarousel .owl-prev:hover, .newsCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.certificatesCarousel .owl-prev:hover, .certificatesCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.reviewsCarousel .owl-prev:hover, .reviewsCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.productionCarousel .owl-prev:hover, .productionCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.newsCarousel01 .owl-prev:hover, .newsCarousel01 .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.portfolioGallery .owl-prev:hover, .portfolioGallery .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.officePhotosWrapper .owl-prev:hover, .officePhotosWrapper .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.relatedCarousel .owl-prev:hover, .relatedCarousel .owl-next:hover {
  background-color: #b50000;
  color: #fff; }

.officeImageCarousel {
  padding-bottom: 0 !important; }
  .officeImageCarousel .owl-prev:hover, .officeImageCarousel .owl-next:hover {
    background-color: #b50000;
    color: #fff; }

.discountCarousel .owl-prev, .discountCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.hitsCarousel .owl-prev, .hitsCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.newsCarousel .owl-prev, .newsCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.portfolioCarousel .owl-prev, .portfolioCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.certificatesCarousel .owl-prev, .certificatesCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.questionsCarousel .owl-prev, .questionsCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.reviewsCarousel .owl-prev, .reviewsCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.productionCarousel .owl-prev, .productionCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.newsCarousel01 .owl-prev, .newsCarousel01 .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.portfolioGallery .owl-prev, .portfolioGallery .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.officePhotosWrapper .owl-prev, .officePhotosWrapper .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.relatedCarousel .owl-prev, .relatedCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.officeImageCarousel .owl-prev, .officeImageCarousel .owl-next {
  color: #b50000;
  font-family: monospace;
  border: 1px solid #e3eceb;
  float: left;
  padding: 0 5px;
  margin-left: 10px; }

.catalogCarousel .owl-controls, .discountCarousel .owl-controls, .hitsCarousel .owl-controls, .portfolioCarousel .owl-controls, .certificatesCarousel .owl-controls, .questionsCarousel .owl-controls, .reviewsCarousel .owl-controls, .productionCarousel .owl-controls, .newsCarousel01 .owl-controls, .portfolioGallery .owl-controls, .officePhotosWrapper .owl-controls, .relatedCarousel .owl-controls, .officeImageCarousel .owl-controls {
  position: absolute;
  top: -44px;
  right: 0;
  padding-left: 26px;
  z-index: 9; }

.advantagesCarousel .owl-controls {
  position: absolute;
  bottom: -34px;
  right: 0;
  width: 100%; }
  .advantagesCarousel .owl-controls .owl-nav {
    display: none; }

.newsCarousel01 .owl-controls {
  top: -95px; }

.hitsCarousel .owl-prev, .hitsCarousel .owl-next {
  background-color: #fff; }

.certificatesCarousel .owl-prev, .certificatesCarousel .owl-next {
  background-color: #fff; }

.reviewsCarousel .owl-prev, .reviewsCarousel .owl-next {
  background-color: #fff; }

.relatedCarousel .owl-prev, .relatedCarousel .owl-next {
  background-color: #fff; }

/**************************** CATALOG ***********************/
.catalog.index {
  background: none; }

.catalogIndex, .discountIndex, .hitsIndex, .portfolioIndex {
  background-image: none;
  padding-top: 0; }

.catalogCarousel {
  margin-bottom: 20px; }

.discountIndex {
  margin-bottom: 70px; }

.portfolioBlock {
  height: 378px;
  /*
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 178px 20px 178px;
    grid-template-rows: 178px 178px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    > * {
        &:nth-child(1) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
        }

        &:nth-child(2) {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
        }

        &:nth-child(3) {
            -ms-grid-row: 1;
            -ms-grid-column: 5;
        }

        &:nth-child(4) {
            -ms-grid-row: 1;
            -ms-grid-column: 7;
        }

        &:nth-child(5) {
            -ms-grid-row: 3;
            -ms-grid-column: 1;
        }

        &:nth-child(6) {
            -ms-grid-row: 3;
            -ms-grid-column: 3;
        }

        &:nth-child(7) {
            -ms-grid-row: 3;
            -ms-grid-column: 5;
        }

        &:nth-child(8) {
            -ms-grid-row: 3;
            -ms-grid-column: 7;
        }
    }
    */ }

.portfolioItem {
  display: inline-block;
  overflow: hidden;
  border-radius: 5px; }
  .portfolioItem a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .portfolioItem:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2; }
  .portfolioItem:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }
  .portfolioItem:nth-child(3) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }
  .portfolioItem:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3 / 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3; }
  .portfolioItem:nth-child(5) {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / 5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2; }
  .portfolioItem:nth-child(6) {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / 5;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "grid_item_1 grid_item_1 grid_item_2 grid_item_3" "grid_item_4 grid_item_5 grid_item_2 grid_item_6";
  width: calc(100% + 20px);
  margin-left: -10px; }
  .grid-container a {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

.grid_item {
  border-radius: 5px;
  padding: 10px;
  overflow: hidden; }

.grid_item_1 {
  grid-area: grid_item_1; }

.grid_item_2 {
  grid-area: grid_item_4; }

.grid_item_3 {
  grid-area: grid_item_5; }

.grid_item_4 {
  grid-area: grid_item_2; }

.grid_item_5 {
  grid-area: grid_item_3; }

.grid_item_6 {
  grid-area: grid_item_6; }

@media all and (-ms-high-contrast: none) {
  .grid-container {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr; }
  .grid_item_1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2; }
  .grid_item_2 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1; }
  .grid_item_3 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1; }
  .grid_item_4 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1; }
  .grid_item_5 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; }
  .grid_item_6 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1; } }

/********************** CATALOG ITEM ************************/
.catalogItem {
  width: 295px;
  outline: none;
  border: 1px solid #e3eceb;
  border-radius: 6px; }
  .catalogItem .button {
    margin-top: auto; }

.catalogWrapper .catalogItem {
  float: none;
  width: calc(25% - 15px);
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
  margin: 0;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); }
  .catalogWrapper .catalogItem:nth-child(4n) {
    margin-right: 0; }
  @media screen and (max-width: 1120px) {
    .catalogWrapper .catalogItem {
      padding: 14px; } }

.catalogItemImage {
  width: 85%;
  margin: 20px auto 10px auto;
  display: inline-block; }
  .catalogItemImage > a {
    display: block;
    width: 100%;
    height: 100%; }
    .catalogItemImage > a > img {
      width: 100%; }

.catalogItemTitle:before {
  display: none; }

.catalogWrapper .catalogItemTitle {
  margin-top: 20px; }

.catalogItemTitle > a {
  color: #404040; }
  .catalogItemTitle > a:hover {
    opacity: 0.8; }

.catalogItemSmall .catalogItemTitle > a {
  min-height: 79px;
  overflow: hidden;
  display: inline-block; }

.inner_page {
  padding: 25px 0 40px 0; }

/********************** DISCOUNTS **************************/
.discounts, .discount {
  padding: 25px 0; }

.discountCarousel .discountItem {
  width: 100%;
  height: 100%;
  border: 1px solid #e3eceb; }

.discountItem {
  width: 49%;
  display: flex;
  align-items: inherit;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: right;
  border-radius: 4px;
  margin-bottom: 25px;
  margin-right: 20px;
  overflow: hidden; }

.discountsWrapper .discountItem:nth-child(even) {
  margin-right: 0; }

.discountItem .discountItemInfo {
  display: flex;
  width: 50%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  text-align: left;
  padding: 30px;
  padding-right: 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  flex-direction: column;
  align-items: flex-start; }

.discountItem .button {
  min-width: 160px; }

.discountItem .discountTitle {
  font-family: 'Nunito',sans-serif;
  font-size: 20px;
  margin-bottom: 5px;
  overflow: hidden; }
  .discountItem .discountTitle > a {
    color: #b50000; }
    .discountItem .discountTitle > a:hover {
      color: #b50000; }

.discountItem .discountText {
  margin-bottom: 20px;
  max-height: 130px;
  overflow: hidden; }
  @media screen and (max-width: 1350px) {
    .discountItem .discountText {
      max-height: 126px; } }

.discountItem .discountDesc {
  width: 100%;
  height: 60px;
  overflow: hidden;
  color: #929292; }

.discountDuration {
  display: inline-block;
  padding: 8px 14px;
  position: relative;
  border: 1px solid #b2b2b2;
  color: #b2b2b2;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: auto; }

.discountCarousel .owl-stage-outer {
  padding: 10px;
  box-sizing: content-box;
  margin-left: -10px; }

.discountCarousel .owl-stage {
  display: flex; }

.discountDurationSvg {
  width: 13px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: -6px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #ffffff;
  fill: #b50000; }

.productButtonsWrapper {
  padding-top: 20px;
  margin-bottom: 30px; }

.productButtonsWrapper button {
  margin-right: 10px; }
  .productButtonsWrapper button:last-child {
    margin-right: 0;
    margin-bottom: 0; }

.button {
  display: inline-block;
  max-width: 100%;
  line-height: 40px;
  padding: 0 15px;
  border: 1px solid #b50000;
  outline: none;
  background-color: #b50000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  transition-duration: 0.4s;
  text-align: center; }
  .button:hover {
    background-color: #850000;
    border-color: #850000;
    color: white; }

.form-group .button {
  width: 100%; }

.button.second {
  background: transparent;
  color: #b50000;
  border-color: #b50000; }
  .button.second:hover {
    background-color: #b50000;
    color: #fff; }

.button.third {
  background-color: #fff;
  color: #b50000;
  border-color: #b50000; }

.button.order {
  margin-right: 10px; }

.button.clear_btn {
  background-color: #fff;
  border-color: #af3e3e;
  color: #af3e3e; }

.discountItemImage {
  width: 50%;
  display: inline-block;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/disocunt_bg.jpg"); }
  .discountItemImage img {
    width: auto;
    height: auto; }
  .discountItemImage a {
    height: 100%;
    display: flex;
    align-items: center; }

.discountContentImg {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../img/disocunt_bg.jpg); }

.discountContent .discountDesc {
  width: 50%;
  display: inline-block; }

.discountContent .discountContentImg {
  max-width: 310px; }

.discountDesc .discountDurationCont {
  color: #b50000; }

/************************* HITS *********************************/
.hitsSection {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/hit_banner.jpg");
  padding-top: 10px;
  padding-bottom: 30px;
  margin-bottom: 90px; }

.hitItem {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e3eceb;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .hitItem:not(:nth-child(4n+1)) {
    margin-left: 28px; }
  .hitItem form {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .hitItem .hitItemImage {
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-height: 226px; }
    .hitItem .hitItemImage img {
      width: 100%; }

.hitItemContent {
  padding: 15px 0; }

.hitItemInStock {
  color: #83c550;
  position: relative;
  margin-left: 17px;
  margin-bottom: 8px;
  font-size: 12px; }
  .hitItemInStock:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #83c550;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -16px; }

.hitItemButton {
  display: block;
  height: auto; }

.hitItemTitle {
  font-size: 14px;
  color: #2a3b45;
  font-weight: 600;
  margin-bottom: 10px; }
  .hitItemTitle > a {
    color: #2a3b45; }
    .hitItemTitle > a:hover {
      opacity: 0.8; }

.catalogItemSmall .hitItemTitle > a {
  min-height: 79px;
  overflow: hidden;
  display: inline-block; }

.rub {
  font-size: 14px; }

.hitItemDescr {
  height: 60px;
  overflow: hidden;
  width: 92%; }

.hitItemPrices, .catalogItemPrices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: space-between;
  position: relative;
  margin-top: auto; }

.hitItemPrice, .catalogItemPrice {
  display: inline-block;
  font-size: 18px;
  color: #2a3b45;
  font-weight: 600;
  margin-right: 40px; }

.catalogItemPrice.catalogItemPrice_currency {
  display: inline; }

.hitItemOldPrice, .catalogItemOldPrice {
  font-size: 12px;
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.hitItemOldPrice:before, .catalogItemOldPrice:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000000;
  position: absolute;
  top: 50%;
  left: 0; }

.catalogSection .catalogItemOldPrice {
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  padding-right: 0;
  display: inline-block;
  margin-right: 10px; }

.hitItemToCart, .catalogItemToCart {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-color: #f5c10c;
  border-radius: 1000px;
  flex-shrink: 0;
  text-align: center; }

a.catalogItemToCart {
  text-align: center; }
  a.catalogItemToCart svg.cartIcon {
    width: 24px; }

.hitItemToCart .cartIcon, .catalogItemToCart .cartIcon {
  width: 18px;
  flex-shrink: 0; }

#mse2_sort .sort {
  position: relative;
  margin-right: 20px; }
  #mse2_sort .sort span {
    position: absolute;
    top: -2px;
    right: -10px; }

#mse2_sort a.active {
  color: #b50000 !important; }

#mse2_tpl a.active {
  color: #b50000 !important; }

#mse2_sort a.active svg, #mse2_tpl a.active svg {
  fill: #b50000; }

/**************************** ADVANTAGES *************************/
.advantagesPic {
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  overflow: hidden;
  position: absolute;
  -webkit-clip-path: circle(65% at 70% 50%);
  clip-path: circle(65% at 70% 50%); }

.advantages {
  padding-top: 20px;
  padding-bottom: 55px;
  vertical-align: top; }
  .advantages .titleLink:after {
    width: 1032px;
    right: -1059px; }
  .advantages .title:before, .advantages .title:after {
    width: 1027px;
    right: -1083px; }

.advantagesItem {
  width: calc(100% / 3 - 13.5px);
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  overflow: hidden; }
  .advantagesItem:nth-child(3n) {
    margin-right: 0; }

.advantagesContent {
  border: 10px solid #f3f4f6;
  background-color: #fff;
  padding: 40px 190px 20px 20px;
  box-sizing: border-box;
  height: 100%; }

.advantagesTitle {
  color: #b50000;
  font-size: 20px;
  margin-bottom: 22px; }

.banner {
  padding: 60px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.flex {
  display: flex; }

.ais {
  align-items: stretch; }

.jsb {
  justify-content: space-between; }

.bannerFormGroup {
  width: 100%; }
  .bannerFormGroup form {
    max-width: 830px;
    width: 100%;
    margin: 0 auto; }
    .bannerFormGroup form .flex {
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 1350px) {
        .bannerFormGroup form .flex {
          justify-content: space-between; } }
      .bannerFormGroup form .flex > * {
        flex: auto; }
        @media screen and (max-width: 1350px) {
          .bannerFormGroup form .flex > * {
            margin: 0 0 10px 0;
            flex-grow: 0; } }
  .bannerFormGroup .form-input {
    margin-right: 20px; }
    @media screen and (max-width: 1350px) {
      .bannerFormGroup .form-input {
        width: 48%; } }
  .bannerFormGroup .button {
    max-width: 190px; }
    @media screen and (max-width: 1350px) {
      .bannerFormGroup .button {
        margin: auto !important; } }

.bannerWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }

.bannerContent {
  max-width: 480px;
  width: 100%;
  text-align: center; }
  .bannerContent .bannerTitle {
    font-family: 'Nunito',sans-serif;
    font-size: 24px;
    color: #fff;
    margin-bottom: 15px; }
  .bannerContent .bannerText {
    color: #ffffff;
    margin-bottom: 30px; }

.bannerWrapper .inputholder {
  display: inline-block;
  margin-right: 20px; }
  .bannerWrapper .inputholder:before {
    background-image: none; }
  .bannerWrapper .inputholder input {
    width: 370px;
    height: 40px;
    border: none;
    outline: none;
    padding: 0 20px;
    border-radius: 4px; }

.bannerWrapper img {
  position: absolute;
  bottom: 0;
  right: 0; }

.form-input input {
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 40px;
  padding-left: 20px; }

.banner .checkbox-block {
  color: #ffffff; }
  .banner .checkbox-block a {
    color: #ffffff; }
  .banner .checkbox-block label {
    border-color: #ffffff; }
    .banner .checkbox-block label:before {
      background-color: #ffffff; }

.checkbox-block {
  position: relative;
  padding-top: 10px;
  letter-spacing: normal; }
  .checkbox-block a {
    color: #b50000;
    text-decoration: underline; }
  .checkbox-block input {
    display: block;
    position: absolute;
    opacity: 0;
    width: 14px !important;
    height: 14px !important;
    left: 0; }
    .checkbox-block input:checked + label:before {
      display: block; }
  .checkbox-block span {
    vertical-align: middle; }
  .checkbox-block label {
    display: inline-block;
    cursor: pointer;
    left: 0;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 1px solid;
    border-radius: 2px;
    margin-bottom: 0;
    vertical-align: middle;
    margin-right: 5px; }
    .checkbox-block label:before {
      position: relative;
      content: '';
      display: none;
      width: 8px;
      height: 8px;
      background-color: #b50000;
      vertical-align: middle;
      left: 2px;
      top: 2px;
      border-radius: 1px; }

/************************ PORTFOLIO **************************/
.portfolioIndex {
  margin-bottom: 90px; }

/************************** NEWS ******************************/
.catalog.index.newsIndex {
  padding-bottom: 100px;
  padding-top: 0; }

.newsItem {
  width: calc(25% - 15px);
  margin-right: 20px;
  border: 1px solid #e3eceb;
  border-radius: 5px;
  padding-bottom: 16px;
  float: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .newsItem:nth-child(4n) {
    margin-right: 0; }

.newsCarousel .newsItemInfo {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
  padding: 0 16px; }

.newsBigItemInfo .newsItemInfo {
  margin-left: 0;
  float: none;
  width: auto; }

.newsItemInfo:before {
  display: none; }

.newsItemInfo .day, .newsItemInfo .month, .newsItemInfo .year {
  display: inline-block; }

.newsItem .newsImage {
  margin-bottom: 15px; }

.newsItem .newsItemLink {
  display: block;
  height: auto;
  overflow: hidden; }

.newsItem .newsType {
  width: 70px;
  height: 20px;
  text-align: center;
  background-color: #b50000;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 9px; }
  .newsItem .newsType.page {
    background-color: #f5c10c !important; }

.newsBigItem .newsType {
  width: 70px;
  height: 20px;
  text-align: center;
  background-color: #b50000;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 9px; }
  .newsBigItem .newsType.page {
    background-color: #f5c10c !important; }

.newsTitle {
  margin-bottom: 10px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.newsBigItemDescSubtitle {
  display: flex;
  justify-content: space-between; }

.newsTitle:hover {
  opacity: 1; }

.newsTitle > a {
  color: #404040; }
  .newsTitle > a:hover {
    opacity: 0.8; }

.newsDesc {
  height: 55px;
  overflow: hidden;
  margin-bottom: 10px; }

.newsDate {
  color: #b9b8b8; }

.newsDate .newsSvg {
  width: 17px;
  height: 17px;
  fill: #b9b8b8;
  transform: translateY(2px); }

.newsBigItemDate {
  width: 10%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-right: 20px; }
  .newsBigItemDate .day {
    font-size: 50px;
    color: #38cbb1;
    text-align: center;
    font-weight: bold;
    line-height: 42px;
    margin-bottom: 12px; }
  .newsBigItemDate .month {
    color: #9bc3c0;
    font-size: 16px;
    text-align: center; }
  .newsBigItemDate .year {
    color: #9bc3c0;
    font-size: 24px;
    text-align: center;
    line-height: 20px; }

.newsBigItemInfo {
  width: 100%;
  display: inline-block;
  vertical-align: top; }

.newsBigItemImage {
  width: 31%;
  display: inline-block;
  margin-right: 2%;
  overflow: hidden; }
  .newsBigItemImage img {
    width: 100%; }

.newsBigItemDesc {
  display: inline-block;
  width: 66%;
  vertical-align: top; }
  .newsBigItemDesc h3 {
    font-size: 22px; }

.newsBigSection {
  min-height: 470px; }

/*********************** ARTICLE *****************************/
.articleIndex {
  margin-bottom: 50px; }

.articleWrapper span.more {
  color: #f46428;
  cursor: pointer;
  text-decoration: underline; }

.articleTitle {
  font-size: 24px;
  font-weight: 600;
  color: #b50000;
  margin-bottom: 25px; }

.articleContentWrapper {
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s; }
  .articleContentWrapper.active {
    max-height: 1000px; }
    .articleContentWrapper.active:after {
      display: none; }
  .articleContentWrapper:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(transparent));
    background: -webkit-linear-gradient(bottom, #fff, transparent);
    background: -o-linear-gradient(bottom, #fff, transparent);
    background: linear-gradient(0deg, #fff, transparent); }

/*********************** ABOUT US *****************************/
.aboutUs {
  margin-top: 25px; }

.aboutLogo {
  margin-bottom: 30px;
  border-radius: 4px;
  width: 100%; }

.aboutUsInfoWrapper {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.aboutUsInfoItem {
  display: inline-block;
  width: 295px;
  height: 153px;
  font-size: 14px;
  border: 1px solid #e3eceb;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 27px;
  vertical-align: top;
  text-align: center; }

.aboutUsInfoItemTitle {
  color: #b50000;
  font-size: 20px;
  border-bottom: 1px solid #e3eceb;
  line-height: 56px;
  overflow: hidden;
  height: 56px; }

.aboutUsInfoItemCont {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }

.forCustomer ul {
  position: relative;
  height: 360px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.forCustomerButton {
  display: inline-block;
  cursor: pointer;
  width: 295px;
  height: 70px;
  line-height: 70px;
  border: 1px solid #e3eceb;
  font-weight: 600;
  color: #b50000;
  background-color: #f3f4f6;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: 8% 42%; }
  .forCustomerButton input {
    display: none; }
    .forCustomerButton input:checked + label {
      outline: 1px solid #00cfb6; }
    .forCustomerButton input:checked ~ .forCustomerCont {
      display: block;
      height: auto; }
  .forCustomerButton label span {
    line-height: normal;
    display: inline-block;
    vertical-align: middle; }
  .forCustomerButton:nth-child(1) {
    background-image: url(/assets/templates/img/forCustomer001.png); }
  .forCustomerButton:nth-child(2) {
    background-image: url(/assets/templates/img/forCustomer002.png); }
  .forCustomerButton:nth-child(3) {
    background-image: url(/assets/templates/img/forCustomer003.png); }
  .forCustomerButton:nth-child(4) {
    background-image: url(/assets/templates/img/forCustomer004.png); }
  .forCustomerButton .buttonText {
    width: 100%;
    height: 100%;
    padding-left: 77px;
    padding-right: 20px;
    cursor: pointer; }

.forCustomerCont {
  position: absolute;
  top: 95px;
  left: 0;
  line-height: normal;
  display: none;
  cursor: default;
  text-align: left;
  font-weight: normal;
  color: #75848e; }

.forCustomerButton:not(:last-child) {
  margin-right: 20px; }

.certificatesWrapper {
  background-color: #f3f4f6;
  padding-bottom: 37px;
  padding-top: 20px; }

.certificateItem {
  display: block;
  width: 100%;
  height: 275px; }
  .certificateItem a {
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat; }

.questionBlock {
  width: 100%; }

.questionItem {
  width: 100%;
  border: 1px solid #e3eceb;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 100px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative; }

.uncutter {
  transition: min-height ease 0.3s; }
  .uncutter.active {
    min-height: 104px; }
  .uncutter .dottes {
    display: contents; }
  .uncutter.active .dottes, .uncutter .cutter {
    display: none; }
  .uncutter.active .cutter {
    display: contents; }

.questionSvgWrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  top: 22px;
  left: 22px;
  background-color: #b50000; }

.questionSvg {
  width: 24px;
  height: 24px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.questionTitle {
  display: inline-block;
  width: 295px;
  vertical-align: top;
  color: #b50000;
  font-size: 20px;
  margin-right: 133px;
  position: relative; }

.questionCont {
  display: inline-block;
  width: 680px;
  vertical-align: top;
  position: relative; }

.readmore-button__show {
  display: inline-block;
  color: #b50000;
  text-decoration: underline; }

.questionTitle:after {
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background-color: #b50000;
  position: absolute;
  top: 10px;
  right: -115px; }

.reviews {
  padding-top: 25px; }

.reviewsWrapper {
  padding: 50px 0 90px 0;
  margin-bottom: 65px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../img/reviews_bg.jpg"); }
  .reviewsWrapper .reviewItem {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: inherit;
    box-sizing: border-box;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 34px;
    border-radius: 4px; }

a[data-discount="true"] {
  color: #b50000 !important; }

.reviewsPageWrapper {
  margin-bottom: 0; }

.reviewItem:not(:last-child) {
  margin-bottom: 20px; }

.reviewTitle {
  display: inline-block;
  width: 465px;
  padding-right: 60px;
  vertical-align: top;
  position: relative; }
  .reviewTitle:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #e3eceb;
    position: absolute;
    top: 0;
    right: 60px; }

.reviewPhoto {
  width: 85px;
  height: 85px;
  display: inline-block;
  vertical-align: top;
  border-radius: 1000px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 30px;
  background-size: cover; }

.reviewPerson {
  color: #2a3b45;
  font-weight: 600;
  margin-bottom: 5px; }

.reviewInfo {
  display: inline-block;
  vertical-align: top;
  margin-top: 18px; }

.reviewTitle .day, .reviewTitle .month, .reviewTitle .year {
  display: inline-block; }

.reviewCont {
  display: inline-block;
  width: 690px;
  vertical-align: top; }

.reviewsButtonWrapper {
  text-align: right;
  padding: 30px 0; }

.form_discount {
  letter-spacing: -0.31em; }

.productionWrapper {
  padding: 30px 0; }

.productionSlogans {
  margin-bottom: 20px;
  padding-top: 34px; }

.productionGalleryBlock {
  letter-spacing: -0.31em; }

.productionGalleryImage {
  display: inline-block;
  vertical-align: top;
  width: calc(25% - 15px);
  margin-right: 20px;
  overflow: hidden;
  border-radius: 4px; }
  .productionGalleryImage:nth-child(4n) {
    margin-right: 0; }
  .productionGalleryImage a {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover; }

.productionTitle {
  font-size: 24px;
  color: #b50000;
  text-transform: uppercase;
  margin-bottom: 52px; }

.productionItemsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.productionItem {
  width: 300px;
  height: 100px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e3eceb;
  text-align: center;
  padding-top: 36px;
  vertical-align: top;
  position: relative; }

.productionItemNumber {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: #b50000;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center; }

.productionItemNumber img {
  max-width: 80%;
  max-height: 67%; }

.productionItemTitle {
  color: #b50000;
  font-weight: 600;
  margin-bottom: 15px; }

.productionItemCont {
  width: 80%;
  margin: 0 auto; }

.discountBanner {
  box-sizing: border-box;
  background-image: url(/assets/templates/img/bannerBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }

.discountBannerTitle {
  font-size: 24px;
  color: #fff;
  width: 450px;
  margin: 0 auto;
  margin-bottom: 9px; }

.bannerTitleColor {
  color: #b50000; }

.discountBannerSubtitle {
  color: #fff; }

.discountBannerForm, .contactsForm {
  margin: 25px auto;
  width: 660px;
  text-align: left; }

.discountBannerForm .inputholder, .contactsForm .inputholder {
  width: 230px;
  display: inline-block;
  margin-right: 16px; }

.discountBannerForm .inputholder:before, .contactsForm .inputholder:before {
  display: none; }

.discountBannerForm .inputholder input, .contactsForm .inputholder input {
  width: 230px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0 20px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.discountBannerForm input.wrong {
  background-color: #ffd8dc;
  border: 1px solid red; }

.discountBannerForm .carouselFormButton {
  display: inline-block;
  width: 160px;
  height: 40px;
  border: none;
  outline: none;
  background-color: #b50000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  vertical-align: top; }

.contactsForm .contactsFormButton {
  display: inline-block;
  width: 160px;
  height: 40px;
  border: none;
  outline: none;
  background-color: #b50000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  vertical-align: top; }

.discountConfidential {
  margin-top: 15px;
  letter-spacing: normal; }
  .discountConfidential a {
    color: #75848e;
    text-decoration: underline; }

.contactsConfidential a {
  color: #75848e;
  text-decoration: underline; }

/******************* NEWS PAGE ***********************/
.news {
  padding: 25px 0 35px 0; }

.newsControlPanel {
  text-align: center; }
  .newsControlPanel .newsControlButton {
    display: inline-block;
    width: 85px;
    height: 30px;
    background-color: #fff;
    color: #b50000;
    line-height: 30px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #b50000; }
    .newsControlPanel .newsControlButton:hover, .newsControlPanel .newsControlButton.active {
      background-color: #b50000;
      color: #fff; }
    .newsControlPanel .newsControlButton:last-child {
      margin-right: 0; }

.newsItemWrapper {
  margin-top: 20px;
  position: relative;
  padding-bottom: 30px; }
  .newsItemWrapper #mse2_results:after {
    content: "";
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto; }
  .newsItemWrapper .newsItemInfo {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
    padding: 0 16px; }
  .newsItemWrapper .newsItem .newsImage img {
    width: 100%; }

.newsBlock:after {
  content: "";
  clear: both;
  display: table; }

.mse2_pagination {
  position: relative; }
  .mse2_pagination .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center; }
    .mse2_pagination .pagination .page-item {
      width: 25px;
      height: 25px;
      border: 1px solid #b50000;
      background: transparent;
      border-radius: 4px;
      margin-right: 10px;
      text-align: center; }
      .mse2_pagination .pagination .page-item:hover {
        background: #b50000; }
        .mse2_pagination .pagination .page-item:hover a {
          color: #fff; }
      .mse2_pagination .pagination .page-item a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        line-height: 25px;
        color: #b50000; }
      .mse2_pagination .pagination .page-item.active {
        background: #b50000; }
        .mse2_pagination .pagination .page-item.active a {
          color: #ffffff; }
      .mse2_pagination .pagination .page-item:first-child, .mse2_pagination .pagination .page-item:last-child {
        display: none;
        width: 15px;
        height: 15px;
        background-color: #b50000;
        border: 1px solid #b50000; }
      .mse2_pagination .pagination .page-item:first-child a, .mse2_pagination .pagination .page-item:last-child a {
        line-height: 13px;
        color: white; }

.page-item.active a:hover {
  color: #ffffff !important; }

.newsItemWrapper .newsCarousel .newsItem {
  display: inline-block;
  width: 304px;
  float: none; }

.newsBlock {
  width: 1280px; }

.newsItemPrices {
  padding: 0px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.newsItemPrices .catalogItemPrice {
  font-size: 16px;
  margin-right: 10px; }

/******************* filter *******************/
.catalogFilterWrapper .bottom_block button {
  margin: 20px 0px; }

.testFilter .newsButtonsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.testFilter .filterButton {
  display: inline-block;
  width: 85px;
  height: 30px;
  background-color: #fff;
  color: #b50000;
  line-height: 30px;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #b50000;
  text-align: center;
  outline: none; }
  .testFilter .filterButton.btnNewsReset {
    display: inline-block !important;
    vertical-align: top;
    visibility: visible !important;
    width: 85px;
    height: 30px;
    background-color: #fff;
    color: #b50000;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid #b50000;
    text-align: center;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-top: 4px;
    line-height: 22px; }
  .testFilter .filterButton.active {
    color: #fff;
    background-color: #b50000; }

.testFilter fieldset {
  text-align: center;
  border: none;
  display: inline-block;
  vertical-align: top; }

.testFilter .filterButton input {
  display: none; }

.testFilter .filterButton label {
  width: 100%;
  height: 100%;
  font-weight: normal;
  cursor: pointer; }

.catalogFilterWrapper {
  border: 1px solid #e5ebea;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px; }
  .catalogFilterWrapper .bottom_block {
    padding: 0px 10px 0px;
    text-align: right; }
  .catalogFilterWrapper .catalogSideMenu {
    background-color: #f3f4f6;
    padding: 14px 15px; }
    .catalogFilterWrapper .catalogSideMenu span, .catalogFilterWrapper .catalogSideMenu a {
      color: #75848e;
      cursor: pointer; }
    .catalogFilterWrapper .catalogSideMenu li {
      display: block;
      overflow: hidden;
      -webkit-transition: all ease 0.5s;
      -o-transition: all ease 0.5s;
      transition: all ease 0.5s; }
      .catalogFilterWrapper .catalogSideMenu li.menuActive, .catalogFilterWrapper .catalogSideMenu li.active {
        max-height: 265px; }
      .catalogFilterWrapper .catalogSideMenu li:not(:last-child) {
        margin-bottom: 12px; }
    .catalogFilterWrapper .catalogSideMenu > li {
      max-height: 22px; }
    .catalogFilterWrapper .catalogSideMenu > li > div {
      position: relative;
      cursor: pointer; }
      .catalogFilterWrapper .catalogSideMenu > li > div > a {
        line-height: 22px;
        height: 22px;
        color: #b50000;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        width: calc(100% - 20px); }
    .catalogFilterWrapper .catalogSideMenu > .parent > div:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      transition: all ease-in-out .5s;
      right: 0;
      border: 6px solid transparent;
      border-left: 10px solid #79838c;
      transform-origin: center center; }
    .catalogFilterWrapper .catalogSideMenu > li.menuActive > div:after, .catalogFilterWrapper .catalogSideMenu > li.active > div:after {
      transform: rotateZ(90deg);
      right: 4px; }
  .catalogFilterWrapper .catalogSideMenuInner {
    display: block;
    width: 100%;
    margin-top: 15px; }
    .catalogFilterWrapper .catalogSideMenuInner > li {
      margin-bottom: 10px; }
      .catalogFilterWrapper .catalogSideMenuInner > li > a {
        display: flex;
        justify-content: space-between; }
      .catalogFilterWrapper .catalogSideMenuInner > li.active > a {
        font-weight: 600;
        color: #b50000; }
      .catalogFilterWrapper .catalogSideMenuInner > li > a > span {
        float: right;
        font-weight: normal;
        color: #75848e;
        margin-left: 6px; }
  .catalogFilterWrapper #mse2_filters .disabled {
    color: #d7d7d7; }
    .catalogFilterWrapper #mse2_filters .disabled .checkboxBox {
      border: 1px solid #d7d7d7; }
  .catalogFilterWrapper button:focus {
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #af3e3e;
    outline: none; }
  .catalogFilterWrapper button:hover {
    background-color: #af3e3e;
    border-color: #af3e3e;
    color: #fff; }
  .catalogFilterWrapper button:active {
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #af3e3e;
    color: #af3e3e; }

.filterSliderWrapper {
  max-height: 65px;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  margin: 0 9px;
  padding: 0 6px;
  border-bottom: 1px solid #e5ebea;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .filterSliderWrapper .filter_title {
    font-size: 14px;
    color: #333d46;
    margin: 24px 0; }
  .filterSliderWrapper.active {
    max-height: 350px; }
  .filterSliderWrapper.resource {
    max-height: 38px; }
    .filterSliderWrapper.resource.active {
      max-height: 350px; }
    .filterSliderWrapper.resource > .filter_title {
      margin: 9px 0;
      text-transform: uppercase; }
  .filterSliderWrapper.active .filter_title:after {
    -webkit-transform: rotateZ(90deg);
    -ms-transform: rotate(90deg);
    transform: rotateZ(90deg); }
  .filterSliderWrapper .filter_title {
    position: relative;
    cursor: pointer;
    font-weight: 600; }
    .filterSliderWrapper .filter_title:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      right: -7px;
      border: 6px solid transparent;
      border-left: 10px solid #79838c;
      transition: all ease-in-out .5s;
      transform-origin: center center; }

.filterNumbers {
  padding: 0;
  display: inline-block;
  width: 130px;
  position: relative; }
  .filterNumbers:last-child {
    float: right;
    text-align: right; }
  .filterNumbers input {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: normal;
    color: #75848e; }
  .filterNumbers:first-child input {
    text-align: left; }
  .filterNumbers:last-child input {
    text-align: right; }
  .filterNumbers input:focus {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .filterNumbers span {
    font-weight: normal; }

#mse2_selected_wrapper {
  display: none; }

.filterSliderWrapper .checkboxInnerWrapper {
  position: relative;
  margin: 11px 0; }
  .filterSliderWrapper .checkboxInnerWrapper label {
    padding-left: 20px;
    position: relative;
    margin-bottom: 0;
    font-weight: normal;
    color: #79838c; }

.filterSliderWrapper .checkboxBox {
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid black;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .filterSliderWrapper .checkboxBox:before {
    content: "";
    display: none;
    width: 5px;
    height: 5px;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.checkboxInnerWrapper input:checked ~ label {
  color: #333d46;
  font-weight: 600; }
  .checkboxInnerWrapper input:checked ~ label .checkboxBox:before {
    display: block; }

.checkboxInnerWrapper .checkboxAmount {
  float: right; }

.filterSliderWrapper input[type="checkbox"] {
  display: none; }

.ui-slider-horizontal .ui-slider-range {
  background-color: #b50000 !important; }

.ui-widget.ui-widget-content {
  border: 1px solid white !important;
  background-color: #e5ebea; }

#mse2_filters .mse2_number_slider {
  font-size: 0.5em !important; }

.ui-slider-horizontal .ui-slider-handle {
  top: -.5em !important; }

.catalogViewTypes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #e3eceb;
  padding: 10px 20px;
  border-radius: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.catalogSortSvg {
  width: 17px;
  height: 17px;
  margin-right: 15px;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px); }

.catalogAltSvg {
  width: 17px;
  height: 17px;
  margin-right: 15px; }

.catalogViewTypes .sortType {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #384650; }
  .catalogViewTypes .sortType .sort {
    color: #384650;
    margin-left: 20px;
    cursor: pointer; }
    .catalogViewTypes .sortType .sort.active {
      cursor: pointer !important; }

#mse2_sort .sort[data-dir="desc"] span:after, #mse2_sort .sort[data-dir="asc"] span:after {
  color: #b50000; }

.catalogViewTypes .viewType {
  display: inline-block;
  margin-left: auto; }
  .catalogViewTypes .viewType > a {
    color: #384650; }
    .catalogViewTypes .viewType > a:not(:last-child) {
      margin-right: 10px; }
  .catalogViewTypes .viewType .svg_icon {
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px; }

.p_top {
  padding-top: 25px; }

/*********************** PRODUCT *******************************/
.productBigImageWrapper {
  display: inline-block;
  vertical-align: top;
  width: 583px;
  height: 360px;
  position: relative; }

.productBigImage {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 4px;
  background-color: #f3f4f6; }

.productGalleryWrapper {
  display: inline-block;
  width: 760px;
  height: 366px;
  position: relative;
  margin-right: 8px;
  overflow: hidden; }

.productGallery {
  width: 150px;
  height: 310px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden; }

.productGalleryControls {
  position: absolute;
  bottom: 13px;
  right: 80px; }
  .productGalleryControls .pr-gal-up, .productGalleryControls .pr-gal-down {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 22px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #b50000;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer; }
  .productGalleryControls .pr-gal-up:hover, .productGalleryControls .pr-gal-down:hover {
    color: #b50000;
    border: 1px solid #b50000; }
  .productGalleryControls .fa-chevron-up, .productGalleryControls .fa-chevron-down {
    font-size: 11px; }

.productGalleryPicture {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.productPhotosItem {
  width: 150px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .productPhotosItem.active {
    border: 1px solid #b50000;
    padding: 10px; }

.productPhotoImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.productGalleryPicture {
  width: 545px;
  height: 365px; }

.productOrderWrapper {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 506px;
  min-height: 366px;
  vertical-align: top;
  border: 1px solid #e3eceb;
  border-radius: 5px;
  padding: 28px;
  position: relative; }
  .productOrderWrapper .count_input .form-control {
    width: 50px; }
  .productOrderWrapper .productRating {
    text-align: right;
    z-index: 10;
    text-decoration: underline; }
    .productOrderWrapper .productRating .reviewsLink {
      cursor: pointer;
      color: #b50000; }
  .productOrderWrapper .productButtonsWrapper {
    margin-bottom: 0; }

.productOptionsField {
  margin-top: 25px;
  display: flex;
  padding: 25px 20px 10px;
  background-color: #f9f9f9;
  text-align: left; }
  @media screen and (max-width: 1350px) {
    .productOptionsField {
      flex-direction: column; } }

.productOptionsLeft {
  margin-right: 30px;
  width: 180px;
  flex-shrink: 0; }
  @media screen and (max-width: 1350px) {
    .productOptionsLeft {
      margin-right: 0;
      width: 100%; } }

@media screen and (max-width: 1350px) {
  .productOptionsRight {
    margin-top: 20px; } }

.productOptionsRight .productOptionsBlock:not(:first-child) {
  margin-top: 13px; }

.productOptionsBlock:not(:first-child) {
  margin-top: 10px; }

.productOptionsTitle {
  margin-bottom: 7px;
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  color: #404040; }

.productOptionsBoxes {
  display: flex;
  flex-wrap: wrap; }

.productOptionsBox {
  margin: 0 10px 10px 0;
  width: 50px;
  height: 26px;
  border-radius: 2px;
  background-color: #ebeaea;
  font-size: 12px;
  font-weight: 800;
  line-height: 26px;
  color: #7b7b7b;
  text-align: center;
  cursor: pointer;
  transition-duration: 300ms; }
  .productOptionsBox_active {
    background-color: #f5c10c;
    color: #fff;
    cursor: default; }
  .productOptionsBox:hover {
    background-color: #f5c10c;
    color: #fff; }

.productOptionsRadio {
  position: relative;
  top: -3px;
  display: inline-block;
  padding-left: 23px;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  color: #b3afaf;
  cursor: pointer; }
  .productOptionsRadio:first-of-type {
    margin-right: 20px; }
  .productOptionsRadio::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #b3afaf; }
  .productOptionsRadio_active {
    color: #797979;
    cursor: default; }
    .productOptionsRadio_active::before {
      border-color: #939393; }
    .productOptionsRadio_active::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      background-color: #b50000; }

.withOptions .ec-stars {
  display: none; }

.withOptions .productOrderWrapper {
  padding: 20px; }
  @media screen and (max-width: 610px) {
    .withOptions .productOrderWrapper {
      padding: 16px 6px 20px; } }

.withOptions .deliveryWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.withOptions .orderWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px; }

.withOptions .orderPriceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%; }
  @media screen and (max-width: 1120px) {
    .withOptions .orderPriceWrapper {
      order: 1;
      width: 100%;
      align-self: flex-start; } }
  @media screen and (max-width: 1120px) {
    .withOptions .orderPriceWrapper .count_input {
      display: none; } }
  @media screen and (max-width: 1120px) {
    .withOptions .orderPriceWrapper .orderPrice {
      margin-right: 0;
      margin-top: 20px;
      margin-left: auto;
      text-align: left; } }
  @media screen and (max-width: 768px) {
    .withOptions .orderPriceWrapper .orderPrice {
      font-size: 24px; } }
  @media screen and (max-width: 610px) {
    .withOptions .orderPriceWrapper .orderPrice {
      font-size: 18px;
      margin-left: 0; } }

.withOptions .count_input_mobile {
  display: none; }
  @media screen and (max-width: 1120px) {
    .withOptions .count_input_mobile {
      display: block; } }

@media screen and (max-width: 1120px) {
  .withOptions .productTopWrapper {
    display: flex;
    justify-content: space-between; } }

.withOptions .orderPrice {
  line-height: normal; }

@media screen and (max-width: 1120px) {
  .withOptions .paymentOptions {
    margin-top: 45px; } }

@media screen and (max-width: 1120px) {
  .withOptions .article {
    display: none; } }

.withOptions .article_mobile {
  display: none; }
  @media screen and (max-width: 1120px) {
    .withOptions .article_mobile {
      display: block;
      margin-top: 8px;
      padding-top: 0;
      font-size: 14px; } }

.withOptions .productOrderWrapper .productRating {
  text-decoration: none; }
  .withOptions .productOrderWrapper .productRating .reviewsLink {
    text-decoration: underline; }

@media screen and (max-width: 1120px) {
  .withOptions .productButtonsWrapper {
    width: 100%;
    order: 2;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    margin-top: 10px;
    flex-shrink: 1; } }

@media screen and (max-width: 610px) {
  .withOptions .productButtonsWrapper {
    flex-direction: row;
    margin-left: 0; } }

@media screen and (max-width: 1120px) {
  .withOptions .productButtonsWrapper > button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px; }
    .withOptions .productButtonsWrapper > button:last-child {
      margin-bottom: 0; } }

@media screen and (max-width: 610px) {
  .withOptions .productButtonsWrapper > button {
    width: auto;
    font-size: 10px;
    margin-bottom: 0; }
    .withOptions .productButtonsWrapper > button:last-child {
      margin-left: 10px; } }

.catalogItemOptionsWrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  letter-spacing: normal; }
  .catalogItemOptionsWrapper .productOptionsField {
    margin-top: 32px;
    margin-bottom: 20px;
    padding: 20px 30px;
    width: 100%; }
    @media screen and (max-width: 1350px) {
      .catalogItemOptionsWrapper .productOptionsField {
        margin-top: 20px;
        margin-bottom: 0;
        padding: 25px 20px; } }
    @media screen and (max-width: 680px) {
      .catalogItemOptionsWrapper .productOptionsField {
        padding: 15px 15px 10px; } }
  .catalogItemOptionsWrapper .productOptionsBlock {
    margin-top: 0;
    margin-right: 33px; }
    @media screen and (max-width: 680px) {
      .catalogItemOptionsWrapper .productOptionsBlock {
        margin-right: 0; } }
  .catalogItemOptionsWrapper .catalogOptionsBoxes {
    display: flex; }
    @media screen and (max-width: 680px) {
      .catalogItemOptionsWrapper .catalogOptionsBoxes {
        flex-direction: column; } }
    .catalogItemOptionsWrapper .catalogOptionsBoxes .productOptionsBlock {
      max-width: 180px; }
      @media screen and (max-width: 1350px) {
        .catalogItemOptionsWrapper .catalogOptionsBoxes .productOptionsBlock {
          max-width: unset; } }
      @media screen and (max-width: 680px) {
        .catalogItemOptionsWrapper .catalogOptionsBoxes .productOptionsBlock {
          margin-bottom: 10px; } }
  .catalogItemOptionsWrapper .catalogOptionsRadio {
    display: flex; }
    @media screen and (max-width: 1350px) {
      .catalogItemOptionsWrapper .catalogOptionsRadio {
        margin-top: 20px;
        justify-content: space-between; } }
    @media screen and (max-width: 680px) {
      .catalogItemOptionsWrapper .catalogOptionsRadio {
        flex-direction: column;
        margin-top: 0; } }
    .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock {
      max-width: 115px; }
      @media screen and (max-width: 1350px) {
        .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock {
          max-width: unset; } }
      @media screen and (max-width: 680px) {
        .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock {
          margin-bottom: 10px; }
          .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock:last-child {
            margin-bottom: 0; } }
      .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock:last-child {
        margin-right: 0; }

.catalogSection .catalogItem_withOptions.catalogItem {
  height: auto; }

.catalogSection .catalogItem_withOptions .catalogItemCont {
  padding-left: 35px; }
  @media screen and (max-width: 1350px) {
    .catalogSection .catalogItem_withOptions .catalogItemCont {
      padding-left: 18px; } }
  @media screen and (max-width: 680px) {
    .catalogSection .catalogItem_withOptions .catalogItemCont {
      width: 68%; } }

.catalogSection .catalogItem_withOptions.catalogItem.full .catalogItemDescription {
  padding: 0; }
  @media screen and (max-width: 680px) {
    .catalogSection .catalogItem_withOptions.catalogItem.full .catalogItemDescription {
      display: none; } }

@media screen and (max-width: 680px) {
  .catalogSection .catalogItem_withOptions.catalogItem.full .catalogItemImage {
    position: relative;
    top: 0;
    left: 0; } }

@media screen and (max-width: 680px) {
  .catalogSection .catalogItem_withOptions.catalogItem.full .productStock {
    font-size: 10px; } }

@media screen and (max-width: 1350px) {
  .catalogSection .catalogItem_withOptions .catalogItemPrices {
    display: none; } }

.catalogSection .catalogItem_withOptions .catalogItemPrices_mobile {
  display: none; }
  @media screen and (max-width: 1350px) {
    .catalogSection .catalogItem_withOptions .catalogItemPrices_mobile {
      display: flex;
      justify-content: flex-end; } }

.catalogSection .catalogItem_withOptions .catalogItemPrices .catalogItemPriceWrapper {
  margin-right: 40px; }
  @media screen and (max-width: 1350px) {
    .catalogSection .catalogItem_withOptions .catalogItemPrices .catalogItemPriceWrapper {
      margin-right: 20px; } }

.catalogSection .catalogItem_withOptions .catalogItemPrices .catalogItemPrice {
  margin-right: 0; }

.catalogSection .catalogItemSmall_withOptions.catalogItemSmall .catalogItemTitle {
  margin-bottom: 0; }

.catalogSection .catalogItemSmall_withOptions.catalogItemSmall .catalogItemTitle > a {
  min-height: unset; }

.catalogSection .catalogItemSmall_withOptions.catalogItemSmall .catalogItemImage {
  margin-bottom: 20px; }

.catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .productOptionsField {
  margin-top: 0;
  padding: 20px 10px 10px;
  flex-direction: column; }

.catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .productOptionsBlock {
  margin-right: 0;
  margin-bottom: 10px; }

.catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .catalogOptionsBoxes {
  flex-direction: column; }
  .catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .catalogOptionsBoxes .productOptionsBlock {
    max-width: 100%; }

.catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .catalogOptionsRadio {
  flex-direction: column; }
  .catalogSection .catalogItemSmall_withOptions .catalogItemOptionsWrapper .catalogOptionsRadio .productOptionsBlock {
    max-width: 100%; }

.catalogSection .catalogItemSmall_withOptions .catalogItemPriceWrapper {
  margin-right: 40px; }
  @media screen and (max-width: 1350px) {
    .catalogSection .catalogItemSmall_withOptions .catalogItemPriceWrapper {
      margin-right: 20px; } }

.catalogSection .catalogItemSmall_withOptions .catalogItemPrice {
  margin-right: 0; }

.ec-stars {
  margin-top: 5px !important; }

.productStock {
  position: relative;
  color: #83c550;
  padding-left: 17px;
  margin-bottom: 8px;
  font-size: 12px; }

.productInStock:first-letter {
  text-transform: uppercase; }

.productStock:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #83c550;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0; }

.productStock.avail {
  color: #ffba00; }
  .productStock.avail:before {
    background-color: #ffba00; }

.productStock.preorder {
  color: red; }
  .productStock.preorder:before {
    background-color: red; }

.chooseSizeTitle {
  margin-bottom: 10px; }

.sizeItem {
  display: inline-block;
  width: 122px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative; }
  .sizeItem:hover label {
    color: #b50000;
    border: 1px solid #b50000; }
  .sizeItem label {
    font-weight: normal;
    width: 100%;
    height: 100%;
    border: 1px solid #75848e;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
  .sizeItem input {
    opacity: 0.0000001;
    margin-top: 22%; }
    .sizeItem input:checked + label {
      color: #b50000;
      border: 1px solid #b50000; }

.paymentOptions {
  margin-bottom: 13px; }

.paymentOptionsTitle {
  display: inline-block;
  vertical-align: middle; }

.paymentOptions img {
  vertical-align: middle;
  margin-left: 8px; }

.deliveryPlace, .deliveryPrice, .currency, .deliveryDays, .time {
  color: #b50000;
  font-weight: 600; }

.article {
  font-size: 17px;
  font-weight: bold;
  text-align: right;
  color: #000000;
  padding-top: 20px; }

.catalogItemCont .article {
  margin-top: 20px;
  margin-bottom: 15px;
  padding-top: 0; }

.orderWrapper {
  padding-top: 27px;
  text-align: right; }

.orderPrice {
  font-size: 24px;
  line-height: 40px;
  color: #b50000;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
  float: left; }

.orderCurrency {
  font-size: 14px; }

.orderButton {
  cursor: pointer;
  padding: 0 9px;
  height: 40px;
  line-height: 40px;
  width: 116px;
  text-align: center;
  background-color: #00bd9b;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #00bd9b;
  outline: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s; }
  .orderButton:hover {
    background-color: #fff;
    color: #00bd9b; }

/*
.oneClickButton {
    cursor: pointer;
    padding: 0 9px;
    height: 40px;
    line-height: 40px;
    width: 150px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #00bd9b;
    color: #00bd9b;
    text-transform: uppercase;
    display: inline-block;
    outline: none;
    border-radius: 4px;
}
.oneClickButton:hover {
    background-color: #00bd9b;
    color: #fff;
}*/
.productContWrapper {
  margin-bottom: 12px;
  height: 333px;
  position: relative;
  margin-top: 20px; }

.productContMenu {
  width: 330px;
  height: 100%;
  background-color: #f3f4f6;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.productContMenuItem {
  display: block;
  color: #b50000;
  font-weight: 600;
  margin-bottom: 20px;
  cursor: pointer; }

.menuText {
  display: inline-block;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer; }
  .menuText:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    display: block;
    width: 14px;
    height: 14px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }

.active .menuText:before {
  filter: sepia(1) brightness(18); }

.desc:before {
  background-image: url("../img/svg/bars-solid.svg"); }

.tech:before {
  background-image: url("../img/svg/list-ol-solid.svg"); }

.delivery:before {
  background-image: url("../img/svg/boxes-solid.svg"); }

.pay:before {
  background-image: url("../img/svg/money-check-alt-solid.svg"); }

.productContMenuItem.active .menuText {
  color: #b50000;
  text-decoration: none; }
  .productContMenuItem.active .menuText svg {
    fill: #b50000; }

.productContMenuItem.active .productContMenuText {
  display: block; }

.productContMenuText {
  width: 925px;
  height: 333px;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #f3f4f6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: auto;
  color: #75848e;
  font-style: normal;
  font-weight: normal;
  display: none;
  cursor: default; }

.productContTitle {
  position: relative;
  font-size: 20px;
  margin-bottom: 20px;
  color: #b50000;
  font-weight: 600; }
  .productContTitle .svg_icon {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 20px; }

.catalogItemOption {
  color: #75848e;
  font-style: normal;
  font-weight: normal; }
  .catalogItemOption .optionValue {
    font-weight: 600;
    color: #b50000; }

.productContMenuText::-webkit-scrollbar-track, .forCustomerCont::-webkit-scrollbar-track {
  background-color: #f3f4f6; }

.productContMenuText::-webkit-scrollbar-thumb, .forCustomerCont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #75848e;
  width: 8px; }

.productContMenuText::-webkit-scrollbar-thumb:hover, .forCustomerCont::-webkit-scrollbar-thumb:hover {
  background-color: #56999f; }

.productContMenuText::-webkit-scrollbar, .forCustomerCont::-webkit-scrollbar {
  width: 10px; }

.productContMenuText::-webkit-scrollbar-button, .forCustomerCont::-webkit-scrollbar-button {
  background-image: url("");
  background-repeat: no-repeat;
  width: 5px;
  height: 0px; }

.productContMenuText::-webkit-resizer, .forCustomerCont::-webkit-resizer {
  background-image: url("");
  background-repeat: no-repeat;
  width: 4px;
  height: 0px; }

.productReviewWrapper {
  margin-bottom: 20px; }
  .productReviewWrapper.reviewsWrapper {
    background-color: #ffffff; }
  .productReviewWrapper .ec-message {
    border: none;
    box-shadow: none; }
  .productReviewWrapper .reviewSend {
    margin: 0; }
  .productReviewWrapper .reviewInfo {
    margin-top: 5px; }

.ec-rating {
  margin-top: 20px; }
  .ec-rating .ec-rating-stars {
    float: none; }
    .ec-rating .ec-rating-stars span {
      float: none;
      display: inline-block; }

.reviewInfo .ec-stars {
  margin: 10px auto; }

.menuText {
  padding-left: 22px;
  position: relative; }
  .menuText .svg_icon {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }

/*********************** PORTFOLIO *****************************/
.portfolioGalleryWrapper {
  padding: 25px 0;
  padding-bottom: 40px; }

.portfolioGallery .gal-item {
  padding: 0;
  border: none;
  margin: 0;
  opacity: 1; }

.portfolioGalleryItem a:after {
  display: none; }

.portfolioGalleryBlock {
  width: 100%;
  height: 574px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 178px 20px 178px 20px 178px;
  grid-template-rows: 178px 178px 178px;
  grid-column-gap: 20px;
  grid-row-gap: 20px; }
  .portfolioGalleryBlock > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .portfolioGalleryBlock > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .portfolioGalleryBlock > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; }
  .portfolioGalleryBlock > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 7; }
  .portfolioGalleryBlock > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .portfolioGalleryBlock > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .portfolioGalleryBlock > *:nth-child(7) {
    -ms-grid-row: 3;
    -ms-grid-column: 5; }
  .portfolioGalleryBlock > *:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-column: 7; }
  .portfolioGalleryBlock > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .portfolioGalleryBlock > *:nth-child(10) {
    -ms-grid-row: 5;
    -ms-grid-column: 3; }
  .portfolioGalleryBlock > *:nth-child(11) {
    -ms-grid-row: 5;
    -ms-grid-column: 5; }
  .portfolioGalleryBlock > *:nth-child(12) {
    -ms-grid-row: 5;
    -ms-grid-column: 7; }

.portfolioGalleryItem {
  border-radius: 4px;
  overflow: hidden; }
  .portfolioGalleryItem a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .portfolioGalleryItem:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2; }
  .portfolioGalleryItem:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }
  .portfolioGalleryItem:nth-child(3) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }
  .portfolioGalleryItem:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4; }
  .portfolioGalleryItem:nth-child(5) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4; }
  .portfolioGalleryItem:nth-child(6) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3 / 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3; }
  .portfolioGalleryItem:nth-child(7) {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / 5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2; }
  .portfolioGalleryItem:nth-child(8) {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / 5;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3; }
  .portfolioGalleryItem:nth-child(9) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3 / 5;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4; }
  .portfolioGalleryItem .pagetitlehidden {
    display: none; }

/******************** CATALOG ******************************/
.catalogSection {
  padding: 20px 0; }
  .catalogSection .row {
    margin: 0; }
  .catalogSection .catalogItem {
    width: 100%;
    margin-left: 0;
    height: auto;
    padding: 15px;
    height: 250px;
    letter-spacing: -0.31em; }

.sideCatalogMenuWrapper {
  display: inline-block;
  margin-right: 20px;
  width: 295px;
  border: 1px solid #e3eceb;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 33px; }

.sideCatalogMenu {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 295px;
  padding: 20px;
  background-color: #f3f4f6;
  display: inline-block;
  vertical-align: top; }
  .sideCatalogMenu li {
    display: block; }
    .sideCatalogMenu li > ul {
      display: block;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .sideCatalogMenu li.active > ul {
      max-height: 300px; }
  .sideCatalogMenu > li {
    padding: 12px 0; }
    .sideCatalogMenu > li.active {
      padding-bottom: 0; }
      .sideCatalogMenu > li.active > a:after {
        -webkit-transform: translateY(-50%) rotate(90deg);
        -ms-transform: translateY(-50%) rotate(90deg);
        transform: translateY(-50%) rotate(90deg); }
    .sideCatalogMenu > li > a:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(0);
      -ms-transform: translateY(-50%) rotate(0);
      transform: translateY(-50%) rotate(0);
      right: 0;
      background-color: #75848e;
      width: 7px;
      height: 7px;
      -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
      clip-path: polygon(0 0, 100% 50%, 0 100%); }
    .sideCatalogMenu > li > a {
      color: #b50000;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      display: block;
      position: relative; }
  .sideCatalogMenu > li > ul > li {
    padding: 8px 0; }
    .sideCatalogMenu > li > ul > li > a {
      color: #909090;
      text-decoration: none; }

.sideCatalogMenuOptions {
  padding: 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .sideCatalogMenuOptions > li > .menuOptionsTitle {
    position: relative; }
    .sideCatalogMenuOptions > li > .menuOptionsTitle:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(0);
      -ms-transform: translateY(-50%) rotate(0);
      transform: translateY(-50%) rotate(0);
      right: 0;
      background-color: #75848e;
      width: 7px;
      height: 7px;
      -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
      clip-path: polygon(0 0, 100% 50%, 0 100%); }
  .sideCatalogMenuOptions > li.active > .menuOptionsTitle:after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg); }

.sideCatalogMenu > li.active > a:after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg); }

.sideCatalogMenuOptions > li {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 55px;
  overflow: hidden;
  border-bottom: 1px solid #e3eceb; }
  .sideCatalogMenuOptions > li.active {
    max-height: 300px;
    -webkit-transition: max-height ease .5s;
    -o-transition: max-height ease .5s;
    transition: max-height ease .5s; }
  .sideCatalogMenuOptions > li > .menuOptionsTitle {
    cursor: pointer; }

.catalogMain {
  display: inline-block;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 960px; }
  .catalogMain .catalogMainSorting {
    width: 100%;
    height: 38px;
    font-size: 12px;
    color: #b50000;
    font-weight: 600;
    border: 1px solid #e3eceb;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px; }
  .catalogMain .sortBy {
    margin-right: 30px; }
  .catalogMain .sortOption {
    margin: 0 20px;
    cursor: pointer;
    position: relative; }
    .catalogMain .sortOption .sortPic {
      position: absolute;
      top: 0px;
      right: -15px;
      width: 10px;
      height: 10px;
      display: none; }
    .catalogMain .sortOption.active {
      color: #b50000; }
      .catalogMain .sortOption.active .sortPic {
        display: block; }
  .catalogMain .catalogMainSorting ul {
    display: inline-block; }
  .catalogMain .sortSvg {
    width: 22px;
    height: 22px;
    margin-right: 30px; }
  .catalogMain .catalogViewType svg {
    fill: #75848e; }
  .catalogMain .viewBig.active .viewBigSvg, .catalogMain .viewSmall.active .viewSmallSvg {
    fill: #b50000; }
  .catalogMain .viewBigSvg, .catalogMain .viewSmallSvg {
    width: 22px;
    height: 22px; }
  .catalogMain .catalogViewType {
    margin-left: auto; }
    .catalogMain .catalogViewType div {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 10px; }
  .catalogMain .catalogItem {
    margin-left: 0;
    width: 100%;
    padding: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }

.catalogSection .catalogItemImage {
  margin: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  overflow: hidden;
  position: relative; }

.catalogItem .catalogItemImage {
  margin: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%; }

.catalogItem.full .catalogItemImage {
  width: 29%; }

.catalogSection .catalogItemImage .catalogItemHit, .productBigImageWrapper .catalogItemHit, .hitItem .catalogItemHit, .cartItem .catalogItemHit {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #b50000;
  z-index: 10;
  border-radius: 4px;
  background-image: url(/assets/templates/img/fire.png);
  background-repeat: no-repeat;
  background-position: center center; }

.catalogSection .catalogItemDiscount, .productBigImageWrapper .catalogItemDiscount, .hitItemImage .catalogItemDiscount, .catalogItemImage .catalogItemDiscount, .cartItem .catalogItemDiscount {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #b50000;
  color: #b50000;
  z-index: 10; }

.catalogSection .catalogItemCont {
  display: inline-block;
  vertical-align: top;
  width: 69%;
  padding-left: 10px;
  letter-spacing: normal; }

.catalogItemSmall .catalogItemCont {
  width: 100%;
  margin-left: 0;
  padding: 0; }

.catalogItemSmall .catalogItemDescription {
  margin-bottom: 10px; }

.catalogSection .catalogItemTitle {
  margin: 0;
  padding: 0;
  font-weight: 600;
  text-align: left;
  margin-bottom: 11px; }

.catalogSection .pagination {
  float: right; }

.catalogMain .productInStock {
  color: #83c550;
  position: relative;
  margin-left: 17px;
  margin-bottom: 8px;
  font-size: 12px;
  margin-top: 10px; }

.catalogSection .catalogItemDescription {
  height: 100px;
  overflow: hidden;
  margin-bottom: 18px; }

.catalogSection .catalogResultsWrapper {
  padding-right: 0; }

.catalogSection .catalogItem.full .catalogItemDescription {
  padding: 0px 10px;
  height: 84px; }

.catalogSection .catalogItem.full .catalogItemDescription::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.catalogSection .catalogItem.full .catalogItemDescription::-webkit-scrollbar {
  width: 6px;
  background-color: #fff; }

.catalogSection .catalogItem.full .catalogItemDescription::-webkit-scrollbar-thumb {
  background-color: #b50000; }

/*
.catalogSection .catalogResultsWrapper #mse2_results {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 20px;
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}*/
.catalogMain .catalogItemPrice {
  position: relative; }

.catalogItemSmall {
  width: calc(100% / 3 - 13.5px);
  display: inline-block;
  border: 1px solid #e3eceb;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px; }
  .catalogItemSmall:nth-child(3n) {
    margin-right: 0; }

.catalogSection .catalogItemSmall .catalogItemImage {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2vw;
  height: 198px; }

.catalogMain .catalogItemOldPrice {
  left: -90%;
  color: #75848e;
  position: relative; }

.sliderItemWrapper {
  padding: 17px 0;
  background-color: #fff;
  overflow: hidden; }

.sliderItemLabel {
  width: 100%;
  display: block;
  margin-bottom: 24px;
  color: #b50000;
  font-weight: 600; }

.sliderItemWrapper .ui-slider-horizontal.sliderItem {
  height: 3px;
  margin-bottom: 10px;
  border: none;
  background-color: #e3eceb; }

.sliderItemWrapper .ui-state-default {
  border: none !important;
  width: 11px !important;
  height: 11px !important;
  border-radius: 20px;
  outline: none;
  background-color: #b50000 !important; }

.ui-widget-content .ui-state-default {
  border: none !important;
  width: 11px !important;
  height: 11px !important;
  border-radius: 20px;
  outline: none;
  background-color: #b50000 !important; }

.sliderItemWrapper .ui-state-active, .ui-widget-content .ui-state-active {
  background-color: #b50000; }

.sliderItemWrapper .ui-slider-range.ui-corner-all.ui-widget-header {
  background-color: #b50000 !important; }

.sliderItemWrapper .sliderValue {
  display: inline-block;
  width: 49%; }

.sliderItemWrapper .sliderValueRight {
  text-align: right; }

.checkboxWrapper {
  padding: 17px 0; }

.checkboxTitle {
  width: 100%;
  display: block;
  margin-bottom: 14px;
  color: #b50000;
  font-weight: 600; }

.checkboxGroup {
  position: relative;
  margin-bottom: 8px; }
  .checkboxGroup .checkboxButton {
    width: 12px;
    height: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid #75848e; }
    .checkboxGroup .checkboxButton:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      background-color: #b50000;
      opacity: 0; }
  .checkboxGroup .checkboxItem {
    display: none; }
  .checkboxGroup .checkboxLabel {
    padding-left: 20px; }
  .checkboxGroup .checkboxItem:checked ~ .checkboxLabel {
    color: black;
    font-weight: 600; }
    .checkboxGroup .checkboxItem:checked ~ .checkboxLabel .checkboxButton:after {
      opacity: 1; }

.sideCatalogResetButton {
  width: 116px;
  height: 40px;
  background-color: #fff;
  color: #ca3c3c;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  text-decoration: none;
  margin-top: 18px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #ca3c3c;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s; }
  .sideCatalogResetButton:hover {
    background-color: #ca3c3c;
    color: #fff; }

.resetButtonWrapper {
  text-align: right;
  padding: 0 20px; }

.catalogSection .pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .catalogSection .pagination li {
    width: 25px;
    height: 25px;
    border: 1px solid #333d46;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px; }
    .catalogSection .pagination li:first-child, .catalogSection .pagination li:last-child {
      width: 15px;
      height: 15px;
      border: none; }
    .catalogSection .pagination li:first-child a, .catalogSection .pagination li:last-child a {
      display: block;
      width: 100%;
      height: 100%; }
    .catalogSection .pagination li:first-child span, .catalogSection .pagination li:last-child span {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #b50000;
      text-align: center;
      line-height: 15px;
      border-radius: 3px;
      color: #fff; }
    .catalogSection .pagination li.disabled {
      border-color: #e3eceb;
      cursor: default; }
      .catalogSection .pagination li.disabled a {
        pointer-events: none; }
      .catalogSection .pagination li.disabled span {
        background-color: #eeeeee; }
    .catalogSection .pagination li a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #333d46;
      line-height: 22px; }
    .catalogSection .pagination li.active {
      border: 1px solid #b50000; }
      .catalogSection .pagination li.active a {
        color: #b50000; }
  .catalogSection .pagination > li > a:hover {
    color: #fff; }
  .catalogSection .pagination > li.active > a:hover {
    color: #b50000; }

.container_12:before,
.container_12:after {
  display: none; }

.container_12.catalogWrapper {
  padding-top: 25px; }

/************************* CONTACTS ***************************/
.contactsBlock {
  background-color: #fff;
  margin-bottom: 40px; }

.contactsTitle {
  margin-top: 20px; }

.contactsTab {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 200px;
  height: 60px;
  background-color: #f3f4f6;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  color: #b50000;
  border: 1px solid #e3eceb;
  border-radius: 4px 4px 0 0;
  margin-right: 10px;
  vertical-align: bottom;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .contactsTab.active {
    height: 70px;
    line-height: 70px;
    color: #fff;
    background-color: #b50000;
    border: 1px solid #b50000; }

.contactsWrap {
  display: inline-block;
  width: 405px;
  vertical-align: top; }

.officePicItem {
  display: block;
  height: 154px;
  width: 285px;
  border-radius: 4px;
  overflow: hidden; }
  .officePicItem a {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover; }

.contBlocks {
  position: relative; }
  .contBlocks .contactsWrapper {
    height: 605px;
    position: relative; }
    .contBlocks .contactsWrapper .contactsWrap {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      padding: 30px;
      display: block;
      border: 1px solid #b50000;
      border-radius: 0 5px 5px 5px;
      padding-right: 730px;
      height: 470px;
      opacity: 0; }
  .contBlocks .contactsTab.active + .contactsWrap {
    opacity: 1;
    z-index: 10; }

.mapHolderWrap {
  width: 610px;
  height: 430px;
  position: absolute;
  top: 90px;
  right: 30px;
  z-index: 10; }

.mapHolder {
  height: 430px;
  position: relative; }

.ymFiltersBlock {
  width: auto !important;
  height: 60px !important;
  position: absolute;
  top: 0;
  right: 0;
  display: none; }

.ymFiltersForm ul {
  padding: 10px !important;
  background-color: #fff;
  border: 1px solid #b50000;
  border-radius: 4px; }
  .ymFiltersForm ul li {
    padding: 0 !important; }
    .ymFiltersForm ul li a {
      color: #b50000; }

.contactIconWrapper {
  display: inline-block;
  margin-right: 8px; }

.contactLocationTitle, .contactPhoneTitle, .contactMailTitle, .contactScheduleTitle {
  vertical-align: top;
  line-height: 25px; }

.contactLocation {
  margin-bottom: 20px; }
  .contactLocation .locationText {
    color: #b50000;
    font-size: 20px; }

.headerLocation .locationText a {
  color: #404040; }

.contactPhone, .contactMail {
  margin-bottom: 20px; }

.contactPhone .phoneText {
  text-align: left;
  font-size: 20px; }
  .contactPhone .phoneText a {
    color: #b50000;
    text-decoration: none; }
  .contactPhone .phoneText.main a {
    font-weight: 600; }

.contactMail .mailText a {
  color: #b50000;
  text-decoration: none;
  font-weight: normal;
  font-size: 20px; }

.scheduleText a {
  color: #b50000; }

.contactSchedule .scheduleText {
  font-weight: 400;
  font-size: 20px;
  color: #b50000;
  width: 195px; }

.contactSocial {
  margin-top: 25px; }
  .contactSocial .socialIcons a {
    margin-right: 0;
    line-height: 43px; }
  .contactSocial .socialIcons .socialIcon {
    fill: #2a3a44; }

.contactSocialIconWrapper {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #f3f4f6;
  text-align: center;
  margin-right: 20px;
  border-radius: 4px; }

.officePicCarouselWrapper {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1200px; }

.contactsFormOuter {
  margin-bottom: 50px; }

.contactsFormWrapper {
  background-color: #f3f4f6;
  padding: 25px; }

.contactsForm {
  width: 500px;
  margin: 0 auto; }

.contactsFormTitle, .contactsFormSubtitle {
  text-align: center; }

.contactsFormTitle {
  font-size: 25px;
  color: #b50000;
  margin-bottom: 20px; }

.contactsFormSubtitle {
  margin-bottom: 20px; }

.contactsForm .inputholder {
  width: 48%; }
  .contactsForm .inputholder input {
    width: 100%; }
    .contactsForm .inputholder input.wrong {
      border: 1px solid red; }
  .contactsForm .inputholder:nth-child(2) {
    margin-right: 0; }
  .contactsForm .inputholder:nth-child(3) {
    margin-right: 0;
    width: 100%; }
  .contactsForm .inputholder textarea {
    height: 100px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px; }

.contactsButtonWrapper {
  text-align: right;
  margin-top: 20px; }

.contactsFormButton {
  display: block; }

.contactsConfidential {
  color: #75848e; }
  .contactsConfidential a {
    color: #75848e; }

.officePhoto {
  display: block;
  width: 275px;
  height: 154px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 4px; }

.officeTitleWrapper {
  margin-top: 20px; }
  .officeTitleWrapper .title:before, .officeTitleWrapper .title:after {
    width: 983px;
    right: -1031px; }

/***************************** CART **************************/
.form-control {
  box-shadow: none;
  border-color: #e0e0e0;
  padding: 0 20px;
  height: 40px; }

.form-horizontal .control-label {
  margin-bottom: 5px; }

.cartWrapper {
  min-height: 500px;
  padding-bottom: 50px; }

#msCart .title:before, #msCart .title:after {
  display: none; }

.cart {
  padding: 25px 0 0 0; }

#msCart .table .header {
  background-color: #f3f4f6;
  border: 1px solid #e3eceb;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around; }
  #msCart .table .header .title, #msCart .table .header .count, #msCart .table .header .total, #msCart .table .header .price, #msCart .table .header .remove {
    display: inline-block;
    font-size: 14px;
    text-transform: unset;
    font-weight: 600;
    margin: 0;
    padding: 23px; }
  #msCart .table .header .title {
    border-top: none;
    line-height: inherit;
    width: 30%; }

#msCart .cartItemCont {
  display: inline-block;
  width: 43%;
  vertical-align: top; }

#msCart .cartItemSome {
  display: inline-block;
  vertical-align: top; }

#msCart .cartItemImage {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px; }

#msCart .cartItemText {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px; }
  #msCart .cartItemText .small {
    margin-top: 8px;
    color: #b50000;
    font-weight: 600; }

#msCart .cartItemImage {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  overflow: hidden; }
  #msCart .cartItemImage a {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }

#msCart .cartItemText {
  width: 220px; }

#msCart .cartItemTitle {
  float: none;
  font-size: 20px; }
  #msCart .cartItemTitle > a {
    color: #b50000; }
    #msCart .cartItemTitle > a:hover {
      opacity: 0.8; }

#msCart .cartItemSubtitle {
  font-size: 14px;
  height: 60px;
  overflow: hidden; }

#msCart .cartItemPrice {
  display: inline-block;
  width: 174px;
  height: 100%; }

#msCart .cartPrice {
  font-size: 18px;
  font-weight: 600; }

#msCart .cartCurrency {
  font-size: 14px; }

#msCart .cartItemCount {
  display: inline-block;
  width: 230px; }

.ec-antispam, .ms2fastBuy {
  display: none; }

/*
.cartItemCount {
    .input-group {
        display: inline-block;
    }

    .number {
        border: 1px solid #e3eceb;
        border-radius: 4px;
        font-weight: 600;
        font-size: 18px;
        width: 100px;
    }

    .minus, .plus {
        display: inline-block;
        width: 30px;
        text-align: center;
        cursor: pointer;
        padding: 0 !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
*/
.count_input .input-group, .cart_count .input-group {
  display: inline-block;
  width: 100%; }

.count_input .number, .cart_count .number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3eceb;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  color: #2a3b45; }

.count_input .form-control, .cart_count .form-control {
  width: calc(100% - 60px);
  outline: 0;
  border: 0;
  box-shadow: none;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  color: #000;
  border-left: 1px solid #e3eceb;
  border-right: 1px solid #e3eceb; }

.count_input .minus, .count_input .plus, .cart_count .minus, .cart_count .plus {
  display: inline-block;
  width: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

#msCart .count_input {
  width: 100px; }

#msCart .input-group-addon, .input-group-btn, .input-group .form-control, #msCart .input-group {
  display: inline-block; }

.cartItemCount .form-control {
  width: 27px;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  padding-left: 0;
  padding-right: 0; }

#msCart .input-group .form-control:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

#msCart .cartItemTotal {
  display: inline-block;
  width: 195px; }

#msCart .cartItemRemove {
  display: inline-block; }

#msCart .cartRemoveButton {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 29px;
  line-height: 21px;
  font-weight: 600;
  color: #b50000; }

#msCart .cartItem {
  border: 1px solid #e3eceb;
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

#msCart .form-group {
  margin-bottom: 0; }

.orderSection {
  background-color: #f3f4f6; }

select::-ms-expand {
  display: none; }

#msOrder {
  margin-top: 0;
  padding: 40px; }
  #msOrder .form-control {
    color: #97a5ae;
    height: 43px;
    box-shadow: none;
    border-color: #e0e0e0;
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none; }
    #msOrder .form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #e0e0e0; }
  #msOrder .select {
    position: relative;
    background-color: #ffffff; }
    #msOrder .select:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      display: block;
      border: 5px solid transparent;
      border-top: 5px solid #9c9c9c; }
  #msOrder select {
    outline: none;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    box-shadow: none;
    border: none;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none; }
    #msOrder select option {
      padding: 10px;
      display: inline-block; }
  #msOrder .orderContacts {
    display: inline-block;
    width: 32%;
    vertical-align: top;
    margin-right: 2%; }
  #msOrder .orderDelivery {
    display: inline-block;
    width: 65%;
    vertical-align: top; }
  #msOrder .orderContacts .title, #msOrder .orderDelivery .title {
    font-size: 20px;
    color: #2a3b45;
    text-transform: unset;
    margin-bottom: 0; }
  #msOrder .overflowHidden {
    margin-bottom: 25px;
    position: relative; }
  #msOrder .title:before, #msOrder .title:after {
    right: 0;
    left: 120%;
    background: #e3eceb;
    width: 583px; }
  #msOrder .title:before {
    top: 22px; }
  #msOrder .title:after {
    bottom: 18px; }
  #msOrder .orderComment textarea {
    height: 158px;
    resize: none;
    padding: 20px; }
  #msOrder .orderConfirm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  #msOrder .orderConfirmContent {
    margin-right: 20px;
    text-align: right; }
  #msOrder .orderConfirmCost {
    color: #b50000;
    font-weight: 600; }
  #msOrder .orderConfirmBig {
    font-size: 18px; }
  #msOrder .orderConfirm button {
    margin-right: 5px;
    background-color: #b50000;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #b50000;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s; }
    #msOrder .orderConfirm button:active {
      -webkit-box-shadow: none;
      box-shadow: none; }
    #msOrder .orderConfirm button:hover {
      color: #b50000;
      background-color: #fff; }
  #msOrder .select select {
    width: 100%; }

.cartPriceTitle {
  display: none; }

#msCart .cartItemPriceWrapper {
  display: flex;
  align-items: center; }

#msOrder .orderContacts .title, #msOrder .orderDelivery .title {
  margin-bottom: 10px; }

.orderConfirmWrapper {
  display: flex;
  margin-bottom: 12px; }
  .orderConfirmWrapper > div {
    width: 32%; }
    .orderConfirmWrapper > div:not(:last-child) {
      margin-right: 2%; }

/********************* DELIVERY *****************************/
.delivWrapper, .warrantyWrapper, .orderingWrapper, .samplingWrapper {
  min-height: 500px; }

/********************* WARRANTY *****************************/
/********************* ORDERING *****************************/
/********************* SAMPLING *****************************/
/************************ PERSONAL *****************************/
.personalWrapper {
  min-height: 500px;
  padding-bottom: 50px; }

.profileWrapper {
  background-color: #f3f4f6;
  padding: 30px 40px;
  border-radius: 4px;
  margin-bottom: 40px; }
  .profileWrapper .title {
    font-size: 20px;
    text-transform: none; }
    .profileWrapper .title a {
      font-size: 14px;
      text-transform: none;
      margin-left: 16px;
      color: #b50000;
      text-decoration: underline; }
  .profileWrapper .profileCol .profilePasswdChange {
    text-decoration: underline; }
  .profileWrapper a i {
    margin-right: 8px; }
  .profileWrapper .title:before, .profileWrapper .title:after {
    right: -924px; }
  .profileWrapper .profileCol {
    display: inline-block;
    width: 33%; }
    .profileWrapper .profileCol p {
      color: #97a5ae; }
      .profileWrapper .profileCol p a {
        margin-left: 5px; }
    .profileWrapper .profileCol .profileCont {
      color: #b50000;
      font-weight: 600;
      margin-left: 5px; }
    .profileWrapper .profileCol .profilePasswdChange {
      color: #b50000; }

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0; }

.profileContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .profileContent .profileCol {
    width: auto; }
    .profileContent .profileCol:not(:last-child) {
      margin-right: 3%; }

/******************* AUTHORIZATION *****************************/
.authWrapper, .regWrapper, .passRecoveryWrapper, .changePassWrapper, .editUserWrapper {
  min-height: 500px;
  padding-bottom: 50px; }

/******************* REGISTRATION *****************************/
/******************** RECOVERY **********************************/
/****************** CHANGE PASSWORD ****************************/
/*********************** USER EDIT ***************************/
.form-horizontal .form-group.form-half {
  display: inline-block;
  vertical-align: top;
  width: 31%; }
  .form-horizontal .form-group.form-half:not(:nth-child(3n+3)) {
    margin-right: 3%; }

.edit-user_button {
  cursor: pointer;
  padding: 0 9px;
  height: 40px;
  line-height: 40px;
  width: auto;
  text-align: center;
  background-color: #00bd9b;
  color: #fff;
  text-transform: uppercase;
  display: block;
  border: 1px solid #00bd9b;
  outline: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s; }
  .edit-user_button:hover {
    background-color: #fff;
    color: #00bd9b; }

.updprof-error, .text-error {
  color: red; }

.input_block .form-half {
  display: inline-block;
  width: calc(50% - 12px);
  margin-right: 20px; }
  .input_block .form-half:nth-child(2n) {
    margin-right: 0; }

.customPopupWrapper .profile_order_products {
  margin-bottom: 20px; }

.customPopupWrapper .prof_order_item.second {
  padding-left: 0; }

.customPopupWrapper .profile_order_product {
  padding: 20px; }

/******************************  FOOTER *************************/
footer {
  padding-top: 40px;
  padding-bottom: 62px;
  margin-top: auto; }

.footerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.footerLinks {
  max-width: 500px;
  width: 100%;
  margin: 0 auto; }

.footerLink {
  box-sizing: content-box;
  padding-right: 40px;
  width: 134px; }

.footerCopiright {
  display: inline-block;
  color: #909090; }

.footerCopirightMain {
  font-size: 31px;
  font-weight: 600;
  line-height: 26px;
  color: #00bd9b; }

.footerCopirightText {
  padding-top: 93px;
  line-height: 23px;
  font-size: 12px;
  margin-bottom: 20px; }

.footerTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 18px; }

.footerLink li {
  display: block; }
  .footerLink li:not(:last-child) {
    margin-bottom: 10px; }
  .footerLink li a {
    color: #909090;
    text-decoration: none; }
    .footerLink li a:hover {
      color: #ffffff; }

.footerContacts {
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.footerContactsTitle {
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 28px; }

.footerPhone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 57%;
  margin-bottom: 20px; }
  .footerPhone .phoneText {
    text-align: left; }

.footer_item {
  width: 50%;
  margin-bottom: 20px;
  letter-spacing: -0.31em;
  color: #ffffff; }
  .footer_item .phoneText .phone {
    color: #cecece;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    margin-right: auto;
    text-align: left; }
  .footer_item a {
    color: #ffffff; }
  .footer_item .getOrder {
    color: #f5c10c; }
  .footer_item .right_block {
    width: calc(100% - 25px);
    display: inline-block;
    vertical-align: top;
    letter-spacing: normal;
    padding-left: 10px;
    text-align: left;
    font-size: 12px; }
  .footer_item .mailText {
    text-decoration: underline; }
  .footer_item .scheduleText {
    font-size: 14px; }
    @media screen and (max-width: 680px) {
      .footer_item .scheduleText {
        font-size: 12px; } }

.footerIconWrapper {
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  border: 1px solid #e7eae9;
  border-radius: 6px;
  text-align: center;
  transform: translateY(3px); }
  .footerIconWrapper svg {
    fill: #f5c10c; }

.footerLocation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 23px; }

.footerMail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 23px;
  width: 57%; }
  .footerMail .mailText {
    color: #fff;
    text-decoration: none; }

.footerSchedule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 23px; }

.footerSocial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 23px;
  width: 100%;
  margin-top: 15px; }
  .footerSocial .socialIcons .socialIcon {
    width: 20px;
    height: 20px;
    fill: #fff; }

.mailIcon {
  width: 52%;
  height: 100%;
  fill: #5f5f5f; }

.bm img {
  margin-right: 14px; }
  @media screen and (max-width: 1350px) {
    .bm img {
      margin-bottom: 14px;
      width: 70px; } }

/************************** VERTICAL CAROUSEL ************************/
.testWrapper {
  min-height: 600px;
  padding: 50px 0; }

.hidden {
  display: none; }

.visible {
  display: block; }

.thumbnail-active {
  filter: alpha(opacity=100);
  opacity: 1.0;
  cursor: pointer; }

.thumbnail-inactive {
  filter: alpha(opacity=90);
  opacity: 0.9;
  cursor: pointer; }

.thumbnail-text {
  color: #E0E0E0;
  font-weight: bold;
  text-align: left;
  display: block;
  padding: 10px 2px 2px 0px; }
  .thumbnail-text a {
    color: #E0E0E0;
    text-decoration: none; }
    .thumbnail-text a:hover {
      color: #fff; }

/*Вериткальная карусель CSS*/
.jscarousal-vertical {
  /* ширина и высота коробки для слайдов */
  width: 172px;
  height: 350px;
  background-color: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: inline-block; }
  .jscarousal-vertical:after {
    content: "";
    display: block;
    width: 100%;
    height: 37px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0; }

.jscarousal-contents-vertical {
  overflow: hidden;
  width: 140px;
  height: 330px; }
  .jscarousal-contents-vertical > div > div {
    width: 150px;
    height: 90px;
    margin-left: 14px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    overflow: hidden; }
    .jscarousal-contents-vertical > div > div.active {
      border: 1px solid #b50000;
      padding: 10px; }
  .jscarousal-contents-vertical img {
    width: 100%;
    height: 100%; }

.jscarousal-vertical-back, .jscarousal-vertical-forward {
  width: 25px;
  height: 25px;
  background-color: white;
  color: #b50000;
  position: relative;
  cursor: pointer;
  z-index: 10;
  border: 1px solid #b50000;
  border-radius: 3px; }

.jscarousal-vertical-back:hover, .jscarousal-vertical-forward:hover {
  border: 1px solid #b50000; }

.jscarousal-vertical-back:before, .jscarousal-vertical-forward:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.jscarousal-vertical-back {
  position: absolute;
  bottom: 0;
  left: 14px;
  background-image: url(/assets/templates/img/arrowUp.png);
  background-repeat: no-repeat;
  background-position: bottom; }
  .jscarousal-vertical-back.inactive {
    display: none; }

.jscarousal-vertical-forward {
  position: absolute;
  bottom: 0;
  left: 50px;
  background-image: url(/assets/templates/img/arrowDown.png);
  background-repeat: no-repeat;
  background-position: bottom; }
  .jscarousal-vertical-forward.inactive {
    display: none; }

.jscarousal-contents-vertical > div {
  position: absolute;
  top: auto;
  width: 100%;
  height: 310px;
  overflow: hidden; }
  .jscarousal-contents-vertical > div > div span {
    display: block;
    width: 70%;
    text-align: center; }

/*Вериткальная карусель CSS*/
/*************************** customPopup ********************************/
.customPopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(22, 34, 41, 0.8);
  z-index: 10000;
  display: none; }
  .customPopup.active {
    display: block; }

.customPopupBtn {
  background-color: rgba(255, 255, 255, 0.8);
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  text-align: center;
  line-height: 50px;
  font-size: 50px;
  color: #b50000;
  cursor: pointer;
  z-index: 20;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 45%; }
  .customPopupBtn.btn-right {
    right: -2%; }
    .customPopupBtn.btn-right .customPopupSvg {
      -webkit-transform: translate(2px, 4px);
      -ms-transform: translate(2px, 4px);
      transform: translate(2px, 4px); }
  .customPopupBtn.btn-left {
    left: -2%; }
    .customPopupBtn.btn-left .customPopupSvg {
      -webkit-transform: translate(-2px, 4px);
      -ms-transform: translate(-2px, 4px);
      transform: translate(-2px, 4px); }

.customPopupSvg {
  width: 90%;
  height: 90%;
  fill: #b50000; }

.customPopupContentWrapper {
  width: 1240px;
  height: 520px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.customPopupContent {
  width: 1240px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
  border-radius: 4px;
  overflow: hidden; }
  .customPopupContent.move-left {
    -webkit-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    -webkit-transform: translate(-20%, 0);
    -ms-transform: translate(-20%, 0);
    transform: translate(-20%, 0);
    opacity: 0; }
  .customPopupContent.move-right {
    -webkit-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    -webkit-transform: translate(20%, 0);
    -ms-transform: translate(20%, 0);
    transform: translate(20%, 0);
    opacity: 0; }

.customPopupImage {
  width: 810px;
  height: 100%;
  display: inline-block;
  vertical-align: top; }

.customPopupTextWrapper {
  width: 420px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  color: #b50000; }

.customPopupText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 40px 30px; }

.customPopupTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px; }

.customPopupDescr {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 16px; }

.customPopupTitle > a {
  color: #b50000; }
  .customPopupTitle > a:hover {
    opacity: 0.8; }

.customPopupPanel {
  margin-top: auto; }

.popuptitle {
  display: block; }

.popupPreorder .popuptitle {
  margin-bottom: 10px; }

.popupPreorder textarea {
  height: 150px; }

.popupPreorder .getOrderBg {
  margin-top: 15px; }

.popupPreorderTitle {
  line-height: 50px; }

.popupPreorderSubtitle {
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 15px; }

.orderProjectButton {
  padding: 10px 15px;
  text-transform: uppercase;
  width: 160px;
  background-color: #b50000;
  border: 1px solid #b50000;
  outline: none;
  color: #fff;
  border-radius: 4px;
  margin-right: 20px;
  display: inline-block; }
  .orderProjectButton:hover {
    background-color: #fff;
    color: #b50000;
    border: 1px solid #b50000; }

.askQuestionButton {
  padding: 10px 15px;
  text-transform: uppercase;
  width: 150px;
  background-color: #fff;
  border: none;
  outline: none;
  color: #b50000;
  border-radius: 4px;
  border: 1px solid #b50000;
  cursor: pointer;
  display: inline-block; }
  .askQuestionButton:hover {
    background-color: #b50000;
    color: #fff; }

.customPopupImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.authReg {
  display: none;
  cursor: default; }

.authRegWrapper {
  display: block;
  width: 400px;
  margin: 0 auto;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 0;
  border-radius: 4px;
  overflow: hidden;
  color: #b50000; }
  .authRegWrapper li {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 14px; }
  .authRegWrapper .form-control {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #b50000;
    padding: 20px;
    width: 100%;
    border-radius: 4px; }
    .authRegWrapper .form-control::-webkit-input-placeholder {
      color: #b50000; }
    .authRegWrapper .form-control:focus {
      border-color: #b50000; }
  .authRegWrapper .g-recaptcha > div {
    margin: 0 auto;
    margin-top: 30px; }

.authRegTab {
  display: block;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background-color: #f3f4f6;
  cursor: pointer;
  font-weight: 600; }
  .authRegTab.active {
    background-color: #fff;
    color: #b50000; }
    .authRegTab.active + .authRegCont {
      display: block; }

.authRegCont {
  display: none;
  padding: 20px;
  width: 200%; }
  .authRegCont .container {
    width: 100%; }

.authRegWrapper li:nth-child(2) .authRegCont {
  margin-left: -100%; }

.authRegCont label {
  font-weight: normal;
  color: #97a5ae;
  text-align: left;
  width: 100%; }

.authRegCont input.error {
  border: 1px solid red; }

.authRegCont .authRegCont .userOptions a {
  color: #b50000; }

.authRegCont .loginMessage {
  padding-bottom: 3px;
  color: red; }

.authRegCont .errormsg {
  color: red;
  padding-left: 21px; }

.authRegCont .regEmail {
  color: #b50000; }

.nav-mobile_btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 26px;
  margin-left: 6px; }

.nav-mobile_btn_item {
  width: 100%;
  height: 4px;
  background-color: #b50000;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all 0.5s; }
  .nav-mobile_btn_item:after, .nav-mobile_btn_item:before {
    width: 100%;
    height: 4px;
    background-color: #b50000;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all 0.5s; }
  .nav-mobile_btn_item:after {
    content: '';
    position: absolute;
    left: 0; }
  .nav-mobile_btn_item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0; }
  .nav-mobile_btn_item:after {
    bottom: 0; }

.nav-mobile_btn.active .nav-mobile_btn_item {
  background-color: transparent; }
  .nav-mobile_btn.active .nav-mobile_btn_item:before {
    top: 11px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .nav-mobile_btn.active .nav-mobile_btn_item:after {
    bottom: 11px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.authRegBtn {
  padding: 15px 0;
  margin-top: 20px; }

.headerTopWrapper input.authRegBtn {
  width: 100%;
  padding-left: 10px; }

.authRegBtn {
  background-color: #b50000;
  color: #fff;
  text-transform: uppercase;
  display: block;
  width: 100%; }
  .authRegBtn:hover {
    background-color: #b50000;
    color: #fff;
    text-transform: uppercase;
    display: block;
    width: 100%; }
  .authRegBtn:active, .authRegBtn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: #fff; }

.authRegHybridAuthWrapper {
  margin-top: 30px;
  color: #b50000;
  text-align: center;
  border-top: 1px solid #e3eceb; }
  .authRegHybridAuthWrapper .hybridauth .ha-info {
    margin-left: 0; }

.hybridauth .ha-info .ha-logout {
  display: block;
  border-radius: 4px;
  background-color: #b50000; }

.arhawTitle {
  font-weight: 600;
  margin-top: 28px;
  margin-bottom: 25px; }

.arhawContent .ha-icon {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  background-size: cover; }
  .arhawContent .ha-icon:not(:last-child) {
    margin-right: 15px; }
  .arhawContent .ha-icon.vkontakte {
    background-image: url(/assets/images/hybridAuth/VK.png);
    background-position: 0 0; }
  .arhawContent .ha-icon.twitter {
    background-image: url(/assets/images/hybridAuth/twitter.png);
    background-position: 0 0; }
  .arhawContent .ha-icon.facebook {
    background-image: url(/assets/images/hybridAuth/facebook.png);
    background-position: 0 0; }
  .arhawContent .ha-icon.google {
    background-image: url(/assets/images/hybridAuth/googlePlus.png);
    background-position: 0 0; }

.customPopupWrapper {
  max-width: 800px;
  width: 95%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 30px;
  background-color: #fff;
  position: relative; }
  .customPopupWrapper .prof_order_item {
    width: auto; }
    .customPopupWrapper .prof_order_item.second {
      width: auto; }
  .customPopupWrapper .price {
    font-size: 22px;
    font-weight: 600; }

.popupCrossSale {
  width: 650px;
  padding-bottom: 30px; }
  .popupCrossSale .hitItemImage, .popupCrossSale .hitItemPrices {
    margin-bottom: 10px; }
  .popupCrossSale .cartAddedProduct img {
    width: 120px; }

.cartAddedImage {
  display: inline-block;
  vertical-align: top;
  width: 130px; }

.cartAddedContent {
  display: inline-block;
  vertical-align: top;
  width: calc(80% - 130px); }

.cartAddedProduct {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea; }

.cartAddedTitle {
  color: #404040;
  height: 80px;
  overflow: hidden;
  font-size: 18px; }

.cartAddedPrice {
  display: inline-block;
  vertical-align: top;
  width: calc(40% - 130px);
  font-weight: bold;
  color: #404040;
  font-size: 18px;
  text-align: right; }

.cartAddedAmount span {
  color: #404040; }

.cartAddedButtons {
  padding: 20px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea; }
  .cartAddedButtons .button:first-child {
    margin-right: 20px; }

.crossSaleCarousel .hitItem {
  width: 31%;
  margin: 0;
  padding: 10px; }
  .crossSaleCarousel .hitItem button {
    margin: 0;
    width: 100%; }
  .crossSaleCarousel .hitItem:not(:last-child) {
    margin-right: 3%; }

.crossSaleCarousel .hitItemImage {
  min-height: unset; }

.crossSaleCarousel .hitItemTitle {
  line-height: 1.1;
  height: 80px; }

.crossSaleCarousel .hitItemContent {
  padding: 0; }

.crossSaleCarousel .hitItemToCart {
  margin: 0;
  width: 100%;
  min-width: auto;
  padding: 10px 0;
  line-height: 1; }

.crossSaleCarousel .catalogItemPrice {
  font-size: 16px;
  margin: 0; }

.catalogInsedeItem .overflowHidden .title {
  max-width: 100%; }
  .catalogInsedeItem .overflowHidden .title .subtitle {
    display: block;
    line-height: 20px;
    text-transform: none; }

.crossSaleCarouselTitle {
  color: #404040;
  padding: 20px 0; }

.total_cost {
  text-align: right;
  margin-bottom: 20px; }
  .total_cost span {
    font-size: 20px; }

.customPopupWrapper .popuptitle {
  line-height: 50px;
  margin-bottom: 40px; }

.oneClickOrder {
  font-size: 20px;
  margin-bottom: 20px;
  color: #b50000;
  font-weight: 600;
  text-align: center; }

.oneClickOrderImage {
  display: inline-block;
  width: 40%; }

.oneClickOrderInfo {
  display: inline-block;
  width: 50%; }

/************************** popup *****************************/
.popupReview {
  height: 650px;
  padding: 20px; }
  .popupReview .inputholder {
    position: relative; }
  .popupReview form {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0; }
    .popupReview form .form-control {
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid #cedad9; }
  .popupReview button[type="submit"] {
    border-radius: 4px; }
  .popupReview .inputholder:nth-child(2):before {
    width: 26px;
    left: 10px;
    top: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAATCAYAAACORR0GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAYNJREFUeNpi/P//vx4DA8NhIH4HxL8ZMAEjEP8nk2YCYgkg9mMAWgTCi/7TDuwAYkaQJVOhlqXRwJI6qNl1DFCBI1ABTypaEgU1c/1/KOMzVOIslK9BBUscoGbthPK/gTgfkRQ8AWJmIOZHEycW/AFiNaglV5HEPzJBUwcMSAPxFyDmBmJ+IL7JQDx4BcS8QPwAiJ8DsRZyymXCooEDiJ8CsSkQawDxcSIsATlIHIhlgfgjNEmjAqC3PuEJilJoMKzCo2YPVE0MHjWfmAi41AFKhwFxBxb5+UDsAmXb4jUJj49mQ126AYjXQtmZSPINULE5QHwAyu7A5SNcFq2EamxAEtsCFctBsmQekvxMqFg/sRbtgGqowKIBlteQ8wgy6IXKLSBk0WEsQYQObgDxOTzyzcglAiwfIWdYmGvDqFAyFELN2gblf4aVdc+hEj5ULOvioWZegZV196C0HQ1Kb1jBehxE8ACxHw3rI1D1w8AIJNiA2ek+tOj5ykBdwA7FGgABBgCaj4oz5MbKMQAAAABJRU5ErkJggg==); }
  .popupReview .inputholder:nth-child(3):before {
    left: 14px;
    top: 11px;
    width: 22px;
    height: 22px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPBJREFUeNpi+P//PwMVcDsQvwLiSTAxSg1kAeIZ/1HBXGoY3PofO5hKqcG6QHwTm8nkGrgOiCdA2WxAfBrN3JXkGDodyYBsJHGYy/eQE8a9WHydDpWTAYUtEDOSavCc/7hBH7p6Yg2d9B8/uArEzKQaPJGAoduAmIlUF88hYOhGXHrxGdpHwNAD+ByFS2IaAUM3EQpCbIKzCRi6hZgIRxfoJmDoPmKTJzJnKgFD15OSmWAMeQKG7iQ168MYRXgM3U1OQQVjnKaG99ENlsCToxgoMbgDybDb0IzhSGk9CCri1jAwMLwA4mVAfIyBSgAgwACad/beKYkfEAAAAABJRU5ErkJggg==); }
  .popupReview input[type="file"] {
    display: none; }
    .popupReview input[type="file"] + label {
      margin-top: 22px;
      padding: 10px 20px;
      border: 1px solid #cedad9;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 53px;
      font-weight: 300;
      color: #fff; }
  .popupReview .inputholder:nth-child(4):before {
    background-image: url(/assets/templates/img/upload2white.png);
    width: 26px;
    height: 26px;
    background-size: contain;
    padding-left: 53px;
    top: unset;
    bottom: 15px; }
  .popupReview .alert {
    margin-top: 45%;
    font-size: 18px;
    color: inherit;
    background-color: transparent;
    border: none;
    font-weight: 300; }
  .popupReview .popuptitle {
    margin-bottom: 30px; }
  .popupReview.productReview input[name="rating"] {
    position: absolute;
    height: 18px;
    opacity: 0.0000000001;
    margin: 0;
    z-index: -1; }
  .popupReview.productReview input[type="submit"] {
    margin: 12px auto; }
  .popupReview .ec-error {
    position: absolute;
    bottom: 0;
    right: 10px;
    color: #b50000; }
    .popupReview .ec-error.reviewRating {
      bottom: unset;
      top: 0px;
      margin: 0; }
  .popupReview label.control-label {
    display: block;
    width: 157px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #cedad9;
    margin-top: 20px;
    cursor: pointer;
    padding-left: 20px;
    font-weight: 400;
    font-style: italic;
    color: #999999; }

/**************** SEARCH ******************/
.search {
  margin-top: 25px; }

.searchWrapper {
  min-height: 600px; }
  .searchWrapper .searchItem {
    padding: 16px;
    padding-left: 50px;
    padding-right: 30px;
    border: 1px solid #e3eceb;
    margin-bottom: 30px;
    border-radius: 7px; }

.searchItemImage {
  display: inline-block;
  position: relative;
  width: 15%;
  height: 130px;
  margin-right: 5%;
  vertical-align: top;
  border-radius: 5px;
  overflow: hidden; }

.searchItemContent {
  display: inline-block;
  width: 65%;
  vertical-align: top; }

.searchItemType {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background-color: #b50000;
  padding: 5px 10px;
  color: white;
  border-radius: 5px; }

/*************** FORM **********************/
input {
  outline: none; }
  input:-internal-autofill-selected {
    background-color: transparent !important; }

.popupCall input.wrong, .popupCall textarea.wrong {
  border: 1px solid red; }

.popup {
  top: 100px;
  padding: 20px; }
  .popup textarea, .popup button {
    outline: none; }

.popupReview input.wrong, .popupReview textarea.wrong {
  border: 1px solid red; }

.nospam-hidden {
  display: none; }

.profileSvg {
  width: 17px;
  height: 17px;
  fill: #00bd9b;
  transform: translateY(1px); }

.checkbox_block {
  float: left;
  width: 100%;
  position: relative; }

input.conf_checkbox {
  position: absolute;
  top: 15px;
  left: 2px;
  margin: 0;
  opacity: 0;
  width: 20px;
  height: 20px; }

label.checkbox_text {
  position: relative;
  line-height: 30px;
  padding: 10px 0;
  vertical-align: middle;
  z-index: 5;
  font-size: 12px;
  font-style: italic;
  font-weight: normal; }
  label.checkbox_text a {
    text-decoration: underline; }

.topCarouselForm label.checkbox_text {
  padding: 0;
  line-height: 1;
  text-align: left;
  margin-bottom: 12px; }

.topCarouselForm .button {
  width: 100%;
  margin-bottom: 10px; }

.topCarouselForm label.checkbox_text:before {
  border-color: #00bd9b;
  color: #00bd9b;
  margin-right: 8px; }

label.checkbox_text:before {
  content: " ";
  color: #fff;
  display: inline-block;
  font: 12px/11px Arial;
  margin-right: 20px;
  position: relative;
  text-align: center;
  text-indent: 0px;
  width: 12px;
  height: 12px;
  background: inherit;
  border: 1px solid #ffffff;
  border-image: initial;
  vertical-align: middle;
  margin-bottom: 2px; }

.popup_feedback_product label.checkbox_text:before, .popup_feedback label.checkbox_text:before {
  border: 1px solid #565656;
  color: #565656; }

input.conf_checkbox:checked + label.checkbox_text:before {
  content: "\2714"; }

/********************** test **********************/
.masGrid {
  height: 380px; }

.masElem {
  overflow: hidden;
  background-color: red;
  margin-bottom: 20px; }
  .masElem.mas-1-of-4 {
    width: 24%; }
  .masElem img {
    width: 100%; }

.slide_twoImagesBlock img {
  display: inline-block;
  vertical-align: top;
  width: 48%; }

.sp-slide .slide_images {
  width: 50%; }

.sp-slide .slide_info {
  width: 40%; }

.sp-slide .slide_images, .sp-slide .slide_info {
  display: inline-block;
  vertical-align: top; }

/* .masElem:nth-child(1) {
    width: 610px;
    height: 178px;
}
.masElem:nth-child(3) {
    width: 295px;
    height: 178px;
}
.masElem:nth-child(4) {
    width: 295px;
    height: 178px;
}
.masElem:nth-child(2) {
    width: 295px;
    height: 376px;
}
.masElem:nth-child(5) {
    width: 295px;
    height: 178px;
}
.masElem:nth-child(6) {
    width: 295px;
    height: 178px;
}
.masElem:nth-child(7) {
    width: 250px;
}
.masElem:nth-child(8) {
    width: 250px;
}
.masElem:nth-child(9) {
    width: 250px;
}
.masElem:nth-child(10) {
    width: 250px;
}
.masElem:nth-child(11) {
    width: 250px;
}
.masElem:nth-child(12) {
    width: 250px;
} */
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit; }

.between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.al_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.profile_order_products {
  border: 1px solid #e7e8ea;
  border-radius: 5px;
  margin-bottom: 80px; }

.prof_order_header {
  background-color: #f3f4f6;
  padding: 20px 40px; }

.profile_order_product {
  padding: 20px 40px; }

.prof_order_item {
  width: 15%;
  color: black; }
  .prof_order_item.first {
    width: 5%; }
  .prof_order_item.second {
    width: 30%; }

.profile_order_product_img {
  display: inline-block;
  width: 80px;
  border-radius: 5px; }

.prof_order_item .right_block {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }
  .prof_order_item .right_block a {
    display: block; }

.profile_order_product_name {
  color: black;
  margin-bottom: 10px; }

.profile_order_product_parent {
  color: #9ca5ac; }

.prof_order_item.count span {
  display: inline-block;
  border: 1px solid #e3eceb;
  border-radius: 4px;
  line-height: 40px;
  height: 40px;
  padding: 0 20px; }

.lazyIMG {
  opacity: 0;
  transition: opacity 0.4s; }

.ready {
  opacity: 1; }

#mmenu {
  flex: auto; }

.mmenuButton, .mmenuButton_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center; }

.mmenuButton_wrapper {
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 300;
  margin-right: 30px; }
  .mmenuButton_wrapper.active .mmenuButtonElement, .mmenuButton_wrapper.active .mmenuButtonElement:after, .mmenuButton_wrapper.active .mmenuButtonElement:before {
    background-color: #f5c10c; }
  @media screen and (max-width: 1024px) {
    .mmenuButton_wrapper {
      margin-right: 10px; } }

.mmenuButton {
  height: 34px;
  width: 34px;
  flex-direction: column;
  justify-content: space-around; }

.mmenuButton.active {
  justify-content: space-between; }

.mmenuButtonElement, .mmenuButtonElement:after, .mmenuButtonElement:before {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  position: relative;
  transition: all ease .3s; }

.mmenuButtonElement:after, .mmenuButtonElement:before {
  content: "";
  width: 4px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  top: 0; }

.mmenuButtonElement:before {
  left: 0; }

.mmenuButtonElement:after {
  right: 0; }

.mm-wrapper_opening header .mmenuButton .mmenuButtonElement, .mmenuButton_wrapper.active .mmenuButton .mmenuButtonElement {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.mm-wrapper_opening header .mmenuButton .mmenuButtonElement:before, .mmenuButton_wrapper.active .mmenuButton .mmenuButtonElement:before {
  width: 8px;
  height: 8px;
  left: -12px;
  opacity: 1; }

.mm-wrapper_opening header .mmenuButton .mmenuButtonElement:after, .mmenuButton_wrapper.active .mmenuButton .mmenuButtonElement:after {
  width: 8px;
  height: 8px;
  right: -12px;
  opacity: 1; }

.mm-wrapper_opening header .mmenuButton + .mmenuButtonTitle, .mmenuButton_wrapper.active .mmenuButton + .mmenuButtonTitle {
  color: #f5c10c; }

.mmenuButtonTitle {
  margin-left: 12px;
  color: #fff; }

.nav_hidden:not(.mm-menu) {
  display: none; }

.mm-menu_offcanvas {
  position: absolute;
  left: -9999px; }

.mm-wrapper_blocking {
  overflow: unset; }

.mm-listview li:not(.active) a:not(.mm-next):hover {
  background: 0 0 !important; }

.mm-listview > li.active > a:not(.mm-next):hover, .mm-menu .mm-listview > li.active > a:not(.mm-next), .mm-menu .mm-listview > li.active > span, .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: #3b3b3b !important;
  color: #fff !important; }

.mm-menu .mm-listview > li.active > .mm-btn_next:after {
  border-color: #fff; }

.mm-menu .mm-listview > li a:not(.mm-next), .mm-menu .mm-listview > li.active > a:not(.mm-next) {
  height: auto; }

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: 0; }

.mm-menu a.mm-navbar__title {
  color: #b50000;
  font-weight: 600; }

.mm-menu_tip-top:before {
  content: none; }

.mm-wrapper__blocker {
  cursor: pointer; }

.headerBottomWrapper_nav {
  flex: auto; }

.nav_new {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 12;
  width: 280px !important;
  height: auto !important;
  display: none;
  background-color: #fff;
  box-shadow: 0px 3px 6.09px 0.91px rgba(0, 0, 0, 0.03);
  border-radius: 0 0 4px 4px; }
  .nav_new > ul {
    width: 100%;
    padding: 18px 0; }
  .nav_new a:hover {
    color: #b50000; }
  .nav_new .level1 {
    width: 100%;
    padding: 0 18px; }
    .nav_new .level1.parent > a:after {
      content: '';
      position: absolute;
      right: 0;
      top: 16px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-left: 6px solid #c6c9cb; }
    .nav_new .level1 > a {
      position: relative;
      display: block;
      padding: 10px 0;
      border-bottom: 1px solid #e3eceb; }
    .nav_new .level1:last-child {
      padding-bottom: 0; }
      .nav_new .level1:last-child a {
        border-bottom: 0; }
    .nav_new .level1:hover > ul {
      left: 100%;
      opacity: 1; }
    .nav_new .level1 > ul {
      position: absolute;
      left: -9999px;
      top: 0;
      min-height: 100%;
      padding: 18px 22px;
      background-color: #fff;
      -webkit-border-radius: 0 0 4px 4px;
      border-radius: 0 0 4px 4px;
      opacity: 0;
      -webkit-columns: 3;
      -moz-columns: 3;
      columns: 3;
      -webkit-column-gap: 60px;
      -moz-column-gap: 60px;
      column-gap: 60px;
      -webkit-transition: opacity .5s;
      -o-transition: opacity .5s;
      transition: opacity 0.5s; }
  .nav_new .level2 {
    width: 190px;
    padding: 10px 0;
    border-bottom: 1px solid #e3eceb; }
    .nav_new .level2 > ul {
      padding-left: 18px;
      padding-top: 5px; }
      .nav_new .level2 > ul a {
        color: #5d656a; }
        .nav_new .level2 > ul a:hover {
          color: #b50000; }
  .nav_new .level3 {
    display: block;
    margin-bottom: 5px; }
    .nav_new .level3:last-child {
      margin-bottom: 0; }
  .nav_new .readmore.active ~ li, .nav_new ul {
    display: block; }
  .nav_new a {
    transition: 0.5s; }
  .nav_new .readmore {
    display: inline-block;
    color: #8f9ca1;
    border-bottom: 1px dashed;
    cursor: pointer; }
    .nav_new .readmore.active {
      display: none; }

.discountsWrapper {
  display: flex;
  flex-wrap: wrap; }

.catalogSection .catalog_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: inherit;
  -webkit-align-items: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
  padding-left: 15px;
  padding-right: 0; }
  @media screen and (max-width: 1120px) {
    .catalogSection .catalog_wrapper {
      width: 100%;
      padding-left: 0; } }
  .catalogSection .catalog_wrapper .catalogItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: -webkit-calc(100%/3 - 14px);
    width: calc(100%/3 - 14px);
    margin-right: 20px;
    height: auto; }
    .catalogSection .catalog_wrapper .catalogItem:nth-child(3n) {
      margin-right: 0; }
    .catalogSection .catalog_wrapper .catalogItem a {
      margin-top: auto; }
  .catalogSection .catalog_wrapper .catalogItemImage {
    margin-bottom: 10px;
    width: 100% !important; }
  .catalogSection .catalog_wrapper .catalogItemTitle {
    padding-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: normal; }

.catalogItem > a {
  letter-spacing: normal; }

.popup button {
  margin: 33px auto 25px; }

@media screen and (max-width: 900px) {
  .nav_new {
    width: 230px !important; } }

@media screen and (max-width: 1120px) {
  .nav_new .level1 > ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px; } }

.crossSaleCarousel {
  letter-spacing: -0.31em; }
  .crossSaleCarousel .hitItem {
    display: inline-block;
    letter-spacing: normal; }

.search_page .catalogItemSmall {
  width: calc(100%/4 - 15px); }
  @media screen and (max-width: 1024px) {
    .search_page .catalogItemSmall {
      width: calc(100%/3 - 13.5px); } }
  @media screen and (max-width: 610px) {
    .search_page .catalogItemSmall {
      width: calc(100%/2 - 10px); } }
  @media screen and (max-width: 440px) {
    .search_page .catalogItemSmall {
      width: 100%;
      margin-right: 0 !important; } }

.search_page .catalogItemSmall:nth-child(3n) {
  margin-right: 20px; }
  @media screen and (max-width: 1024px) {
    .search_page .catalogItemSmall:nth-child(3n) {
      margin-right: 0 !important; } }
  @media screen and (max-width: 610px) {
    .search_page .catalogItemSmall:nth-child(3n) {
      margin-right: 20px !important; } }
  @media screen and (max-width: 440px) {
    .search_page .catalogItemSmall:nth-child(3n) {
      margin-right: 0 !important; } }

.search_page .catalogItemSmall:nth-child(4n) {
  margin-right: 0; }
  @media screen and (max-width: 1024px) {
    .search_page .catalogItemSmall:nth-child(4n) {
      margin-right: 20px; } }

@media screen and (max-width: 610px) {
  .search_page .catalogItemSmall:nth-child(even) {
    margin-right: 0 !important; } }

.search_page .catalogItemDescription {
  height: 100px;
  overflow: hidden;
  margin-bottom: 18px; }

.reviewBlock {
  margin-bottom: 12px; }

/************************ responsive *********************************/
@media screen and (max-width: 1350px) {
  .container_12 {
    width: 90vw;
    margin: 0 auto; }
  /********** header **********/
  .headerTopWrapper .topMenu {
    margin-right: 0; }
  #main-nav li {
    margin-right: 18px; }
  .headerTopWrapper #main-nav {
    margin-right: auto; }
  .headerTopWrapper .formGroup {
    margin-right: 20px; }
  .headerTopWrapper input {
    width: 232px;
    padding: 7px;
    padding-left: 42px; }
  .headerTopWrapper button {
    width: 25px;
    height: 25px; }
  .headerTopWrapper .headerScaleSvg {
    margin-right: 20px; }
  .headerTopWrapper .headerStarSvg {
    margin-right: 20px; }
  .headerTopWrapper .headerSearchButton svg {
    width: 50%;
    height: 50%;
    fill: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .headerTopWrapper .headerLogin {
    font-size: 12px;
    max-width: 175px; }
  .headerBottomWrapper ul.topCatalogMenu li {
    margin-right: 6px; }
  .topCarouselForm {
    left: 69%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
  .topCarouselTitle .titleBig {
    font-size: 32px; }
  .topCarouselTitle .titleItem {
    margin-bottom: 14px; }
  /*********** main page ************/
  .advantagesTitle {
    line-height: 1; }
  .catalogItemPrice {
    margin-right: 10px; }
  .bannerWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .bannerFormGroup {
    width: 600px; }
  .bannerFormGroup .inputholder {
    width: 63%; }
  .bannerFormGroup .inputholder input {
    width: 100%;
    margin-bottom: 10px; }
  .bannerWrapper img {
    margin-right: 50px; }
  .discountItem {
    width: 43vw;
    margin-right: 3vw; }
  .discountItem .discountItemInfo {
    padding: 25px;
    padding-right: 20px;
    width: 53%; }
  .discountItem .discountItemInfo .discountTitle {
    line-height: 1.2;
    width: 100%;
    margin-bottom: 10px; }
  /************** about *****************/
  .aboutUs {
    margin-top: 25px; }
  .aboutUsInfoItem {
    width: 21vw; }
  .forCustomerButton {
    width: 21vw;
    line-height: 1;
    vertical-align: top; }
  .forCustomerButton .buttonText {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .forCustomerButton:not(:last-child) {
    margin-right: 18px; }
  .certificateItem {
    height: 19vw; }
  .questionItem {
    padding-top: 20px;
    padding-bottom: 15px; }
  .questionTitle {
    width: 26%;
    margin-right: 57px; }
  .questionTitle:after {
    right: -43px; }
  .questionCont {
    width: 62%; }
  .reviewTitle {
    width: 30%; }
  .reviewCont {
    width: 67%; }
  .productionSlogans.container_12 {
    margin-bottom: 30px; }
  .productionItem {
    width: 21vw;
    height: 100px; }
  .productionItemTitle {
    width: 90%;
    margin: 0 auto;
    height: 41px;
    margin-bottom: 10px; }
  .productionItemCont {
    width: 90%; }
  /************** news ****************/
  .newsItemWrapper.container_12 {
    margin: 0;
    margin-top: 20px; }
  /************ catalog ******************/
  .catalogWrapper {
    padding-bottom: 0; }
  .catalogWrapper .title {
    margin-bottom: 0; }
  .catalogSection .catalogItem {
    padding: 15px; }
  .catalogSection .catalogResultsWrapper #mse2_results {
    -ms-grid-columns: 1fr [3];
    grid-template-columns: repeat(3, 1fr); }
  .catalogSection .catalogItemSmall .catalogItemCont {
    width: 100%; }
  /********** product ********************/
  .productGalleryWrapper {
    width: 54vw; }
  .productBigImageWrapper {
    width: 70%; }
  .productOrderWrapper {
    width: 34vw;
    padding: 20px; }
  .sizeItem {
    width: 94px;
    font-size: 12px; }
  .sizeItem input {
    margin-top: 28%; }
  .orderWrapper {
    margin-top: 0; }
  .orderPrice {
    display: block; }
  .orderButton {
    height: 36px;
    line-height: 36px;
    width: 94px; }
  .oneClickButton {
    height: 42px;
    line-height: 42px; }
  .productContMenu {
    width: 27vw; }
  .productContMenuText {
    width: 62vw; }
  .productContMenuItem {
    margin-bottom: 13px; }
  .productContMenu .menuText {
    font-size: 14px; }
  /**********contactsFormOuter ************/
  .officePicCarouselWrapper {
    width: 86vw; }
  .officePicItem {
    width: 100%; }
  .contactsFormOuter.container_12 {
    margin-bottom: 50px; }
  .container_12.contactsTitle {
    margin-top: 25px; }
  /*************** footer ***************/
  .footerCopiright {
    width: 175px;
    margin-right: 30px; }
  .footerCopirightText {
    line-height: 1.2; }
  .footerCompanyMenuTitle,
  .footerCatalogMenuTitle,
  .footerClientsMenuTitle {
    margin-bottom: 23px; }
  .footerCompanyMenu li:not(:last-child),
  .footerCatalogMenu li:not(:last-child),
  .footerClientsMenu li:not(:last-child) {
    margin-bottom: 3px; }
  .footer_item {
    display: flex; }
  /************* cart *************/
  #msCart .cartItemCont {
    width: 38vw; }
  #msCart .table .header {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  #msCart .table .header .title {
    margin-right: auto; }
  #msCart .cartItem {
    padding-right: 8px; }
  #msCart .table .header .price,
  #msCart .table .header .count,
  #msCart .table .header .total,
  #msCart .table .header .remove {
    width: 13vw;
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
  #msCart .cartItemPrice,
  #msCart .cartItemCount,
  #msCart .cartItemTotal,
  #msCart .cartItemRemove {
    width: 13vw;
    text-align: center; }
  #msCart .cartItemImage, #msCart .cartItemText {
    margin-right: 15px; }
  #msCart .cartItemText {
    margin-right: 0; }
  .contactLocation .locationText,
  .contactPhone .phoneText,
  .contactMail .mailText a,
  .contactSchedule .scheduleText {
    font-size: 18px; }
  .contactSchedule .scheduleText {
    width: 177px; }
  .customPopupContentWrapper {
    width: 86%; }
  .customPopupContent {
    width: 100%; }
  .customPopupImage {
    width: 50%; }
  .customPopupTextWrapper {
    width: 49%; } }

@media screen and (max-width: 1120px) {
  .container_12 {
    width: 85vw; }
  /************ header *****************/
  #main-nav {
    display: block; }
  .headerTop {
    height: 100px;
    padding: 10px 0; }
  .headerTopWrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .headerTopWrapper #main-nav {
    display: block;
    float: none;
    width: 100%; }
  .headerTopWrapper .formGroup {
    margin-right: auto; }
  .headerTopWrapper .headerIcon {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: -10px; }
  .headerMiddleWrapper .logo {
    width: 160px;
    margin-left: 0;
    margin-right: 20px;
    background-size: contain;
    width: 100px; }
  .headerMiddleWrapper .headerSocial {
    display: none; }
  .headerIconWrapper {
    margin-right: 10px; }
  .phoneText .phone {
    font-weight: 600;
    font-size: 14px; }
  .headerBottom {
    height: 50px; }
  .headerIncartWrapper {
    display: none; }
  .headerBottomWrapper ul.topCatalogMenu > li {
    padding: 0;
    margin-right: 10px; }
  .topCatalogMenu li a {
    font-size: 12px; }
  ul.topCatalogMenu > li:after {
    display: none; }
  ul.topCatalogMenu li > ul {
    left: -13px; }
  .topDiscounts {
    font-size: 12px; }
  .topCarouselTitle .titleBig {
    font-size: 20px;
    margin-bottom: 15px; }
  .topCarouselForm {
    left: 60%; }
  /************* main catalog ******************/
  .catalogItem {
    padding-bottom: 15px; }
  .catalogWrapper .catalogItem:nth-child(4n + 1) {
    margin-left: 0; }
  .catalogItemImage {
    margin-top: 12px; }
  .catalogItemTitle {
    margin-bottom: 10px;
    padding: 0;
    font-size: 12px; }
  .discountCarousel .discountItem .discountItemInfo {
    padding: 25px; }
  .hitItem {
    padding: 10px; }
  .crossSaleCarousel .hitItemContent {
    height: auto; }
  .hitItemContent {
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    height: auto;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid gainsboro; }
  .productPreorder {
    margin-bottom: 5px; }
  .hitItemTitle {
    font-size: 13px; }
  .hitItemDescr {
    font-size: 13px;
    line-height: 1.2;
    height: 60px; }
  .hitItemPrices, .catalogItemPrices {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .hitItemPrice, .catalogItemPrice {
    font-size: 15px; }
  .hitItemOldPrice, .catalogItemOldPrice {
    top: 0; }
  .advantagesWrapper {
    display: block; }
  .advantagesItem {
    height: 200px;
    width: 100%; }
  .bannerWrapper img {
    margin-right: -40px; }
  .bannerFormGroup .inputholder {
    width: 90%; }
  .newsCarousel .newsItemInfo {
    padding: 0 10px; }
  .newsTitle {
    font-size: 13px; }
  .newsDesc {
    font-size: 13px;
    line-height: 1.2;
    height: 60px; }
  .newsDate .newsSvg {
    width: 13px;
    height: 13px; }
  .newsDate {
    font-size: 12px; }
  .footerWrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .footerCopiright {
    width: 213px;
    margin-right: auto;
    margin-left: 0; }
  .footerCatalog, .footerClients, .footerCompany {
    width: 24%; }
  .footerContacts {
    width: 100%; }
  .footerPhone, .footerLocation, .footerMail, .footerSchedule {
    width: 25%; }
  .footerIconWrapper {
    margin-bottom: 10px; }
  /*************** about us **********************/
  .aboutUsInfoItem {
    width: 20vw;
    padding: 0 15px; }
  .forCustomerButton {
    width: 20vw; }
  .forCustomerButton:not(:last-child) {
    margin-right: 8px; }
  .forCustomerButton .buttonText {
    font-size: 13px;
    padding-left: 65px; }
  .forCustomerCont {
    height: 250px;
    overflow: auto; }
  .questionItem {
    padding-left: 56px; }
  .questionSvgWrapper {
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px; }
  .questionTitle {
    font-size: 16px; }
  .reviewPhoto {
    display: block;
    margin: 0 auto; }
  .reviewInfo {
    display: block;
    text-align: center; }
  .reviewTitle:after {
    height: 155px;
    right: 11px; }
  .reviewTitle {
    width: 26%; }
  .productionItem {
    width: 40vw;
    margin-bottom: 40px; }
  .productionItemsWrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .productionItem:nth-child(even) {
    margin-right: 0; }
  .productionSlogans.container_12 {
    margin-bottom: 0; }
  .forCustomerCont {
    padding-right: 20px; }
  /************ contacts ****************/
  .officePicCarouselWrapper {
    width: 79vw; }
  .mapHolderWrap {
    width: 514px;
    height: 380px; }
  .contBlocks .contactsWrapper .contactsWrap {
    padding-right: 556px; }
  /************** product card **************/
  .productOrderWrapper {
    width: 27vw;
    font-size: 12px; }
  .productGalleryWrapper {
    width: 56vw; }
  .productBigImageWrapper {
    width: 67%; }
  .productOrderWrapper {
    padding: 15px; }
  .sizeItem {
    height: 30px;
    line-height: 30px; }
  .orderButton, .oneClickButton {
    padding: 0 10px;
    width: auto; }
  .productContMenuText {
    width: 56vw; }
  .sizeItem input {
    margin-top: 18%; }
  /*************** catalog ************/
  .filterSliderWrapper {
    margin: 0 9px;
    border-left: 1px solid #e5ebea; }
  .catalogFilterWrapper {
    width: 75vw;
    margin: 0 auto;
    margin-bottom: 30px; }
  .catalogFilterWrapper button[type="reset"] {
    margin: 0 10px 10px 0; }
  .catalogFilterWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .catalogResultsWrapper {
    width: 100%;
    padding: 0; }
  .catalogFilterWrapper .catalogSideMenu {
    width: 40%;
    display: inline-block;
    vertical-align: top; }
  .catalogFilterWrapper form {
    width: 55%;
    display: inline-block;
    vertical-align: top; }
  .filterSliderWrapper {
    padding: 0 24px;
    max-height: 54px; }
  .filterSliderWrapper .filter_title {
    margin: 18px 0; }
  .catalogSection {
    padding-top: 0; }
  /*********** discount **************/
  .discountItem {
    width: 40.8vw;
    margin-right: 3vw; }
  .discountItem .discountItemInfo {
    padding: 20px; }
  .discountItem .discountTitle {
    font-size: 18px;
    height: 43px; }
  #msCart .table .header .price,
  #msCart .table .header .count,
  #msCart .table .header .total,
  #msCart .table .header .remove {
    width: 11.2vw;
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
  #msCart .cartItem {
    padding-right: 0; }
  #msCart .cartItemCont {
    margin-right: auto; }
  #msCart .cartItemPrice,
  .cartItemCount,
  #msCart .cartItemTotal,
  #msCart .cartItemRemove {
    width: 11vw;
    text-align: center; }
  #msCart .cartItemTitle {
    font-size: 18px; }
  .orderConfirmWrapper > div:not(:last-child) {
    margin-right: 0; }
  #msOrder .orderMain, #msOrder .contData {
    width: 48%; }
  .orderConfirmWrapper {
    flex-wrap: wrap;
    justify-content: space-between; }
  #msOrder .orderComment {
    width: 100%; }
  #msOrder .orderContacts {
    margin-right: 3.5%; }
  #msOrder .orderContacts, #msOrder .orderDelivery {
    width: calc(50% - 14px); }
  .footerLinks {
    margin: 0;
    max-width: 400px; } }

@media screen and (max-width: 1050px) {
  .mm-menu_offcanvas {
    left: auto; }
  .aboutUsInfoWrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .aboutUsInfoItem {
    width: 40vw;
    margin-bottom: 20px;
    margin-right: 0; }
  #msCart .cartItemText {
    width: 200px; }
  .form-horizontal .form-group.form-half {
    display: inline-block;
    width: 48%; }
  .form-horizontal .form-group.form-half:not(:nth-child(3n+3)) {
    margin-right: 0; }
  .form-horizontal .form-group.form-half:nth-child(odd) {
    margin-right: 3%; } }

@media screen and (max-width: 976px) {
  .bannerWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .bannerFormGroup {
    width: 50vw; }
  .bannerContent {
    margin-right: 2%;
    width: 55%;
    margin-bottom: 10px; }
  .bannerContent .bannerTitle {
    margin-bottom: 7px; }
  .topCarouselTitle {
    background-color: transparent; }
  .jscarousal-vertical {
    width: 116px; }
  .jscarousal-contents-vertical {
    width: 130px; }
  .jscarousal-contents-vertical > div > div {
    width: 100px; }
  .productGalleryWrapper {
    width: 48vw; }
  .productOrderWrapper {
    width: 35vw; }
  .productContMenu {
    padding-right: 0;
    width: 25vw; }
  .discountItem {
    width: 41vw;
    margin-right: 2vw; }
  .discountItem .discountItemInfo {
    width: 100%;
    padding: 25px; }
  #msCart .cartItemCont {
    width: 22vw; }
  #msCart .cartItemPrice, .cartItemCount, #msCart .cartItemTotal, #msCart .cartItemRemove {
    width: 14vw; }
  #msCart .table .header .price, #msCart .table .header .count, #msCart .table .header .total, #msCart .table .header .remove {
    width: 14.2vw; }
  #msCart .cartItemImage {
    width: 130px;
    height: 130px; }
  #msOrder .orderContacts {
    margin-right: 3%; }
  .mapHolderWrap {
    width: 412px; }
  .contBlocks .contactsWrapper .contactsWrap {
    padding-right: 460px; }
  .contactLocation .locationText, .contactPhone .phoneText, .contactMail .mailText a, .contactSchedule .scheduleText {
    font-size: 16px; }
  .customPopupContentWrapper {
    width: 96%; }
  .customPopupText {
    padding: 40px 22px 22px 22px; }
  .orderProjectButton {
    margin-right: 4px;
    margin-bottom: 6px; } }

@media screen and (max-width: 845px) {
  .productGalleryWrapper {
    margin-right: 0;
    width: 51vw; }
  .productOrderWrapper {
    width: 33vw; }
  .catalogSection .catalogItemSmall .catalogItemImage {
    width: 100%;
    height: auto; }
  #msOrder .orderConfirm button {
    margin-right: 0; }
  .advantagesContent {
    padding-right: 40%; }
  ul.topCatalogMenu > li {
    margin-left: 0; } }

@media screen and (max-width: 768px) {
  h1 {
    font-size: 20px; }
  .headerCart {
    padding-right: 15px; }
  nav#mmenu {
    top: 110px !important; }
  #my-menu:not(.mm-menu) {
    display: none; }
  .headerCallback {
    display: none !important; }
  ul.topCatalogMenu {
    height: auto; }
  .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span, .mm-menu .mm-listview > li.active > a:not(.mm-next), .mm-menu .mm-listview > li.active > span {
    color: #fff !important; }
  .mm-listview li:not(.active) a:not(.mm-next):hover {
    background: transparent !important; }
  .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span, .mm-menu .mm-listview > li.active > a:not(.mm-next), .mm-menu .mm-listview > li.active > span, .mm-listview > li.active > a:not(.mm-next):hover {
    background: #b50000 !important; }
  .mm-menu .mm-listview > li.active > a:not(.mm-next), .mm-menu .mm-listview > li a:not(.mm-next) {
    height: 38px; }
  .mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: 0; }
  .catalogFilterWrapper .catalogSideMenu {
    width: 50%; }
  .container_12 {
    width: auto;
    margin: 0 10px; }
  .noMarginBottom {
    margin-bottom: 0; }
  .container_12.bannerWrapper {
    margin: 0 20px 0 43px; }
  .headerBottom .container_12 {
    width: 100%;
    margin: 0; }
  .mobileHeader {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .mobileHeader .logo {
    margin-left: 0;
    margin-right: 20px;
    background-size: contain;
    width: 100px; }
  .mobileHeader .socialTitle {
    display: none; }
  .mobileHeader button {
    cursor: pointer;
    padding: 0 9px;
    height: 30px;
    width: 30px;
    text-align: center;
    background: #00bd9b;
    color: white;
    text-transform: capitalize;
    display: inline-block;
    border: none;
    outline: none; }
  .mobileHeader button svg, .mobileHeader a svg {
    width: 100%;
    height: 100%;
    fill: #b50000; }
  .mobileHeader .headerSearch::-webkit-input-placeholder {
    color: #a5a8a7; }
  .mobileHeader .formGroup form {
    position: relative;
    margin-right: 10px; }
  .mobileHeader .formGroup .headerSearchButton {
    position: absolute;
    top: 50%;
    left: 6px;
    background-color: #5f5f5f;
    transform: translateY(-50%);
    border-radius: 1000px; }
  .mobileHeader input {
    outline: none;
    border: 1px solid #d0d0d2;
    border-radius: 1000px;
    font-size: 14px;
    padding: 10px;
    padding-left: 54px;
    max-width: 250px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .mobileHeader .headerSearchButton svg {
    width: 50%;
    height: 50%;
    fill: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .mobileHeader .headerLogin {
    border-radius: 50%;
    padding: 0 6px;
    background-color: transparent; }
  .mobileHeader .headerSearchMin {
    display: none; }
  .mobileHeader .phoneText .phone {
    width: 46px;
    height: 46px;
    background-color: #b50000;
    border-radius: 50%;
    padding-left: 2px; }
  .mobileHeader .headerPhoneSvg {
    width: 38%;
    fill: white; }
  .mobileHeader .phoneText {
    text-align: center; }
  .socialIcons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 20px; }
  .socialIcons a {
    margin: 0; }
  .title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
    display: block;
    width: auto; }
  .titleLink {
    line-height: 25px;
    font-size: 14px; }
  .topCarousel .topCarouselImage {
    height: 390px;
    background-size: cover;
    background-position: 42% center; }
  .topCarouselTitle .titleItem .titleItemDec {
    width: 20px;
    height: 20px;
    top: 0; }
  .topCarouselTitle .titleItem .titleItemDec:before {
    width: 6px;
    height: 6px; }
  .topCarouselTitle .titleItem {
    padding-left: 30px;
    margin-bottom: 10px; }
  .topCarouselTitle .titleBig {
    font-size: 28px;
    width: 100%; }
  .topCarouselForm {
    left: unset;
    right: 20px;
    padding: 20px;
    top: 20px; }
  .topCarouselForm .topCarouselFormTitle .titleBold {
    font-size: 18px; }
  .topCarouselForm .topCarouselFormTitle {
    line-height: 1.4;
    margin-bottom: 14px; }
  .topCarousel .owl-controls .owl-dots {
    bottom: 20px; }
  .topCarousel .owl-controls .owl-dot {
    width: 8px;
    height: 8px; }
  .catalogWrapper .catalogItem {
    padding: 15px 10px;
    width: 48%; }
  .catalogWrapper .catalogItem:nth-child(even) {
    margin-right: 0; }
  .titleLine {
    background-position-y: 56%; }
  .catalogItem {
    padding-bottom: 20px; }
  .hitItem {
    padding: 15px;
    height: 100%; }
  .advantagesTitle {
    font-size: 16px; }
  .advantagesText {
    font-size: 14px; }
  .bannerWrapper img {
    width: 190px;
    margin-right: 0; }
  .newsCarousel .newsItemInfo {
    padding: 0 14px; }
  .catalogCarousel .owl-controls, .discountCarousel .owl-controls, .hitsCarousel .owl-controls, .portfolioCarousel .owl-controls, .certificatesCarousel .owl-controls, .questionsCarousel .owl-controls, .reviewsCarousel .owl-controls, .productionCarousel .owl-controls, .newsCarousel01 .owl-controls, .portfolioGallery .owl-controls, .officePhotosWrapper .owl-controls, .relatedCarousel .owl-controls {
    top: -42px; }
  .catalogWrapper {
    padding-top: 0; }
  .catalogWrapper .catalogItemTitle {
    margin-top: 14px;
    margin-bottom: 14px; }
  .catalogItemTitle > a {
    font-size: 16px; }
  .container_12 .overflowHidden {
    margin-bottom: 20px; }
  .advantages {
    margin-top: 10px; }
  .discountItem .discountItemInfo .discountTitle {
    font-size: 14px;
    line-height: 1.3; }
  .footerCatalog, .footerClients, .footerCompany {
    display: none; }
  .footerPhone, .footerLocation, .footerMail, .footerSchedule {
    width: 37%; }
  .forCustomerButton:not(:last-child) {
    margin-right: 3px; }
  .certificates .overflowHidden {
    padding-top: 20px; }
  .certificateItem {
    height: 33vw; }
  .certificatesWrapper {
    padding-bottom: 20px; }
  .questionSvgWrapper {
    top: 14px;
    left: 14px;
    width: 50px;
    height: 50px; }
  .questionItem {
    padding: 14px;
    padding-left: 66px; }
  .questionTitle {
    margin-right: 36px;
    font-size: 16px;
    line-height: 1.1;
    width: 90%;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-left: 16px; }
  .questionTitle:after {
    display: none; }
  .questionCont {
    font-size: 14px;
    line-height: 1.3;
    width: 100%; }
  .questionCont:before {
    content: "";
    position: absolute;
    top: 10px;
    left: -51px;
    width: 30px;
    height: 1px;
    background-color: #b50000; }
  .reviewItem {
    padding: 20px; }
  .reviewItem:nth-child(odd) {
    margin-right: 1%; }
  .reviewTitle {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 14px; }
  .reviewTitle:after {
    right: unset;
    top: unset;
    bottom: 0;
    height: 1px;
    width: 100%; }
  .reviewCont {
    width: 100%;
    padding-top: 14px; }
  .reviewsWrapper {
    padding-top: 20px; }
  .reviewPhoto {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0;
    margin-right: 15px; }
  .reviewInfo {
    display: inline-block;
    margin-top: 0;
    text-align: left; }
  .reviewSend {
    margin-top: 0;
    margin-bottom: 20px; }
  .productionItemCont {
    max-height: 80px;
    overflow: hidden; }
  .discountItem .discountDesc {
    display: none; }
  .discountsWrapper .discountItem {
    width: 49%;
    margin-right: 1%;
    margin-bottom: 1%; }
  .discountCarousel .discountItem .discountItemInfo {
    padding: 14px; }
  .discountItem .discountItemInfo {
    padding: 14px;
    width: 58%; }
  .discountBanner {
    height: auto;
    padding-bottom: 30px; }
  .discountBannerTitle {
    font-size: 20px;
    width: 435px;
    margin-bottom: 5px; }
  .discountConfidential {
    font-size: 12px; }
  .newsItemWrapper #mse2_results {
    -ms-grid-columns: 31vw [3];
    grid-template-columns: repeat(3, 31vw); }
  .newsItemWrapper .newsItem {
    width: calc(100% / 3 - 15px); }
  .newsItemWrapper .newsItem:nth-child(3n) {
    margin-right: 0; }
  .newsItem:nth-child(4n) {
    margin-right: 20px; }
  .portfolioGalleryBlock {
    -ms-grid-rows: 105px 10px 105px 10px 105px;
    grid-template-rows: 105px 105px 105px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    height: 336px; }
  .newsItemWrapper .newsItemInfo {
    padding: 0 14px; }
  .pagination {
    margin: 0; }
  .mapHolderWrap {
    top: 60px;
    right: 15px; }
  .contactsTab {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    width: 170px;
    padding: 0 15px; }
  .contactsTab.active {
    height: 50px;
    line-height: 50px; }
  .contBlocks .contactsWrapper .contactsWrap {
    top: 50px;
    height: 456px;
    padding: 14px;
    padding-right: 460px; }
  .contactLocation, .contactPhone, .contactMail, .contactSchedule {
    width: 100%; }
  .contBlocks .contactsWrapper {
    height: 590px; }
  .contactsFormTitle {
    margin-bottom: 10px; }
  .contactsForm .inputholder {
    width: 100%;
    margin-bottom: 10px; }
  .contactsForm .inputholder textarea {
    margin-top: 0; }
  .contactsConfidential {
    font-size: 13px; }
  .productGalleryWrapper {
    width: calc(97% - 365px);
    display: inline-block;
    vertical-align: top;
    margin-right: 2%;
    height: auto; }
  .productBigImageWrapper {
    width: 100%; }
  .productBigImage {
    height: 250px; }
  .jscarousal-vertical {
    width: 25vw;
    display: none; }
  .jscarousal-contents-vertical {
    margin: 0 auto; }
  .jscarousal-vertical-back {
    left: 6vw; }
  .jscarousal-vertical-forward {
    left: 13vw; }
  .productOrderWrapper {
    width: 100%;
    margin-top: 20px; }
  .sizeItem {
    width: 31%;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    margin-right: 3px; }
  .optionsWrapper {
    width: 100%; }
  .productOrderWrapper {
    width: 365px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 14px;
    font-size: 14px;
    height: auto; }
  .chooseSize {
    width: 100%; }
  .orderButton, .oneClickButton {
    height: 40px;
    line-height: 40px; }
  .orderButton {
    margin-bottom: 5px; }
  .orderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .orderPrice {
    font-size: 31px;
    float: none;
    display: inline-block;
    width: 48%;
    margin-right: 0; }
  .productButtonsWrapper {
    width: 46%;
    display: inline-block;
    margin-top: 10px; }
  .productButtonsWrapper button {
    width: 100%;
    margin-bottom: 10px; }
  .productContMenu {
    width: 31vw; }
  .productContMenuItem {
    margin-bottom: 24px; }
  .productContMenuText {
    width: 63vw;
    padding: 18px 16px; }
  .catalogWrapper {
    padding-top: 30px; }
  .footerCopirightText {
    font-size: 14px;
    margin-top: 18px; }
  .footerContacts {
    flex: 1; }
  .footerContacts .footerContactsTitle {
    display: none; }
  .popup {
    width: 95%;
    height: auto; }
  .popuptitle {
    margin-bottom: 34px; }
  .authRegWrapper {
    width: 394px; }
  .authRegWrapper .control-label,
  .authRegWrapper .form-control {
    font-size: 12px; }
  .authRegWrapper .form-control {
    padding: 17px; }
  .sizeItem input {
    margin-top: 20%; }
  .catalogWrapper .catalogItem {
    padding-top: 12px; }
  .newsItem .newsType {
    font-size: 12px; }
  .articleTitle {
    font-size: 20px; }
  .articleIndex {
    padding-top: 0; }
  .articleContentWrapper {
    max-height: 90px; }
  .footerCopiright {
    width: 156px;
    flex-shrink: 0;
    margin-right: 6%; }
  .footerCopirightMain {
    font-size: 22px; }
  .footerCopirightSubtitle {
    font-size: 10px; }
  .footerContacts > div {
    display: flex; }
  .phoneText .phone {
    font-weight: normal; }
  .footerContacts .footerSocial {
    width: auto; }
  footer {
    padding: 20px 0; }
  .topDiscounts {
    display: none; }
  .headerCartWrapper {
    margin-right: 10px; }
  .headerIncartWrapper {
    display: block; }
  .incartText {
    display: none; }
  .headerMiniCartWrapper {
    justify-content: start;
    align-items: center; }
  .mmenuButton {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }
  .mmenuButton.active {
    justify-content: space-between; }
  .mmenuButtonElement {
    display: block;
    width: 28px;
    height: 4px;
    background-color: #ffffff;
    position: relative;
    transition: all ease 0.3s; }
  .mmenuButtonElement:before, .mmenuButtonElement:after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    top: 0;
    transition: all ease 0.3s; }
  .mmenuButtonElement:before {
    left: 0; }
  .mmenuButtonElement:after {
    right: 0; }
  .mm-wrapper_opening header .mmenuButton .mmenuButtonElement {
    width: 8px;
    height: 8px;
    border-radius: 50%; }
  .mm-wrapper_opening header .mmenuButton .mmenuButtonElement:before {
    width: 8px;
    height: 8px;
    left: -12px;
    opacity: 1; }
  .mm-wrapper_opening header .mmenuButton .mmenuButtonElement:after {
    width: 8px;
    height: 8px;
    right: -12px;
    opacity: 1; }
  .mm-wrapper_opening header .mmenuButton + .mmenuButtonTitle {
    color: #f5c10c; }
  .mmenuButtonTitle {
    margin-left: 5px;
    color: #ffffff; }
  .bannerFormGroup {
    width: 63vw; }
  .bannerFormGroup .inputholder {
    width: 62%; }
  .bannerWrapper .inputholder {
    margin-right: 10px; }
  .bannerContent .bannerTitle {
    font-size: 20px; }
  .bannerWrapper button {
    min-width: 26%; }
  .aboutLogo {
    margin-bottom: 22px; }
  .aboutUsInfoItem {
    width: 100%;
    margin-bottom: 0; }
  .tablet_dots {
    padding-bottom: 30px; }
  .tablet_dots.owl-carousel .owl-controls {
    position: absolute;
    bottom: 0;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-left: 0; }
  .tablet_dots.owl-carousel .owl-controls .owl-dots {
    text-align: center; }
  .tablet_dots.owl-carousel .owl-controls .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #d4d5d7; }
  .tablet_dots.owl-carousel .owl-nav {
    display: none; }
  .tablet_dots.owl-carousel .owl-controls .owl-dot:not(:last-child) {
    margin-right: 10px; }
  .tablet_dots.owl-carousel .owl-controls .owl-dot.active {
    background-color: #b50000; }
  .forCustomerButton {
    background-position: 50% 15%;
    background-size: 28px;
    height: 96px;
    width: 23vw; }
  .forCustomerButton .buttonText {
    padding: 0 20px 10px 20px;
    align-items: flex-end;
    text-align: center;
    font-size: 14px;
    font-weight: normal; }
  .forCustomerCont {
    position: absolute;
    top: 107px; }
  ul.topCatalogMenu li {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .mm-listitem {
    width: 100%; }
  ul.topCatalogMenu li:not(:last-child) {
    border-bottom: 1px solid #485c68; }
  .mm-menu_tip-left:before {
    display: none; }
  .mm-btn:after, .mm-btn:before {
    border-color: #485c68;
    width: 6px;
    height: 6px; }
  .mm-listitem__btn:not(.mm-listitem__text) {
    border-left: none; }
  .questionsCarousel.tablet_dots {
    padding-bottom: 60px; }
  .questionsCarousel.tablet_dots.owl-carousel .owl-controls {
    bottom: 20px; }
  .container_12.aboutUs {
    padding-top: 20px; }
  .reviews {
    padding-top: 0; }
  .reviewsWrapper .reviewItem {
    width: 49%;
    display: inline-block;
    padding: 15px; }
  .readmore-button__show {
    margin-top: 14px; }
  .productionItemsWrapper {
    margin-bottom: 14px; }
  .productionSlogans {
    padding-top: 0; }
  .productionTitle {
    margin-bottom: 0; }
  .productionItem {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 0; }
  .productionGalleryImage {
    height: 20vw; }
  .filterTrigger {
    background-color: #f3f4f6;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: 600;
    color: #b50000;
    border-bottom: 1px solid #e3eceb; }
  .catalogFilterWrapper {
    flex-direction: row;
    transition: all ease 0.4s;
    margin-bottom: 10px; }
  .catalogViewTypes {
    margin-bottom: 20px; }
  .catalogFilterWrapper.active {
    max-height: 1000px; }
  .filterSliderWrapper {
    margin: 0 20px;
    border-left: none; }
  .catalogSortSvg {
    display: none; }
  .catalogSort {
    display: none; }
  #mse2_sort .sort {
    font-size: 14px; }
  .catalogSection .pagination {
    float: none;
    justify-content: center; }
  .catalogItemSmall {
    padding: 14px; }
  #msCart .table .header {
    display: none; }
  .cartSection .table-responsive {
    border: none; }
  #msCart .cartItem {
    padding: 14px; }
  #msCart .cartItem:not(:last-child) {
    margin-top: 0;
    margin-bottom: 10px; }
  #msCart .cartItemCont {
    width: 55vw; }
  #msCart .cartItemTitle {
    font-size: 16px; }
  #msCart .cartItemImage {
    width: 100px;
    height: 120px; }
  #msCart .cartItemPriceWrapper {
    align-self: stretch;
    position: relative;
    width: 37vw;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #e3eceb;
    padding-left: 7%; }
  #msCart .cartPrice {
    font-size: 18px; }
  #msCart .cartItemPrice {
    height: auto; }
  #msCart .cartItemPrice, #msCart .cartItemCount, #msCart .cartItemTotal, #msCart .cartItemRemove {
    width: 100%;
    text-align: left; }
  .cartPriceTitle {
    display: inline-block;
    font-size: 14px;
    color: #b50000;
    padding-right: 6px; }
  #msCart .cartRemoveButton {
    position: absolute;
    top: 0;
    right: 0; }
  .cartItem .productInStock {
    margin-bottom: 0; }
  #msCart .cartItemText {
    width: calc(93% - 100px); }
  #msOrder .orderContacts .title, #msOrder .orderDelivery .title {
    margin-bottom: 0px; }
  #msOrder {
    margin-top: 0;
    padding: 30px; }
  .cartWrapper {
    padding-bottom: 30px; }
  .profileWrapper {
    padding: 18px; }
  .officePicCarouselWrapper {
    width: 93vw; }
  .catalogFilterWrapper form {
    width: 50%; }
  .newsBigItemInfo .newsItemInfo {
    display: none; }
  .newsBigItemImage {
    width: 40%; }
  .newsBigItemDesc {
    width: 56%; }
  .newsBigItemDesc h3 {
    font-size: 18px; }
  .newsBigItemDescSubtitle {
    margin-top: 14px; }
  .newsBigItem .newsType {
    font-size: 12px;
    line-height: 20px; }
  .customPopupImage {
    width: 47%; }
  .customPopupTextWrapper {
    width: 52%; }
  .customPopupText {
    padding: 22px; }
  .customPopupTitle {
    margin-bottom: 16px; }
  .prof_order_header, .profile_order_product {
    padding: 20px; }
  .prof_order_item.first {
    width: 5%; }
  .prof_order_item.second {
    width: 45%; }
  .footerLinks {
    display: none; }
  .relatedCarousel .owl-stage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit; }
  .discountContent .discountDesc {
    width: 100%; }
  .from {
    display: none; }
  .bannerFormGroup form .flex {
    flex-direction: column; }
  .bannerFormGroup .form-input,
  .bannerFormGroup .button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    max-width: none; }
  .topCarousel .topCarouselImage .container_12 {
    width: 100%; }
  .topCarouselTitle {
    padding: 0; }
  .topMenu.mm-listview {
    height: auto;
    flex-wrap: wrap; }
  .mm-listitem:after {
    border-color: #b50000; } }

@media screen and (max-width: 680px) {
  .catalogSection .catalogItem {
    position: relative;
    height: auto; }
  .catalogItem.full .catalogItemImage {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 32%; }
  .catalogItem.full .productStock {
    margin-left: 40%; }
  .catalogSection .catalogItemCont {
    width: 98%; }
  .catalogSection .productPreorder,
  .catalogSection .productInStock {
    margin-left: 45%; }
  .catalogSection .catalogItemTitle {
    margin-left: 40%;
    font-size: 16px; }
  .catalogSection .catalogItemDescription {
    margin-top: 22vw; }
  #mse2_sort .sort {
    margin-right: 10px; }
  .catalogSection .catalogItemSmall .catalogItemImage {
    position: relative;
    top: 0;
    left: 0; }
  .catalogSection .catalogItemSmall .productPreorder, .catalogSection .catalogItemSmall .productInStock, .catalogSection .catalogItemSmall .productOnStorage {
    margin: 0;
    margin-left: 20px; }
  .catalogSection .catalogItemSmall .catalogItemTitle {
    margin: 0; }
  .catalogSection .catalogItemSmall .catalogItemDescription {
    margin: 0; }
  .form-horizontal .form-group.form-half {
    display: block;
    width: 100%; }
  .form-horizontal .form-group.form-half:nth-child(odd) {
    margin-right: 0; }
  .advantagesText {
    font-size: 12px; }
  .footerContacts {
    flex: none;
    margin-top: 18px; }
  .topCarouselTitle {
    width: 68vw; }
  .topCarouselTitle .titleBig {
    font-size: 28px;
    width: 85%; }
  .titleItemText {
    font-size: 12px; }
  .topCarouselForm {
    width: 250px;
    right: 10px; }
  .discountBanner {
    padding: 16px 0; }
  .discountBannerForm {
    width: auto;
    margin: 25px 25px 0 25px; }
  .discountBannerForm form {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .discountBannerForm .inputholder {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 20px; }
  .discountBannerForm .inputholder input {
    width: 100%; }
  .productContMenu {
    padding: 20px; }
  .productContMenu .menuText {
    font-size: 12px; }
  .productContTitle {
    font-size: 18px; }
  .catalogItemOptions {
    font-size: 12px; }
  .mapHolderWrap, .mapHolder {
    width: 300px;
    height: 360px; }
  .contBlocks .contactsWrapper .contactsWrap {
    padding-right: 333px;
    height: 382px; }
  .contactsTab {
    width: 120px;
    font-size: 12px; }
  .contactsForm {
    width: 96%; }
  .contactLocation .locationText, .contactPhone .phoneText, .contactMail .mailText a, .contactSchedule .scheduleText {
    font-size: 14px; }
  .contBlocks .contactsWrapper {
    height: 507px; }
  .officePicCarouselWrapper {
    bottom: 12px; }
  .orderConfirm {
    padding-top: 15px; } }

@media screen and (max-width: 610px) {
  .headerSocial {
    display: none; }
  .advantagesItem:not(:last-child) {
    margin-bottom: 10px; }
  .advantagesContent {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .advantagesTitle {
    font-size: 20px; }
  .advantagesText {
    font-size: 14px; }
  .container_12.bannerWrapper {
    margin: 0 20px 0 30px; }
  .newsItem .newsImage {
    margin-bottom: 6px; }
  .newsItem .newsType {
    font-size: 9px;
    width: 45px;
    height: 15px;
    line-height: 14px; }
  .newsTitle {
    font-size: 10px;
    margin-bottom: 0; }
  .newsDesc {
    font-size: 10px; }
  .newsDate {
    font-size: 9px; }
  .newsCarousel .newsItemInfo {
    padding: 0 7px; }
  .catalogWrapper .catalogItem {
    padding-top: 9px; }
  .discountItem .discountItemInfo .discountTitle {
    font-size: 12px; }
  .discountDurationCont {
    font-size: 12px; }
  .discountDuration {
    width: 100px; }
  .discountButton {
    font-size: 12px; }
  .productionItemCont {
    width: 98%;
    margin-bottom: 10px; }
  .productionItemTitle {
    height: auto; }
  .productionItem {
    height: auto; }
  .catalogSection .catalogResultsWrapper #mse2_results {
    grid-column-gap: 10px; }
  #mse2_sort .sort:first-of-type {
    margin-left: 0; }
  .catalogSection .catalogResultsWrapper #mse2_results {
    -ms-grid-columns: 1fr [2];
    grid-template-columns: repeat(2, 1fr); }
  .catalogViewTypes .sortType .sort {
    margin-left: 10px; }
  #mse2_sort .sort[data-sort="ms|available"] {
    display: none; }
  .productGalleryWrapper {
    width: 100%;
    height: 250px; }
  .jscarousal-vertical {
    height: 250px; }
  .productOrderWrapper {
    width: 100%; }
  .productBigImageWrapper {
    width: 67%; }
  .jscarousal-vertical {
    width: 25vw;
    display: inline-block; }
  .jscarousal-contents-vertical > div > div {
    height: 60px;
    margin-bottom: 10px; }
  .productBigImageWrapper {
    height: auto;
    width: 73%; }
  #msCart .cartItemPriceWrapper {
    padding-left: 4%; }
  #msOrder .orderContacts .title, #msOrder .orderDelivery .title {
    font-size: 16px; }
  .titleLine {
    margin-left: 0; }
  .discountsWrapper .discountItem .discountItemInfo {
    width: 100%; }
  .catalogFilterWrapper form {
    width: 75%; }
  .customPopupImage, .customPopupTextWrapper {
    width: 100%; }
  .customPopupImage {
    height: 40%; }
  .customPopupBtn {
    background-color: transparent;
    width: 30px;
    height: 30px; }
  .customPopupBtn.btn-right .customPopupSvg, .customPopupBtn.btn-left .customPopupSvg {
    position: absolute;
    top: 0;
    left: 0; }
  .customPopupTextWrapper {
    height: 60%; }
  .customPopupDescr {
    max-height: 162px; } }

@media screen and (max-width: 530px) {
  .orderWrapper {
    flex-direction: column; }
  .productContMenu {
    position: relative; }
    .productContMenu.active {
      height: 140px; }
      .productContMenu.active:after {
        transform: rotate(45deg); }
      .productContMenu.active .productContMenuText {
        display: none; }
      .productContMenu.active li {
        display: block; }
    .productContMenu li {
      margin-bottom: 10px; }
    .productContMenu:after {
      content: '';
      display: block;
      position: absolute;
      right: 15px;
      top: 20px;
      width: 10px;
      height: 10px;
      border-bottom: 1px solid #b50000;
      border-right: 1px solid #b50000;
      transform: rotate(-45deg); }
  .owl-carousel .owl-controls .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #d4d5d7; }
  .owl-carousel .owl-controls .owl-dot:not(:last-child) {
    margin-right: 10px; }
  .owl-carousel .owl-controls .owl-dot.active {
    background-color: #b50000; }
  .catalogItemOldPrice {
    position: relative;
    top: 0;
    transform: none; }
  .cartAddedPrice {
    display: block;
    width: 100%;
    padding-top: 20px;
    text-align: left; }
  .crossSaleCarousel .hitItem {
    width: 100%; }
  .crossSaleCarousel .owl-dots {
    text-align: center;
    padding-top: 20px; }
  .crossSaleCarousel {
    padding-bottom: 10px !important; }
  .cartAddedButtons .button {
    width: 100%;
    margin-right: 0;
    text-align: center; }
  .cartAddedButtons .button:first-child {
    margin-bottom: 20px; }
  .cartAddedContent {
    width: calc(100% - 135px); }
  .customPopupWrapper .price {
    font-size: 18px; }
  .customPopupWrapper, .popup {
    top: 150px; }
  .customPopupWrapper {
    padding: 15px; }
  .customPopupWrapper .profile_order_product {
    padding: 10px; }
  .catalogWrapper .catalogItem {
    width: calc(50% - 12px);
    margin-right: 10px;
    margin-bottom: 10px; }
  .catalogWrapper .catalogItem:nth-child(2n) {
    margin-right: 0 !important; }
  .advantagesText {
    line-height: 1.2; }
  .container_12.bannerWrapper {
    margin: 0 10px; }
  .bannerWrapper img {
    display: none; }
  .bannerContent {
    width: 100%;
    text-align: center; }
  .bannerFormGroup {
    width: 100%; }
  .bannerFormGroup .inputholder {
    width: 100%;
    margin-right: 0; }
  .bannerWrapper button {
    display: block;
    margin: 0 auto; }
  .aboutUsInfoItem {
    width: 100%;
    margin-bottom: 20px; }
  .forCustomerButton {
    background-position: center center; }
  .forCustomerButton .buttonText {
    display: none; }
  .questionTitle {
    width: 100%;
    margin-bottom: 20px; }
  .questionTitle:after {
    right: 50%;
    top: auto;
    bottom: -10px; }
  .questionCont {
    width: 93%; }
  .reviewInfo {
    display: inline-block;
    margin: 0; }
  .productionTitle {
    font-size: 20px;
    margin-bottom: 35px; }
  .discountBannerTitle {
    width: 85%; }
  .footerIconWrapper {
    margin-bottom: 3px; }
  .footer {
    padding-bottom: 30px; }
  .portfolioGalleryBlock {
    -ms-grid-rows: 110px 110px 110px;
    grid-template-rows: 110px 110px 110px;
    height: 351px; }
  .productGalleryWrapper {
    height: auto;
    margin-bottom: 20px; }
  .chooseSize {
    width: 100%; }
  .sizeItemWrapper {
    width: 100%; }
  .sizeItem {
    width: 102px; }
  .orderPrice {
    margin-top: 20px;
    margin-bottom: 5px;
    width: 100%;
    text-align: left; }
  .productButtonsWrapper {
    display: flex;
    width: 100%; }
  .productContMenuItem {
    display: block;
    width: 100%;
    margin-bottom: 12px; }
  .catalogItemOptions {
    font-size: 12px; }
  .productContMenuText::-webkit-scrollbar,
  .forCustomerCont::-webkit-scrollbar {
    width: 5px; }
  .productContWrapper {
    height: 333px; }
  .discountBannerTitle {
    width: 79%; }
  .discountConfidential {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px; }
  .profileWrapper {
    padding: 30px 15px; }
  .footerContacts .footerSocial {
    position: absolute;
    margin-top: 0;
    top: 32px;
    left: 190px; }
  .footerWrapper {
    position: relative; }
  .footerPhone, .footerLocation, .footerMail, .footerSchedule {
    width: 49%; }
  .articleTitle {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 14px; }
  .articleContentWrapper {
    max-height: 50px;
    margin-bottom: 0; }
  .articleContent {
    font-size: 10px; }
  .articleWrapper span.more {
    font-size: 12px; }
  .articleIndex {
    margin-bottom: 30px; }
  .topCarouselForm {
    top: unset;
    bottom: -180px;
    right: 50%;
    width: 92%;
    transform: translateX(50%); }
  .topCarouselTitle {
    width: 95vw; }
  .topCarousel .topCarouselImage {
    height: 385px;
    background-position: 82% center;
    position: relative; }
  .titleItemText {
    font-size: 10px; }
  .topCarouselTitle .titleItem {
    margin-bottom: 4px; }
  .topCarouselTitle .titleItem .titleItemDec {
    width: 14px;
    height: 14px;
    top: 0;
    border: 1px solid #dfe2e6;
    border-radius: 2px; }
  .catalogItemTitle > a {
    font-size: 12px; }
  .discountCarousel .discountItem .discountItemInfo {
    width: 100%; }
  .titleLink {
    font-size: 12px; }
  .discountConfidential {
    width: 100%;
    text-align: center; }
  .discountBannerForm .inputholder {
    width: 85%; }
  .discountBannerTitle {
    font-size: 18px; }
  .productContMenu {
    padding: 14px; }
  .productContMenu {
    height: 50px;
    width: 100%; }
  .productContMenuItem {
    display: inline-block;
    width: auto;
    margin-bottom: 0; }
  .productContMenuItem {
    display: none; }
  .productContMenuItem.active {
    display: block; }
  .productContMenuText {
    width: 100%;
    margin-top: 50px;
    height: 285px; }
  .productContTitle {
    display: none; }
  #msCart .cartItem {
    position: relative;
    height: 288px;
    align-items: flex-start; }
  #msCart .cartItemCont {
    width: 95%; }
  #msCart .cartItemPriceWrapper {
    position: absolute;
    top: 150px;
    right: 0;
    width: 100%;
    padding-left: 14px;
    border-left: none; }
  #msCart .cartRemoveButton {
    position: absolute;
    top: -133px;
    right: 7px; }
  #msCart .cartItemTotal, #msCart .cartItemPrice {
    padding-left: 118px;
    margin-top: 6px; }
  .cart_count .cartItemCount {
    padding: 0;
    order: inherit;
    width: auto;
    background: white;
    z-index: 2;
    padding-left: 10px; }
  .cartItemCount {
    order: -1;
    padding-left: 118px; }
  #msCart .text-nowrap {
    display: block;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 93%;
    background-position: 100% 73%;
    background-repeat: repeat no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAACCAYAAABllJ3tAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACtJREFUeNpi+P//PwMMa1m7pmlbu/4H4lCYGAsDEmBkYFD6D2GehYkBBBgAxssUn0pV8jsAAAAASUVORK5CYII=); }
  #msCart .cartPrice {
    margin-left: auto;
    padding-right: 10px; }
  .cartPriceTitle, .cartPrice, .cartCurrency {
    background-color: #ffffff; }
  .profileContent .profileCol {
    width: 100%; }
  .contBlocks .contactsWrapper .contactsWrap {
    padding-right: 14px;
    height: 760px; }
  .mapHolderWrap {
    width: 90vw;
    top: 440px;
    right: 50%;
    transform: translateX(50%); }
  .mapHolder {
    width: 100%; }
  .mapHolderWrap, .mapHolder {
    height: 360px; }
  .contBlocks .contactsWrapper {
    height: 896px; }
  .officePicCarouselWrapper {
    width: 90vw; }
  .prof_order_item.second {
    margin-bottom: 20px; }
  .prof_order_item.second ~ .prof_order_item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
    align-items: center; }
  .prof_order_item.first {
    margin-bottom: 10px; }
  .prof_order_item.second ~ .prof_order_item span {
    padding-left: 10px;
    background-color: white;
    z-index: 2;
    vertical-align: middle;
    line-height: 40px; }
  .prof_order_item.second {
    padding-left: 10px; }
  .prof_order_item .right_block a {
    font-size: 12px; }
  .prof_order_item.second ~ .prof_order_item:last-child {
    margin-bottom: 0;
    color: #b50000; }
  .profile_order_products .between {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .prof_order_item.second ~ .prof_order_item:before {
    content: '';
    display: inline-block;
    background-color: white;
    padding-right: 10px;
    z-index: 2;
    line-height: 40px; }
  .prof_order_item.second ~ .prof_order_item.price:before {
    content: 'Цена'; }
  .prof_order_item.second ~ .prof_order_item.count:before,
  .prof_order_item.second ~ .prof_order_item.cart_count:before {
    content: 'Количество'; }
  .prof_order_item.second ~ .prof_order_item.cost:before {
    content: 'Стоимость'; }
  .prof_order_item.second ~ .prof_order_item:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(to right, #dfe4e6 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x; }
  .prof_order_item.count span {
    border: none;
    padding: 0;
    line-height: normal;
    height: auto; }
  .prof_order_header {
    display: none; }
  .prof_order_item.second {
    width: 95%; }
  .topCarousel .topCarouselImage .container_12 {
    margin: 0;
    padding: 0 10px; }
    .topCarousel .topCarouselImage .container_12:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.8);
      visibility: visible; }
  .topCarouselTitle {
    left: 10px; }
  #msCart .count_input {
    margin-bottom: 14px; } }

@media screen and (max-width: 460px) {
  .hitItem .hitItemImage {
    min-height: 34vw; }
  .reviewsWrapper, .catalog.index.newsIndex {
    padding-bottom: 5vw; }
  .discountIndex, .hitsSection, .portfolioIndex, .reviewsWrapper {
    margin-bottom: 5vw; }
  .catalogWrapper .catalogItemTitle {
    padding: 0; }
  .reviewsWrapper.reviewsPageWrapper {
    margin-bottom: 0; }
  .titleLine {
    display: none; }
  .titleLink {
    line-height: 20px; }
  .discountDuration {
    min-width: 110px; }
  .topCarousel .owl-controls .owl-dots {
    bottom: 5px; }
  .catalogSection .catalog_wrapper .catalogItem {
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px; }
    .catalogSection .catalog_wrapper .catalogItem:nth-child(3n) {
      margin-right: 10px; }
    .catalogSection .catalog_wrapper .catalogItem:nth-child(2n) {
      margin-right: 0; }
  .catalogSection .catalog_wrapper .catalogItemTitle {
    font-size: 14px; }
  .discountItem .button {
    font-size: 9px;
    line-height: 38px;
    min-width: 110px; }
  .footer_item .phoneText .phone {
    font-size: 12px; }
  .customPopupWrapper .input_block .form-half {
    width: 100%;
    margin-right: 0; }
  .headerCart {
    width: 60px;
    padding: 0; }
  h1 {
    font-size: 18px; }
  .title {
    font-size: 18px; }
  .container_12.aboutUs, .container_12.catalogWrapper, .container_12.cart, .container_12.news, .container_12.discounts, .container_12.portfolioGalleryWrapper {
    padding-top: 0; }
  .container_12.portfolioGalleryWrapper {
    padding-bottom: 18px; }
  .container_12.news, .container_12.portfolioGalleryWrapper {
    padding-bottom: 10px; }
  .content {
    font-size: 10px; }
  .breadcrumb > li {
    font-size: 10px; }
  .breadcrumb > li:not(:last-child) {
    margin-right: 15px; }
  .breadcrumb > li:not(:last-child)::after {
    right: -15px; }
  .mobileHeader .formGroup {
    position: absolute;
    width: 55%;
    display: none; }
    .mobileHeader .formGroup input {
      padding-left: 40px; }
  .mobileHeader .headerSearchMin {
    display: inline-block;
    background-color: transparent;
    border-radius: 50%;
    padding: 0 8px;
    margin-left: 28%;
    width: 42px;
    height: 42px; }
  .catalogFilterWrapper {
    flex-direction: column; }
  .catalogFilterWrapper .catalogSideMenu {
    width: 100%; }
  .mobileHeader .phoneText .phone {
    width: 42px;
    height: 42px; }
  .headerIncartWrapper {
    display: none; }
  .headerCartWrapper {
    margin-right: 0;
    width: 100%; }
  .cartIcon {
    width: 30%; }
  .headerIncart {
    right: 9px; }
  .catalogCarousel .catalogWrapper.catalogMobile {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2vw; }
  .newsCarousel .newsItem {
    margin-bottom: 0; }
  .discountItem {
    margin-bottom: 0; }
  .hitItemButton {
    display: block;
    height: 35vw; }
  .hitItemDescr {
    display: none; }
  .hitItemContent {
    padding-top: 7px; }
  .hitItemContent:after {
    display: none; }
  .hitItemTitle {
    font-size: 12px; }
  .productOrderWrapper .productRating {
    top: 2%; }
  .productInStock, .productPreorder {
    margin-bottom: 10px; }
  .hitsSection {
    padding-bottom: 0; }
  .owl-carousel {
    padding-bottom: 40px; }
  .catalogCarousel .owl-controls, .discountCarousel .owl-controls, .hitsCarousel .owl-controls, .portfolioCarousel .owl-controls, .certificatesCarousel .owl-controls, .questionsCarousel .owl-controls, .reviewsCarousel .owl-controls, .productionCarousel .owl-controls, .newsCarousel01 .owl-controls, .portfolioGallery .owl-controls, .officePhotosWrapper .owl-controls, .relatedCarousel .owl-controls, .newsCarousel .owl-controls {
    top: unset;
    bottom: 10px;
    padding-left: 0;
    right: 50%;
    transform: translateX(50%); }
  .owl-carousel .owl-controls .owl-nav {
    display: none; }
  .forCustomer .mobileTabs {
    height: auto;
    flex-direction: column; }
  .mobileTabs .forCustomerButton {
    width: 100%;
    height: auto;
    border: none;
    background: none;
    margin-bottom: 10px; }
  .mobileTabs .forCustomerCont {
    position: static; }
  .mobileTabs .forCustomerButton .buttonText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
    border: 1px solid #e3eceb;
    border-radius: 4px;
    padding: 0;
    padding-left: 70px;
    color: #b50000;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    transition: all ease 0.3s; }
  .mobileTabs .forCustomerButton .buttonText:after {
    content: "";
    position: absolute;
    top: 23px;
    right: 15px;
    width: 6px;
    height: 6px;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    transform: rotate(45deg);
    transition: all ease 0.3s; }
  .mobileTabs .forCustomerButton.active .buttonText:after {
    transform: rotate(-135deg); }
  .mobileTabs .forCustomerButton .forCustomerCont {
    height: 0;
    transition: all ease 0.3s;
    display: block; }
  .mobileTabs .forCustomerButton.active .buttonText {
    border: 1px solid #00cfb6;
    background-color: #f3f4f6; }
  .mobileTabs .forCustomerButton.active .forCustomerCont {
    height: 286px;
    transition: all ease 0.3s; }
  .mobileTabs .forCustomerButton .buttonText {
    background-size: 30px;
    background-position: 15px center;
    background-repeat: no-repeat; }
  .mobileTabs .forCustomerButton:nth-child(1) .buttonText {
    background-image: url(../img/forCustomer001.png); }
  .mobileTabs .forCustomerButton:nth-child(2) .buttonText {
    background-image: url(../img/forCustomer002.png); }
  .mobileTabs .forCustomerButton:nth-child(3) .buttonText {
    background-image: url(../img/forCustomer003.png); }
  .mobileTabs .forCustomerButton:nth-child(4) .buttonText {
    background-image: url(../img/forCustomer004.png); }
  .aboutUsInfoItem {
    width: 49%;
    height: 140px;
    margin-bottom: 10px; }
  .aboutUsInfoItemTitle {
    font-size: 14px;
    height: 48px;
    line-height: 48px; }
  .aboutUsInfoItemCont {
    font-size: 12px; }
  .certificateItem {
    height: 68vw; }
  .questionTitle {
    font-size: 12px;
    font-weight: 600;
    padding-left: 0;
    padding-top: 10px; }
  .questionCont {
    font-size: 10px; }
  .questionSvgWrapper {
    width: 30px;
    height: 30px; }
  .reviewsWrapper .reviewItem {
    width: 100%;
    font-size: 10px; }
  .reviewPerson {
    font-size: 12px; }
  .readmore-button__show {
    margin-top: 0; }
  .uncutter.active {
    min-height: 84px; }
  .reviewSend {
    margin-top: 20px; }
  .productionItem {
    padding-top: 20px; }
  .productionItemNumber {
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    transform: translate(0);
    top: 15px;
    left: 15px; }
  .productionItemTitle {
    text-align: left;
    padding-left: 45px;
    margin: 0px auto 20px; }
  .productionItemCont {
    font-size: 10px; }
  .productionGalleryImage {
    display: inline-block;
    width: calc(50% - 5px);
    height: 30vw;
    margin-bottom: 10px; }
  .productionGalleryImage:nth-child(2n) {
    margin-right: 0; }
  .productionGalleryImage:nth-child(odd) {
    margin-right: 10px; }
  .discountBannerForm {
    margin: 25px 10px 0 10px; }
  .discountBannerForm .inputholder {
    width: 100%; }
  .aboutUsInfoWrapper {
    padding-bottom: 0; }
  .filterTrigger {
    text-transform: capitalize; }
  #mse2_sort .sort span {
    position: absolute;
    top: 8px;
    right: 8px; }
  #mse2_sort .sort[data-sort="ms|price"] {
    padding: 10px;
    padding-right: 20px;
    margin: 0;
    background-color: #ffe9e0; }
  #mse2_sort .sort:not([data-sort="ms|price"]) {
    display: none; }
  #mse2_sort .sort {
    font-size: 10px;
    border-radius: 4px; }
  .catalogViewTypes {
    padding: 13px 8px 16px 8px; }
  .catalogViewTypes .viewType .fas {
    font-size: 18px; }
  .catalogSection .catalogItemSmall .catalogItemDescription {
    display: none; }
  .catalogItemSmall {
    width: calc(50% - 16px);
    padding: 8px; }
  .catalogItemSmall:nth-child(2n) {
    margin-right: 0 !important; }
  .catalogItemSmall:nth-child(3n) {
    margin-right: 20px; }
  .catalogFilterWrapper form {
    width: 100%; }
  .filterSliderWrapper {
    margin: 0 10px;
    padding: 0 6px;
    border-left: none; }
  .catalogItemPrice {
    margin-right: 0; }
  .hitItemPrices, .catalogItemPrices {
    justify-content: space-between; }
  .catalogSection .catalogItemSmall .catalogItemTitle {
    margin-bottom: 6vw; }
  .catalogSection .catalogItem {
    padding: 8px; }
  .catalogSection .catalogItem .catalogItemDescription {
    display: none; }
  .catalogSection .catalogItem .catalogItemImage {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    display: inline-block;
    vertical-align: top; }
  .catalogSection .catalogItemCont {
    width: 68%; }
  .catalogSection .catalogItem .productInStock {
    margin-left: 16px; }
  .catalogItem.full .catalogItemTitle, .catalogItem.full .productStock {
    margin-left: 0; }
  .catalogSection .catalogItemImage {
    width: 37%; }
  .productBigImageWrapper {
    width: 67%;
    margin-right: 2px; }
  .productBigImage {
    height: 72vw; }
  .jscarousal-vertical {
    width: 27vw;
    height: 230px; }
  .jscarousal-contents-vertical {
    width: 100%;
    height: 230px; }
  .jscarousal-contents-vertical > div > div {
    margin: 0;
    margin-bottom: 8px;
    width: 100%; }
  .catalogInsedeItem {
    padding-top: 0; }
  .jscarousal-vertical-back {
    left: 0; }
  .jscarousal-vertical-forward {
    left: 35px; }
  .chooseSize {
    font-size: 10px; }
  .sizeItem label {
    font-size: 12px; }
  .productOrderWrapper .productRating .reviewsLink {
    font-size: 10px; }
  .paymentOptionsTitle, .deliveryWrapper {
    font-size: 12px; }
  #msCart .cartItemImage {
    width: 70px;
    height: 70px; }
  .cartItem .catalogItemHit {
    width: 15px;
    height: 15px;
    background-size: 46%; }
  .cartItem .catalogItemDiscount {
    font-size: 9px;
    line-height: 14px;
    height: 15px;
    width: 30px; }
  .productInStock, .productPreorder {
    font-size: 10px; }
  #msCart .cartItemTitle {
    font-size: 12px; }
  #msCart .cartItemSubtitle {
    font-size: 10px;
    height: 42px; }
  #msCart .cartItemText .small {
    font-size: 10px;
    margin-top: 4px; }
  #msCart .cartItemPriceWrapper {
    top: 108px; }
  #msCart .cartItem {
    padding: 10px;
    height: 230px; }
  .cartItemCount .number {
    width: 78px; }
  .cart_count .cartItemCount .number {
    width: auto; }
  .cartItemCount {
    padding-left: 84px; }
  #msCart .input-group .form-control {
    width: 18px;
    font-size: 12px; }
  .cartItemCount .minus, .cartItemCount .plus {
    width: 24px; }
  #msCart .cartItemTotal, #msCart .cartItemPrice {
    padding-left: 84px;
    margin-top: 6px; }
  #msCart .cartPriceTitle, #msCart .cartPrice, #msCart .cartCurrency {
    font-size: 12px; }
  #msCart .cartRemoveButton {
    top: -100px; }
  #msOrder .orderDelivery {
    display: none; }
  #msOrder .orderContacts {
    width: 100%; }
  #msOrder .orderMain, #msOrder .contData {
    width: 100%; }
  .orderConfirmWrapper > div {
    width: 100%; }
  .orderConfirmWrapper input, .orderConfirmWrapper select, .orderConfirmWrapper textarea {
    font-size: 10px; }
  #msOrder {
    padding: 14px; }
  #msOrder .form-control, #msOrder select {
    padding: 15px;
    height: 40px; }
  #msOrder select {
    padding: 10px 8px; }
  #msOrder .orderConfirm button {
    font-size: 10px; }
  .orderConfirmTitle {
    font-size: 12px; }
  .profileWrapper .title {
    font-size: 18px; }
  .profileWrapper .title a {
    font-size: 12px; }
  .profileContent {
    font-size: 12px; }
  .officePicItemBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 34vw);
    grid-column-gap: 2vw;
    grid-row-gap: 2vw; }
  .contBlocks .contactsWrapper .contactsWrap {
    height: 763px; }
  .contBlocks .contactsWrapper {
    height: 890px; }
  .newsItemWrapper #mse2_results {
    -ms-grid-columns: 46vw [2];
    grid-template-columns: repeat(2, 46vw); }
  .newsItemWrapper .newsItem {
    width: calc(50% - 10px); }
  .newsItemWrapper .newsItem:nth-child(2n), .newsItemWrapper .newsItem:nth-child(4n) {
    margin-right: 0 !important; }
  .newsItemWrapper .newsItem:nth-child(3n) {
    margin-right: 20px; }
  .newsItemWrapper .newsItemInfo {
    padding: 0 8px; }
  .newsItemWrapper #mse2_results {
    margin-bottom: 0; }
  .discountsWrapper .discountItem {
    width: 100%; }
  .discountsWrapper .discountItem .discountItemInfo {
    width: 59%; }
  .newsBigItemImage, .newsBigItemDesc {
    width: 100%; }
  .newsBigItemInfo .newsItemInfo {
    display: block; }
  .customPopupContentWrapper {
    height: 608px;
    top: 50px;
    transform: translate(-50%, 0); }
  .customPopupContent {
    border-radius: 6px;
    height: 80vh; }
  .customPopupTitle {
    font-size: 12px; }
  .customPopupDescr {
    font-size: 10px;
    max-height: 225px;
    margin-bottom: 16px; }
  .customPopupText {
    padding: 16px; }
  .customPopupPanel {
    text-align: center; }
  .mobileHeader {
    justify-content: flex-end; }
  .phoneText {
    margin-right: 12px; }
  .headerSearchMin {
    margin-right: 12px; }
  .mobileHeader .logo {
    margin-right: auto; }
  .mobileHeader.searched .formGroup {
    left: 10px; } }

@media screen and (max-width: 440px) {
  .discountCarousel .discountItem .discountItemInfo {
    width: 56%; }
  .titleLink {
    font-size: 10px; }
  .discountBannerTitle {
    font-size: 18px; }
  .discountBannerSubtitle {
    font-size: 12px; }
  .discountBannerForm .carouselFormButton {
    font-size: 10px;
    width: 100%; }
  .discountBannerForm .inputholder input {
    font-size: 10px; } }

@media screen and (max-width: 425px) {
  .forCustomerButton {
    width: 20vw; }
  .forCustomerButton:not(:last-child) {
    margin-right: 3vw; }
  .questionTitle {
    width: 90%; }
  .catalogCarousel .owl-controls, .discountCarousel .owl-controls, .hitsCarousel .owl-controls, .portfolioCarousel .owl-controls, .certificatesCarousel .owl-controls, .questionsCarousel .owl-controls, .reviewsCarousel .owl-controls, .productionCarousel .owl-controls, .newsCarousel01 .owl-controls, .portfolioGallery .owl-controls, .officePhotosWrapper .owl-controls, .relatedCarousel .owl-controls {
    padding-left: 0; }
  .popuptitle {
    line-height: 50px;
    font-size: 16px;
    margin-bottom: 20px; }
  .popup input {
    height: 35px; }
  .inputholder:before {
    background-size: contain; }
  .inputholder:nth-child(1):before {
    top: 8px;
    width: 20px;
    height: 20px; }
  .inputholder:nth-child(2):before {
    top: 6px;
    left: 15px;
    width: 14px;
    height: 25px; }
  .popupReview .inputholder:nth-child(2):before {
    width: 22px;
    top: 10px; }
  .inputholder:nth-child(3):before {
    width: 23px;
    top: 9px; }
  .popupReview .inputholder:nth-child(3):before {
    left: 12px;
    width: 18px; }
  .inputholder:nth-child(4):before {
    left: 11px;
    top: 9px;
    width: 20px;
    height: 20px; }
  .popup textarea {
    padding-top: 8px;
    height: 100px; }
  .popup button {
    margin: 20px auto; }
  .authRegWrapper {
    width: 310px; }
  .authRegCont {
    padding: 12px; }
  .recaptchaWrapper {
    margin-left: -8px;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95); }
  .footerCopirightText {
    font-size: 10px;
    margin-top: 12px;
    padding-top: 0; }
  .footerContacts > div {
    display: flex;
    font-size: 10px;
    line-height: 1.3; }
  .footerPhone .phoneText .phone {
    font-size: 10px;
    line-height: 1.3; }
  .footerIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px; }
  .getOrder {
    display: none; }
  .hitItem {
    padding: 7px; }
  .aboutUsInfoItemTitle {
    font-size: 12px; }
  .aboutUsInfoItemCont {
    font-size: 10px; }
  .aboutUsInfoItem {
    padding: 0px 10px; } }

@media screen and (max-width: 395px) {
  .authRegWrapper {
    width: 310px; }
  .advantagesItem {
    height: 165px; }
  .advantagesTitle {
    font-size: 12px;
    width: 90%;
    font-weight: 600; }
  .advantagesText {
    font-size: 10px;
    width: 75%; }
  .mobileTabs .forCustomerButton .buttonText {
    font-size: 12px; }
  .mobileTabs .forCustomerButton .forCustomerCont {
    font-size: 10px; }
  .catalogItemOption {
    font-size: 10px; } }

@media screen and (max-width: 373px) {
  .testFilter .filterButton.btnNewsReset {
    width: 75px; }
  .testFilter .filterButton {
    width: 75px; }
  .bannerContent .bannerTitle {
    font-size: 18px;
    font-weight: bold; }
  .bannerContent .bannerText {
    font-size: 10px; }
  .bannerWrapper button {
    width: 100%; }
  .bannerFormGroup .inputholder input {
    font-size: 10px;
    padding: 0 14px; }
  .bannerWrapper button {
    font-size: 10px; }
  .articleTitle {
    width: 70%; }
  .topCarouselForm .topCarouselFormTitle .titleNormal {
    font-size: 10px; }
  .topCarouselForm .inputholder input {
    font-size: 10px; }
  .topCarouselForm .carouselFormButton {
    font-size: 10px; }
  .topCarouselForm .topCarouselFormSubtitle {
    font-size: 10px; }
  .titleLink_mobile {
    font-size: 12px; }
  .discountItem .discountItemInfo .discountTitle {
    font-size: 10px;
    line-height: 1.5; }
  .discountDurationCont {
    font-size: 10px; }
  .discountDuration {
    width: 80px;
    height: 30px; }
  .discountButton {
    font-size: 10px; }
  .discountCarousel .discountItem .discountItemInfo {
    width: 50%; }
  .footerContacts .footerSocial {
    left: unset;
    right: 32px; }
  .catalogSection .catalogItemImage .catalogItemHit,
  .productBigImageWrapper .catalogItemHit,
  .hitItem .catalogItemHit {
    width: 20px;
    height: 20px;
    background-size: 45%; }
  .catalogSection .catalogItemDiscount,
  .productBigImageWrapper .catalogItemDiscount,
  .hitItemImage .catalogItemDiscount,
  .catalogItemImage .catalogItemDiscount {
    height: 20px;
    line-height: 18px;
    font-size: 10px;
    width: 40px; }
  .hitItemOldPrice, .catalogItemOldPrice {
    font-size: 10px; }
  .hitItemPrice, .catalogItemPrice {
    font-size: 14px; }
  .reviewSend {
    font-size: 10px;
    width: 100%; }
  .discountBannerTitle {
    width: 98%; }
  .testFilter .filterButton.btnNewsReset, .testFilter .filterButton {
    font-size: 10px; }
  .discountsWrapper .discountItem .discountItemInfo {
    width: 50%; }
  .customPopupPanel {
    font-size: 10px; }
  .orderProjectButton, .askQuestionButton {
    width: 130px;
    text-align: center; }
  .button.order, .oneClickButton {
    font-size: 10px; }
  .orderPrice {
    font-size: 26px; } }

@media screen and (max-height: 850px) {
  .authRegTab {
    height: 40px;
    line-height: 40px; }
  .authRegCont {
    padding-top: 10px;
    padding-bottom: 20px; }
  .form-group {
    margin-bottom: 3px; }
  .form-group .g-recaptcha {
    margin-top: -15px; }
  .authRegBtn {
    margin-top: 8px; }
  .authRegHybridAuthWrapper {
    margin-top: 10px;
    border-top: none; }
  .arhawTitle {
    margin-top: 15px;
    margin-bottom: 10px; } }

@media screen and (max-height: 720px) {
  .authRegCont .control-label {
    display: inline-block;
    vertical-align: top;
    width: 32%; }
  .authRegCont .form-control {
    display: inline-block;
    vertical-align: top;
    width: 66%; }
  .authRegCont .errormsg {
    padding-left: 34%; }
  .authRegBtn, .authRegBtn:hover {
    width: 286px;
    margin-left: auto;
    margin-right: auto; } }

/********** fancybox ***************/
.fancybox-container {
  width: 100vw;
  height: 100vh;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

.fancybox-toolbar {
  display: none; }

.fancybox-content {
  position: relative; }

.portfolioContent {
  width: 1240px;
  height: 520px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.fancybox-can-swipe .fancybox-content, .fancybox-can-pan .fancybox-content {
  width: 100vw;
  height: 100vh; }

.portfolioImageWrapper {
  display: inline-block;
  vertical-align: top;
  width: 66%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.portfolioContentWrapper {
  display: inline-block;
  vertical-align: top;
  width: 33%;
  height: 100%;
  padding: 30px; }

.portfolioContentTitle {
  font-size: 20px;
  color: #b50000;
  font-weight: 600; }

/********** responsive inputs **************/
.responsiveInput {
  border: 1px solid #ccc;
  padding: 0; }

.responsiveInputBuffer {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

input[readonly] {
  background-color: red; }

/************ jGrowl ************/
.jGrowl-message {
  text-transform: none; }

.jGrowl-closer {
  display: none !important; }

/* + Доработка от 10,04,2024 - Подваленко Олег */
.made-by-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
  padding: 5px 0; }
  @media (max-width: 576px) {
    .made-by-block {
      padding: 10px 0; } }
  .made-by-block .container-inner {
    display: flex;
    justify-content: flex-end;
    width: 100%; }

.made-by-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-bottom: 15px;
  height: 36px; }

.made-by {
  display: inline-flex;
  align-items: center;
  gap: 19px;
  max-width: 263px;
  text-decoration: none;
  transition: color 0.3s, fill 0.3s; }
  .made-by__logo {
    flex-shrink: 0;
    width: 119px;
    height: 36px; }
  .made-by__hover {
    transition: color 0.3s, fill 0.3s; }
  .made-by__text {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
    width: 126px; }
  .made-by__subtext {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin-bottom: 0;
    padding: 2px 0 0; }
  .made-by--light,
  .made-by--light + span {
    color: #000; }
  .made-by--dark,
  .made-by--dark + span {
    color: #fff; }
  @media (hover: hover) {
    .made-by.made-by--light:hover .made-by__hover, .made-by.made-by--dark:hover .made-by__hover {
      fill: #ad5354;
      color: #ad5354; } }

/* - Завершено. от 10,04,2024 - Подваленко Олег */
